/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: D2 Form Context
 * Description: Page for D2 Form Context
 * Date: 30/05/2022
 */

import { createContext, useContext, useReducer, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Initial State
const initialState = {
    d2Forms: [],
};

// Reducer Function
const reducer = (state, action) => {
    switch (action.type) {
        case 'D2_FORM_LIST':
            return {
                ...state,
                d2Forms: action.payload,
            };
        case 'ADD_D2_FORM':
            return {
                ...state,
                d2Forms: [...state.d2Forms, action.payload],
            };
        case 'EDIT_D2_FORM':
            const updateD2Form = action.payload;

            const updateD2Forms = state.d2Forms.map((d2Form) => {
                if (d2Form.id === updateD2Form.id) {
                    return updateD2Form;
                }
                return d2Form;
            });
            return {
                ...state,
                d2Forms: updateD2Forms,
            };

        default:
            return state;
    }
};

// Create Context
const ContextAPI = createContext(initialState);

export function useD2Form() {
    return useContext(ContextAPI);
}

// Provider Component
export function D2FormProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [errMsg, setErrMsg] = useState('');
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [formShow, setFormShow] = useState(false);
    const [rowData, setRowData] = useState('');
    const [requisitionData, setRequisitionData] = useState({});

    // Dependency
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const partnerId = auth.employee_info?.partner_id;

    // API Function: Add/Edit Data
    const addOrUodateData = async (data, id) => {
        try {
            const response = await axiosPrivate.post(
                id ? UrlService.updateD2FormUrl(id) : UrlService.saveD2FormUrl(),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (id) {
                ToastifyService.updateNotify('Data Update Successfull');
            } else if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
            }
            setShow(false);
            setFormShow(false);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else if (id) {
                setErrMsg('Data Update Failed!');
            } else {
                setErrMsg('Data Insert Failed!');
            }
            ToastifyService.errorNotify(errMsg);
        }
    };

    // API Function: Fatch All Data
    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllD2FormUrl(partnerId), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            dispatch({
                type: 'D2_FORM_LIST',
                payload: response?.data?.d2_preparation_info,
            });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // API Function: Fatch All Data
    const getRequisitionData = async (dateData) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getD2FormFacilityListUrl(
                    partnerId,
                    dateData?.year || new Date().getFullYear(),
                    dateData?.month ? dateData.month + 1 : new Date().getMonth() + 1
                ),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setRequisitionData(response?.data?.d2_facility_list);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // Add D2Form
    const addD2Form = (data) => {
        addOrUodateData(data);
        dispatch({
            type: 'ADD_D2_FORM',
            payload: data,
        });
    };

    // Edit D2Form
    const editD2Form = (data, id) => {
        addOrUodateData(data, id);
        dispatch({
            type: 'EDIT_D2_FORM',
            payload: data,
        });
    };

    return (
        <ContextAPI.Provider
            value={{
                d2Forms: state.d2Forms,
                getAllData,
                addD2Form,
                editD2Form,
                show,
                setShow,
                showModal,
                setShowModal,
                rowData,
                setRowData,
                formShow,
                setFormShow,
                requisitionData,
                getRequisitionData,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
