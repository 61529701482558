/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Contex API
 * Description: Contex API
 * Date: 15/07/2022
 */

import { createContext, useContext, useState } from 'react';

// Create Context
const ContextAPI = createContext();

export function useLowStock() {
    return useContext(ContextAPI);
}

// Provider Component
export function LowStockProvider({ children }) {
    const [show, setShow] = useState(false);
    const [partnerInfo, setPartnerInfo] = useState();
    const [facilityInfo, setFacilityInfo] = useState();
    const [dispensaryInfo, setDispensaryInfo] = useState();
    const [centralInfo, setCentralInfo] = useState();

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                partnerInfo,
                setPartnerInfo,
                facilityInfo,
                setFacilityInfo,
                dispensaryInfo,
                setDispensaryInfo,
                centralInfo,
                setCentralInfo,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
