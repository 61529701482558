/*
 * Author M. Atoar Rahman
 * Title: Partner Internal Request
 * Description: Page for Partner Internal Request
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { PartnerInternalRequestProvider } from '../partner/internalRequest/ContextAPI';
import Index from '../partner/internalRequest/Index';
import PermissionDenied from './PermissionDenied';

export default function PartnerInternalRequest() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['partner-internal-request']) return <PermissionDenied />;

    return (
        <PartnerInternalRequestProvider>
            <Index />
        </PartnerInternalRequestProvider>
    );
}
