/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/*
 * Author M. Atoar Rahman
 * Title: Facility Info
 * Description: Master Setup of Facility Info Form: Create and Update
 * Date: 15/03/2022
 */

import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import * as pattern from '../../../services/Helpers';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useFacility } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    // Local State
    const [partnerData, setPartnerData] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [show, setShow] = useState(false);

    const { auth } = useAuth();
    const partnerId = auth?.employee_info?.partner_id ? auth?.employee_info?.partner_id : null;
    const partnerName = auth?.employee_info?.partner_name
        ? auth?.employee_info?.partner_name
        : null;

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                // eslint-disable-next-line eqeqeq
                status: editData && editData?.status == '1' ? 1 : 0,
                partner_id: partnerId
                    ? {
                          label: partnerName,
                          value: partnerId,
                      }
                    : editData
                    ? {
                          label: editData?.partner?.name,
                          value: editData?.partner_id,
                      }
                    : partnerData,
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            // eslint-disable-next-line eqeqeq
            status: editData && editData?.status == '1' ? 1 : 0,
            partner_id: partnerId
                ? {
                      label: partnerName,
                      value: partnerId,
                  }
                : editData
                ? {
                      label: editData?.partner?.name,
                      value: editData?.partner_id,
                  }
                : partnerData,
        });
    }, [editData]);

    // API URL
    let SAVE_URL = '';
    if (!editData) {
        SAVE_URL = UrlService.saveFacilityUrl();
    } else {
        SAVE_URL = UrlService.updateFacilityUrl(editData.id);
    }
    // Dependency
    const axiosPrivate = useAxiosPrivate();
    const { getAllData, isSubmitted, setIsSubmitted } = useFacility();

    // Status Check Handling
    let checkeStatus;
    // eslint-disable-next-line eqeqeq
    if (editData != null && editData.status == 1) {
        checkeStatus = 'checked';
    } else if (editData === null) {
        checkeStatus = 'checked';
    } else {
        checkeStatus = '';
    }

    const [checked, setChecked] = useState(checkeStatus);

    // Get All Partner From Partner Table
    const partnerList = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllPartnerUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const partnerInfo = response?.data?.partner_info?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setPartnerData(partnerInfo);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // For Partner Login
    useEffect(async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getPartnerByIdUrl(partnerId), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const partnerInfo = response?.data?.partner_info?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setPartnerData(partnerInfo);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    }, [partnerId]);

    // Closing the Model
    const handleClose = () => {
        getAllData();
        setShow(false);
    };

    // Show the Model
    const handleShow = () => {
        setShow(true);
        partnerList();
        setIsSubmitted(false);
    };

    const handleChecked = () => {
        if (checked === 'checked') {
            setChecked('');
        } else {
            setChecked('checked');
        }
    };

    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            code_no: data.code_no,
            name: data.name,
            partner_id: data.partner_id.value,
            address: data.address,
            lat: data.lat,
            lng: data.lng,
            status: data.status === true ? 1 : 0,
        };
        try {
            const response = await axiosPrivate.post(SAVE_URL, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response?.data?.status === 'error') {
                const message = Object.keys(response.data.message);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
                setIsSubmitted(false);
            } else if (editData != null) {
                ToastifyService.updateNotify('Data Update Successfull');
                handleClose();
                reset();
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
                handleClose();
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else {
                setErrMsg('Save Failed!');
            }
            ToastifyService.errorNotify(errMsg);
            setIsSubmitted(false);
        }
    };

    return (
        <>
            {editData != null ? (
                <Button className="btn btn-light actionBtn" variant="primary" onClick={handleShow}>
                    <i className="fas fa-edit" />
                </Button>
            ) : (
                <Button className="btnInfo" variant="primary" onClick={handleShow}>
                    Create New
                </Button>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Facility Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Code<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="code_no"
                                        id="facilityCodeNo"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('code_no', {
                                            required: 'Please enter your code no.',
                                            // pattern: {
                                            //     value: /^[a-z]*?\-?\d+$/i,
                                            //     message:
                                            //         'Only allow example: AB-1234 or 1234 format',
                                            // },
                                        })}
                                        className={errors?.code_no && 'invalidInput'}
                                    />
                                    {errors.code_no && (
                                        <span className="invalid">{errors.code_no?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Name<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="name"
                                        id="facilityName"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('name', {
                                            required: 'Please enter your name.',
                                        })}
                                        className={errors?.name && 'invalidInput'}
                                    />
                                    {errors.name && (
                                        <span className="invalid">{errors.name?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Partner<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        name="partner_id"
                                        id="partnerId"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.partner_id && 'invalidInput'
                                                }
                                                options={partnerData}
                                                isDisabled={partnerId}
                                            />
                                        )}
                                        rules={{ required: 'Please select your partner.' }}
                                        // {...register('partner_id', {
                                        //     required: 'Please select your partner.',
                                        // })}
                                    />

                                    {errors.partner_id && (
                                        <span className="invalid">
                                            {errors.partner_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Address<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="address"
                                        id="facilityAddress"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('address', {
                                            required: 'Please enter your address.',
                                            pattern: pattern.addressPattern,
                                        })}
                                        className={errors?.address && 'invalidInput'}
                                    />
                                    {errors.address && (
                                        <span className="invalid">{errors.address?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Latitude<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="lat"
                                        id="facilityLat"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('lat', {
                                            required: 'Please enter your latitude.',
                                        })}
                                        className={errors?.lat && 'invalidInput'}
                                    />
                                    {errors.lat && (
                                        <span className="invalid">{errors.lat?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Longitude<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="lng"
                                        id="facilityLng"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('lng', {
                                            required: 'Please enter your longitude.',
                                        })}
                                        className={errors?.lng && 'invalidInput'}
                                    />
                                    {errors.lng && (
                                        <span className="invalid">{errors.lng?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Status</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Check
                                        name="status"
                                        id="user-status-switch"
                                        type="switch"
                                        checked={checked}
                                        onClick={handleChecked}
                                        onChange={handleChecked}
                                        {...register('status')}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Modal.Footer className="borderTop0 pe-0">
                            <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                onClick={handleSubmit(formSubmitHandler)}
                                className="btnSuccess"
                                type="button"
                                variant="success"
                                disabled={isSubmitted}
                            >
                                {editData != null ? 'Update' : 'Create New'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
