/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import CommonApiService from '../../../services/CommonApiService';
import { useDispensaryStockReceive } from './ContextAPI';
import ShowTable from './ShowTable';

export default function ShowModel() {
    const { show, setShow, rowData } = useDispensaryStockReceive();
    const { drugCategory, getDrugCategory } = CommonApiService();
    const [page, setPage] = useState(0);
    const [pageLength, setPageLength] = useState(0);

    // console.log({ rowData });

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    // Closing the Model
    const handleClose = () => {
        setShow(false);
    };

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <ShowTable data={rowData?.records?.filter((el) => el.category_id == item.id)} />
            </Tab>
        ));

    return (
        <Modal className="modalWidth80" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Dispensary Stock Receive</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col className="p-0">
                            <Table bordered hover size="sm">
                                <thead className="table-light">
                                    <tr>
                                        <th>Dispensary Name</th>
                                        <th>Stockout Date</th>
                                    </tr>
                                </thead>
                                <tbody className="dark-light-bg">
                                    <tr>
                                        <td>{rowData?.dispensary_name}</td>
                                        <td>{rowData?.stockout_date}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Tabs
                            activeKey={page}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            // eslint-disable-next-line radix
                            onSelect={(k) => setPage(parseInt(k))}
                        >
                            {tableDataDisplay()}
                        </Tabs>
                    </Row>

                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className="btnCancel me-2 ms-2"
                            variant="secondary"
                            disabled={page === 0}
                            onClick={() => {
                                setPage((currPage) => currPage - 1);
                            }}
                        >
                            Prev
                        </Button>
                        <Button
                            type="button"
                            className="btnSuccess"
                            disabled={page === pageLength - 1}
                            variant="success"
                            style={{ minWidth: '6rem' }}
                            onClick={() => setPage((currPage) => currPage + 1)}
                        >
                            Next
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
