/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/*
 * Author M. Atoar Rahman
 * Date: 15/03/2022
 * Time: 11:40 AM
 */

import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useData } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    // API URL
    let SAVE_URL = '';
    if (editData == null) {
        SAVE_URL = UrlService.saveRoleUrl();
    } else {
        SAVE_URL = UrlService.updateRoleUrl(editData.id);
    }

    // Dependency
    const axiosPrivate = useAxiosPrivate();
    const { getAllData } = useData();

    const [list, setList] = useState({
        name: '',
        description: '',
        // otp_status: 0,
        // mail_status: 0,
        status: 1,
    });

    const { name, description, status } = list;

    const [show, setShow] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [disabledForm, setDisabledForm] = useState(false);

    const handleClose = () => {
        getAllData();
        setShow(false);
    };

    const handleShow = () => {
        setList({
            name: '',
            description: '',
            status: 1,
        });
        setShow(true);
    };

    const editDandleShow = () => {
        setList({
            name: editData?.name,
            description: editData?.description,
            // otp_status: editData?.otp_status,
            // mail_status: editData?.mail_status,
            status: editData && editData?.status == '1' ? 1 : 0,
        });
        setShow(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabledForm(true);

        try {
            // list.otp_status = list.otp_status ? 1 : 0;
            // list.mail_status = list.mail_status ? 1 : 0;
            list.status = list.status ? 1 : 0;
            await axiosPrivate.post(SAVE_URL, JSON.stringify(list), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            handleClose();
            if (editData != null) {
                ToastifyService.updateNotify('Data Update Successfull');
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
            }
            setDisabledForm(false);
        } catch (err) {
            setDisabledForm(false);
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else {
                setErrMsg('Data Inserted Failed!');
            }
            ToastifyService.errorNotify(errMsg);
        }
    };

    return (
        <>
            {editData != null ? (
                <Button
                    className="btn btn-light actionBtn"
                    variant="primary"
                    onClick={editDandleShow}
                >
                    <i className="fas fa-edit" />
                </Button>
            ) : (
                <Button variant="primary" onClick={handleShow}>
                    Create New
                </Button>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Role Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Name</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder=""
                                        id="name"
                                        name="name"
                                        autoComplete="off"
                                        onChange={(e) =>
                                            setList({
                                                ...list,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        value={name}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Description</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        required
                                        as="textarea"
                                        rows="3"
                                        type="text"
                                        placeholder=""
                                        id="description"
                                        name="description"
                                        autoComplete="off"
                                        onChange={(e) =>
                                            setList({
                                                ...list,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        value={description}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>OTP Status</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Check
                                        type="switch"
                                        checked={otp_status}
                                        id="otp_status"
                                        name="otp_status"
                                        onChange={(e) =>
                                            setList({
                                                ...list,
                                                [e.target.name]: e.target.checked,
                                            })
                                        }
                                        value={otp_status}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Email Status</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Check
                                        type="switch"
                                        checked={mail_status}
                                        id="mail_status"
                                        name="mail_status"
                                        onChange={(e) =>
                                            setList({
                                                ...list,
                                                [e.target.name]: e.target.checked,
                                            })
                                        }
                                        value={mail_status}
                                    />
                                </Col>
                            </Row>
                        </Form.Group> */}

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Status</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Check
                                        type="switch"
                                        checked={status == 1 ? 'checked' : ''}
                                        id="status"
                                        name="status"
                                        onChange={(e) =>
                                            setList({
                                                ...list,
                                                [e.target.name]: e.target.checked,
                                            })
                                        }
                                        value={status}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Modal.Footer className="borderTop0 pe-0">
                            <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                type="submit"
                                className="btnSuccess"
                                variant="success"
                                disabled={disabledForm}
                            >
                                {editData ? 'Updated' : 'Create New'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
