/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { toArray } from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Card, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useApproval } from './ContextAPI';
import DistributeRequisitionTable from './DistributeRequisitionTable';
import ForwardRequisitionTable from './ForwardRequisitionTable';
import PartialRequisitionTable from './PartialRequisitionTable';

export default function FormInfo() {
    const {
        setFormShow,
        rowData,
        setRowData,
        receiveData,
        setReceiveData,
        receiveList,
        setReceiveList,
        forwardData,
        setForwardData,
        forwardList,
        setForwardList,
        getPendingData,
        getApprovedData,
        partialData,
        setPartialData,
        partialList,
        setPartialList,
    } = useApproval();

    const [isSubmitted, setIsSubmitted] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        rowData?.records?.map((el) => {
            const itemdata = {};
            itemdata.id = el.id;
            itemdata.drug_id = el.drug_id;
            itemdata.drug_name = el.drug_name;
            itemdata.category_id = el.category_id;
            itemdata.partner_available_qty = el.partner_available_qty;
            itemdata.facility_approved_qty = el.facility_approved_qty;
            itemdata.partner_received_qty = el.partner_received_qty;
            itemdata.approved_qty = !Number(el.partner_available_qty)
                ? 0
                : Number(el.partner_available_qty) > Number(el.facility_approved_qty)
                ? el.facility_approved_qty
                : el.partner_available_qty;
            itemdata.forward_qty = !Number(el.partner_available_qty)
                ? el.facility_approved_qty
                : Number(el.partner_available_qty) > Number(el.facility_approved_qty)
                ? el.facility_approved_qty
                : el.facility_approved_qty - el.partner_available_qty;

            const list = receiveData;
            const forward_list = forwardData;
            const partial_list = partialData;

            if (Number(el.partner_available_qty) >= Number(el.facility_approved_qty)) {
                list[itemdata.drug_id] = itemdata;
            }

            if (Number(el.partner_available_qty) < Number(el.facility_approved_qty)) {
                forward_list[itemdata.drug_id] = itemdata;
            }

            partial_list[itemdata.drug_id] = itemdata;

            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
                delete forward_list[itemdata.drug_id];
                delete partial_list[itemdata.drug_id];
            }

            setReceiveData(list);
            const arrOfObj = toArray(list);
            setReceiveList(arrOfObj);

            setForwardData(forward_list);
            const arrOfObjforward = toArray(forward_list);
            setForwardList(arrOfObjforward);

            setPartialData(partial_list);
            const arrOfObjPartial = toArray(partial_list);
            setPartialList(arrOfObjPartial);

            return receiveData;
        });
    }, []);

    const { reset, handleSubmit } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    const tableDataDisplayDistribute = () => (
        <DistributeRequisitionTable
            data={rowData?.records?.filter(
                (el) =>
                    Number(el.partner_available_qty) >= Number(el.facility_approved_qty) &&
                    el.status !== 'distributed'
            )}
        />
    );

    const tableDataDisplayForword = () => (
        <ForwardRequisitionTable
            data={rowData?.records?.filter(
                (el) =>
                    Number(el.partner_available_qty) < Number(el.facility_approved_qty) &&
                    el.status != 'forwarded'
            )}
        />
    );

    const tableDataDisplayPartial = () => (
        <PartialRequisitionTable
            data={rowData?.records?.filter(
                (el) => el.status !== 'forwarded' && el.status !== 'distributed'
            )}
        />
    );

    // Distribute Form Submit Handelar
    const distributeSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            submit_type: 'partner_distributed',
            master_id: rowData.master_id,
            requisition: receiveList,
        };
        try {
            await axiosPrivate.post(
                UrlService.internalRequestApprovalUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            ToastifyService.updateNotify('Distribute Successfull');
            setFormShow(false);
            reset();
            getPendingData();
            getApprovedData();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    // Forward Form Submit Handelar
    const forwordSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            submit_type: 'partner_forwarded',
            master_id: rowData.master_id,
            requisition: forwardList,
        };
        try {
            await axiosPrivate.post(
                UrlService.internalRequestApprovalUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            ToastifyService.updateNotify('Forward Successfull');
            setFormShow(false);
            reset();
            getPendingData();
            getApprovedData();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    // Partial Distribution Form Submit Handelar
    const partialDistributeSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            submit_type: 'partner_distributed',
            master_id: rowData.master_id,
            requisition: partialList,
        };
        try {
            await axiosPrivate.post(
                UrlService.internalRequestApprovalUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            ToastifyService.updateNotify('Distribute Successfull');
            setFormShow(false);
            reset();
            getPendingData();
            getApprovedData();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setFormShow(false);
        setRowData('');
    };

    // register('requisition', { required: 'Requisition quantity is required!' });

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Facility Internal Request</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <Tabs
                    defaultActiveKey="distribute"
                    id="tab-internal-request"
                    className="tab-internal-request p-2 pb-0"
                    style={{ background: '#eee' }}
                >
                    <Tab eventKey="distribute" title="Distribute to Facility">
                        <Card>
                            <Card.Body>
                                {tableDataDisplayDistribute()}

                                <div className="formFooter">
                                    <Button
                                        className="btnCancel"
                                        variant="secondary"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>

                                    <Button
                                        onClick={handleSubmit(distributeSubmitHandler)}
                                        className="btnSuccess ms-2"
                                        type="button"
                                        variant="success"
                                        style={{ minWidth: '6rem' }}
                                        disabled={
                                            isSubmitted || Object.keys(receiveData).length < 0
                                        }
                                    >
                                        Distribute
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab eventKey="forward" title="Forward to UNHCR">
                        <Card>
                            <Card.Body>
                                {tableDataDisplayForword()}

                                <div className="formFooter">
                                    <Button
                                        className="btnCancel"
                                        variant="secondary"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>

                                    <Button
                                        onClick={handleSubmit(forwordSubmitHandler)}
                                        className="btnSuccess ms-2"
                                        type="button"
                                        variant="success"
                                        style={{ minWidth: '6rem' }}
                                        disabled={
                                            isSubmitted || Object.keys(forwardData).length < 0
                                        }
                                    >
                                        Forward
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab eventKey="partial" title="Partial Distribute to Facility">
                        <Card>
                            <Card.Body>
                                {tableDataDisplayPartial()}

                                <div className="formFooter">
                                    <Button
                                        className="btnCancel"
                                        variant="secondary"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>

                                    <Button
                                        onClick={handleSubmit(partialDistributeSubmitHandler)}
                                        className="btnSuccess ms-2"
                                        type="button"
                                        variant="success"
                                        style={{ minWidth: '6rem' }}
                                        disabled={
                                            isSubmitted || Object.keys(forwardData).length < 0
                                        }
                                    >
                                        Distribute
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    );
}
