/* eslint-disable react-hooks/exhaustive-deps */
import useDataTable from '../../../hooks/useDataTable';

export default function ShowTable({ data }) {
    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            width: '350px',
            wrap: true,
        },
        {
            name: 'Opening Balance',
            selector: (row) => row.opening_balance,
            sortable: true,
        },
        {
            name: 'Closing Balance',
            selector: (row) => row.closing_balance,
            sortable: true,
        },
        {
            name: 'Request Quantity',
            selector: (row) => row.approved_request_qty,
            sortable: true,
        },
        {
            name: 'Available Quantity',
            selector: (row) => row.available_qty,
            sortable: true,
        },
        {
            name: 'Distribution Quantity',
            selector: (row) => (Number(row.supplied_qty) < 0 ? 0 : row.supplied_qty),
            sortable: true,
        },
        {
            name: 'Final Distribution Quantity',
            selector: (row) => (row.approved_supply_qty < 0 ? 0 : row.approved_supply_qty),
            sortable: true,
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
