/*
 * Author M. Atoar Rahman
 * Title: Center Stock In
 * Description: Page for Center Stock In
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { CurrentStockEntryProvider } from '../masterSetup/currentStockEntry/ContextAPI';
import Index from '../masterSetup/currentStockEntry/Index';
import { ManufacturerProvider } from '../masterSetup/manufacturer/ContextAPI';
import { SupplierProvider } from '../masterSetup/supplier/ContextAPI';
import PermissionDenied from './PermissionDenied';

export default function CurrentStockEntry() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (route_permissions['current-stock-entry']) return <PermissionDenied />;

    return (
        <CurrentStockEntryProvider>
            <ManufacturerProvider>
                <SupplierProvider>
                    <Index />
                </SupplierProvider>
            </ManufacturerProvider>
        </CurrentStockEntryProvider>
    );
}
