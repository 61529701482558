/*
 * Author M. Atoar Rahman
 * Title: Donation
 * Description: Page for Donation
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { DonationProvider } from '../misReports/donation/ContextAPI';
import Index from '../misReports/donation/Index';
import PermissionDenied from './PermissionDenied';

export default function Donation() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions.donation) return <PermissionDenied />;

    return (
        <DonationProvider>
            <Index />
        </DonationProvider>
    );
}
