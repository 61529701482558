/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Partner Internal Request Approve
 * Description: Partner Internal Request Approve Form: Create and Update
 * Date: 26/04/2022
 */

import { isEmpty, toArray } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import CommonApiService from '../../../services/CommonApiService';
import ApproveTable from './ApproveTable';
import { useInternalRequest } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const {
        setShow,
        addInternalRequest,
        editInternalRequest,
        rowData,
        approveList,
        setApproveList,
        approveData,
        setApproveData,
    } = useInternalRequest();
    // Local State
    const [partnerData, setPartnerData] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { partner, getPartner } = CommonApiService();

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                partner_id: rowData && {
                    label: rowData?.partner_name,
                    value: rowData?.partner_id,
                },
                priority: rowData && {
                    label: rowData?.priority,
                    value: rowData?.priority,
                },
                expected_receive_date:
                    rowData.expected_receive_date && new Date(rowData?.expected_receive_date),
            }),
            [rowData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            partner_id: rowData && {
                label: rowData?.partner_name,
                value: rowData?.partner_id,
            },
            priority: rowData && {
                label: rowData?.priority,
                value: rowData?.priority,
            },
            expected_receive_date:
                rowData.expected_receive_date && new Date(rowData?.expected_receive_date),
        });
    }, [rowData]);

    useEffect(() => {
        rowData?.items?.map((el) => {
            const itemdata = {};
            itemdata.id = el.id;
            itemdata.drug_id = el.drug_id;
            itemdata.req_qty = el.req_qty;
            itemdata.approve_qty = Number(el.approve_qty) > 0 ? el.approve_qty : el.req_qty;

            const list = approveData;
            list[itemdata.drug_id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }
            setApproveData(list);
            const arrOfObj = toArray(list);
            setApproveList(arrOfObj);
            return approveData;
        });
    }, [rowData.records]);

    // Set default values
    useEffect(() => {
        if (!isEmpty(approveList)) {
            setValue('itemDetails', approveList);
        }
    }, [approveList]);

    console.log(approveList);

    const dataList = useCallback(() => {
        getPartner(); // Partner
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner]);

    // Form Close
    const handleClose = () => {
        setShow(false);
    };

    register('itemDetails', { required: 'Approve quantity is required!' });

    // Form Submit Handelar
    const formSubmitHandler = (data) => {
        setIsSubmitted(true);
        const formData = {
            ...data,
            partner_request_id: rowData.id,
            partner_id: data.partner_id.value,
            priority: data.priority.value,
            expected_receive_date: data.expected_receive_date.toLocaleDateString('sv-SE'),
        };
        if (editData) {
            editInternalRequest(formData, editData.id); // Update Data
        } else {
            addInternalRequest(formData); // Create Data
        }
        setShow(false);
        reset();
    };

    const priorities = [
        { label: 'Very Urgent', value: 'Very Urgent' },
        { label: 'Urgent', value: 'Urgent' },
        { label: 'General', value: 'General' },
    ];

    return (
        <Form>
            <fieldset style={{ background: '#f5f7ff' }} className="customFieldset p-3 pb-0 mb-3">
                <Row>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Partner <span className="text-danger">*</span>
                            </Form.Label>
                            <Controller
                                name="partner_id"
                                id="partner_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.partner_id && 'invalidInput'}
                                        options={partnerData}
                                        isDisabled={rowData.partner_id}
                                    />
                                )}
                                rules={{ required: 'Please select your partner.' }}
                            />
                            {errors?.partner_id && (
                                <span className="invalid">{errors?.partner_id?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Expected Receive Date <span className="text-danger">*</span>
                            </Form.Label>
                            <Controller
                                control={control}
                                name="expected_receive_date"
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        closeOnScroll
                                        placeholderText="Select date"
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        className="form-control"
                                        popperPlacement="top-end"
                                        minDate={new Date()}
                                    />
                                )}
                                rules={{
                                    required: 'Please select your date.',
                                    valueAsDate: true,
                                }}
                            />
                            {errors.approved_date && (
                                <span className="invalid">{errors.approved_date?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Priority <span className="text-danger">*</span>
                            </Form.Label>
                            <Controller
                                name="priority"
                                id="priority"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.priority && 'invalidInput'}
                                        options={priorities}
                                    />
                                )}
                                rules={{
                                    required: 'Please select priority.',
                                }}
                            />
                            {errors.priority && (
                                <span className="invalid">{errors.priority?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>

            <Row className="requisition_table">
                <ApproveTable />
            </Row>

            {errors.itemDetails && <span className="invalid">{errors.itemDetails?.message}</span>}

            <div className="formFooter">
                <Button className="btnCancel me-2" variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    onClick={handleSubmit(formSubmitHandler)}
                    className="btnSuccess"
                    type="button"
                    variant="success"
                    disabled={rowData.status !== 'pending' || isSubmitted}
                >
                    Submit
                </Button>
            </div>
        </Form>
    );
}
