/*
 * Author M. Atoar Rahman
 * Title: Drug Presentation Strength
 * Description: Page for Drug Presentation Strength
 * Date: 15/03/2022
 */

import React from 'react';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { PresentationStrengthProvider } from '../masterSetup/presentationStrength/ContextAPI';
import DataTable from '../masterSetup/presentationStrength/DataTable';
import FormInfo from '../masterSetup/presentationStrength/FormInfo';
import PermissionDenied from './PermissionDenied';

export default function DrugPresentationStrength() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['drug-presentation-strength']) return <PermissionDenied />;

    return (
        <PresentationStrengthProvider>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Drug Presentation Strength</h3>
                    <div>
                        <FormInfo />
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable />
                </Card.Body>
            </Card>
        </PresentationStrengthProvider>
    );
}
