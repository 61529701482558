/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form, Row, Tab, Tabs } from 'react-bootstrap';
import CommonApiService from '../../../services/CommonApiService';
import { usePoStock } from './ContextAPI';
import DetailsDataTable from './DetailsDataTable';

export default function ShowDetails() {
    const {
        setShowDetails,
        rowData,
        setShowList,
        setShowForm,
        setShowPdfList,
        setPendingDetailsShow,
        setShowPdfDetails,
    } = usePoStock();
    const { drugCategory, getDrugCategory } = CommonApiService();
    const [page, setPage] = useState(0);
    const [pageLength, setPageLength] = useState(0);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    // Go Back
    const backToList = () => {
        setShowList(true);
        setShowForm(false);
        setShowDetails(false);
        setPendingDetailsShow(false);
        setShowPdfList(false);
        setShowPdfDetails(false);
    };

    const handlePrintPreview = () => {
        setShowList(false);
        setShowForm(false);
        setShowDetails(false);
        setPendingDetailsShow(false);
        setShowPdfList(false);
        setShowPdfDetails(true);
    };

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <DetailsDataTable
                    data={rowData?.records?.filter((el) => el.category_id == item.id)}
                />
            </Tab>
        ));

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>PO Wise Stock Receive Status</h3>
                <div>
                    <Button onClick={() => backToList()} className="btnInfo" variant="primary">
                        Go Back
                    </Button>
                </div>
                <div>
                    <Button className="btn btn-dark me-2" onClick={handlePrintPreview}>
                        Print Preview
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Row>
                        <Tabs
                            activeKey={page}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            // eslint-disable-next-line radix
                            onSelect={(k) => setPage(parseInt(k))}
                        >
                            {tableDataDisplay()}
                        </Tabs>
                    </Row>

                    <Row>
                        <div className="mt-3 text-end">
                            <Button className="btnCancel" variant="secondary" onClick={backToList}>
                                Close
                            </Button>
                            <Button
                                className="btnInfo me-2 ms-2"
                                variant="primary"
                                style={{ minWidth: '6rem' }}
                                disabled={page === 0}
                                onClick={() => {
                                    setPage((currPage) => currPage - 1);
                                }}
                            >
                                Prev
                            </Button>
                            <Button
                                type="button"
                                className="btnSuccess"
                                disabled={page === pageLength}
                                variant="success"
                                style={{ minWidth: '6rem' }}
                                onClick={() => setPage((currPage) => currPage + 1)}
                            >
                                Next
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}
