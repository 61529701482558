/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Employee Info
 * Description: Master Setup of Employee Info Form: Create and Update
 * Date: 15/03/2022
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import CommonApiService from '../../../services/CommonApiService';

export default function CustomFilterUserInfo({ editData = null, setcustomFilter }) {
    const { auth } = useAuth();
    const partnerId = auth?.employee_info?.partner_id ? auth?.employee_info?.partner_id : null;
    const partnerName = auth?.employee_info?.partner_name
        ? auth?.employee_info?.partner_name
        : null;

    const {
        formState: { errors },
        reset,
        resetField,
        control,
        watch,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                code_no: editData && editData?.code_no,
                first_name: editData && editData?.first_name,
                last_name: editData && editData?.last_name,
                father_name: editData && editData?.father_name,
                mother_name: editData && editData?.mother_name,
                nid_no: editData && editData?.nid_no,
                date_of_birth: editData ? new Date(editData.date_of_birth) : new Date(),
                mobile: editData && editData?.mobile,
                email: editData && editData?.email,
                address: editData && editData?.address,
                designation: editData && editData?.designation,
                // department: editData && editData?.department,
                joining_date: editData ? new Date(editData.joining_date) : new Date(),
                organization: partnerId
                    ? {
                          label: 'Partner',
                          value: '3',
                      }
                    : editData && {
                          label:
                              editData.organization == '1'
                                  ? 'UNHCR'
                                  : editData.organization == '2'
                                  ? 'Center'
                                  : editData.organization == '3'
                                  ? 'Partner'
                                  : editData.organization == '4'
                                  ? 'Facility'
                                  : 'Department',
                          value: editData?.organization,
                      },
                partner_id: partnerId
                    ? {
                          label: partnerName,
                          value: partnerId,
                      }
                    : editData && {
                          label: editData?.partner?.name,
                          value: editData?.partner_id,
                      },
                facility_id: editData && {
                    label: editData?.facility?.name,
                    value: editData?.facility_id,
                },
                dispensary_id: editData && {
                    label: editData?.dispensary?.name,
                    value: editData?.dispensary_id,
                },
                photo: editData && editData?.photo,
                status: editData ? (editData?.status == '1' ? 1 : 0) : 1,
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            code_no: editData && editData?.code_no,
            first_name: editData && editData?.first_name,
            last_name: editData && editData?.last_name,
            father_name: editData && editData?.father_name,
            mother_name: editData && editData?.mother_name,
            nid_no: editData && editData?.nid_no,
            date_of_birth: editData ? new Date(editData.date_of_birth) : new Date(),
            mobile: editData && editData?.mobile,
            email: editData && editData?.email,
            address: editData && editData?.address,
            designation: editData && editData?.designation,
            // department: editData && editData?.department,
            joining_date: editData ? new Date(editData.joining_date) : new Date(),
            organization: partnerId
                ? {
                      label: 'Partner',
                      value: '3',
                  }
                : editData && {
                      label:
                          editData.organization == '1'
                              ? 'UNHCR'
                              : editData.organization == '2'
                              ? 'Center'
                              : editData.organization == '3'
                              ? 'Partner'
                              : editData.organization == '4'
                              ? 'Facility'
                              : 'Department',
                      value: editData?.organization,
                  },
            partner_id: partnerId
                ? {
                      label: partnerName,
                      value: partnerId,
                  }
                : editData && {
                      label: editData?.partner?.name,
                      value: editData?.partner_id,
                  },
            facility_id: editData && {
                label: editData?.facility?.name,
                value: editData?.facility_id,
            },
            dispensary_id: editData && {
                label: editData?.dispensary?.name,
                value: editData?.dispensary_id,
            },
            photo: editData && editData?.photo,
            status: editData ? (editData?.status == '1' ? 1 : 0) : 1,
        });
    }, [editData]);

    // Employee Context
    const {
        partner,
        getPartner,
        facilityByPartner,
        getFacilityByPartner,
        dispensaryByFacility,
        getDispensaryByFacility,
    } = CommonApiService();

    // Local State
    const [partnerData, setPartnerData] = useState();
    const [facilityData, setFacilityData] = useState();
    const [dispensaryData, setDispensaryData] = useState();
    const [organizationInfo, setOrganizationInfo] = useState();
    // Watch data
    const organizationWatch = watch('organization');

    useEffect(() => {
        setOrganizationInfo(organizationWatch?.value);
    }, [organizationWatch]);

    useEffect(() => {
        if (editData) {
            setOrganizationInfo(editData.organization);
        }
    }, [editData]);

    React.useEffect(() => {
        const subscription = watch((value) => setcustomFilter(value));
        return () => subscription.unsubscribe();
    }, [watch]);

    const dataList = useCallback(() => {
        getPartner(); // Partner
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Dispensary
        setDispensaryData(
            dispensaryByFacility?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner, facilityByPartner, dispensaryByFacility]);

    // Organization List
    const orgList = [
        { value: '1', label: 'UNHCR' },
        { value: '2', label: 'Center' },
        { value: '3', label: 'Partner' },
        { value: '4', label: 'Facility' },
        { value: '5', label: 'Department' },
    ];

    // Upload Image Custom Button
    // Set default values

    return (
        <fieldset className="">
            <Row>
                <Form.Group className="mb-5">
                    <Row>
                        <Col lg={2}>
                            <Form.Label>Organization Type</Form.Label>
                        </Col>
                        <Col lg={4}>
                            <Controller
                                name="organization"
                                id="organization"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.organization && 'invalidInput'}
                                        options={orgList}
                                        isDisabled={partnerId}
                                    />
                                )}
                                rules={{
                                    required: 'Please select your organization.',
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
            {(organizationInfo == 3 || organizationInfo == 4 || organizationInfo == 5) && (
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={4}>
                                    <Form.Label>Partner Agency</Form.Label>
                                </Col>
                                <Col lg={8}>
                                    <Controller
                                        name="partner_id"
                                        id="partner_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.partner_id && 'invalidInput'
                                                }
                                                options={partnerData}
                                                onBlur={async () => {
                                                    await resetField('facility_id');
                                                    await resetField('dispensary_id');
                                                    await getFacilityByPartner(field?.value?.value);
                                                }}
                                                isDisabled={partnerId}
                                            />
                                        )}
                                        rules={{ required: 'Please select your partner.' }}
                                    />
                                    {errors?.partner_id && (
                                        <span className="invalid">
                                            {errors?.partner_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col lg={6} className={organizationInfo == 3 && 'd-none'}>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={4}>
                                    <Form.Label>Facility Name</Form.Label>
                                </Col>
                                <Col lg={8}>
                                    <Controller
                                        name="facility_id"
                                        id="facility_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.facility_id && 'invalidInput'
                                                }
                                                options={facilityData}
                                                onBlur={async () => {
                                                    await resetField('dispensary_id');
                                                    await getDispensaryByFacility(
                                                        field?.value?.value
                                                    );
                                                }}
                                            />
                                        )}
                                        // rules={{ required: 'Please select your facility.' }}
                                    />
                                    {errors?.facility_id && (
                                        <span className="invalid">
                                            {errors?.facility_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col
                        lg={6}
                        className={(organizationInfo == 3 || organizationInfo == 4) && 'd-none'}
                    >
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={4}>
                                    <Form.Label>Department Name</Form.Label>
                                </Col>
                                <Col lg={8}>
                                    <Controller
                                        name="dispensary_id"
                                        id="dispensary_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.dispensary_id && 'invalidInput'
                                                }
                                                options={dispensaryData}
                                            />
                                        )}
                                        // rules={{ required: 'Please select your dispensary.' }}
                                    />
                                    {errors?.dispensary_id && (
                                        <span className="invalid">
                                            {errors?.dispensary_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
            )}
        </fieldset>
    );
}
