/*
 * Author M. Atoar Rahman
 * Title: Menu Item
 * Description: Menu Item
 * Date: 15/03/2022
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { dashboard, star } from '../../SvgIcon';

export default function MenuItem({ menuItem }) {
    const [isMenuExtended, setIsMenuExtended] = useState(false);
    const [isExpandable, setIsExpandable] = useState(false);
    const [isMainActive, setIsMainActive] = useState(false);
    const [isOneOfChildrenActive, setIsOneOfChildrenActive] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleMenu = () => {
        setIsMenuExtended(!isMenuExtended);
    };

    const handleMainMenuAction = () => {
        if (isExpandable) {
            toggleMenu();
            return;
        }
        navigate(menuItem.path ? menuItem.path : '/');
    };

    useEffect(() => {
        const calculateIsActive = (url = Location) => {
            setIsMainActive(false);
            setIsOneOfChildrenActive(false);
            if (isExpandable && menuItem && menuItem.children) {
                menuItem.children.forEach((item) => {
                    if (item.path.replaceAll('/', '') === url.pathname.replaceAll('/', '')) {
                        setIsOneOfChildrenActive(true);
                        setIsMenuExtended(true);
                    }
                    // Sub Children
                    if (item.children) {
                        item.children.forEach((el) => {
                            if (el.path.replaceAll('/', '') === url.pathname.replaceAll('/', '')) {
                                setIsOneOfChildrenActive(true);
                                setIsMenuExtended(true);
                            }
                        });
                    }
                });
            } else if (menuItem.path.replaceAll('/', '') === url.pathname.replaceAll('/', '')) {
                setIsMainActive(true);
            }
        };
        if (location) {
            calculateIsActive(location);
        }
    }, [location, isExpandable, menuItem]);

    useEffect(() => {
        if (!isMainActive && !isOneOfChildrenActive) {
            setIsMenuExtended(false);
        }
    }, [isMainActive, isOneOfChildrenActive]);

    useEffect(() => {
        setIsExpandable(Boolean(menuItem && menuItem.children && menuItem.children.length > 0));
    }, [menuItem]);

    return (
        <li className={`nav-item ${isMenuExtended ? 'menu-open' : ''}`}>
            <a
                className={`nav-link ${isMainActive || isOneOfChildrenActive ? ' active' : ''}`}
                role="link"
                onClick={handleMainMenuAction}
                style={{ cursor: 'pointer' }}
            >
                {/* <span className="svg-icon svg-icon-light">{menuItem.icon}</span> */}
                <span className="svg-icon svg-icon-light">{dashboard}</span>
                <p>{menuItem.name}</p>
                {isExpandable ? <i className="right fas fa-angle-left" /> : null}
            </a>

            {isExpandable &&
                menuItem &&
                menuItem.children &&
                menuItem.children.map((item) => (
                    <ul key={Math.random()} className="nav nav-treeview">
                        <li className="nav-item">
                            <NavLink className="nav-link" to={`${item.path}`}>
                                {/* <span className="svg-icon svg-icon-light">{item.icon}</span> */}
                                <span className="svg-icon svg-icon-light">{star}</span>
                                <p>{item.name}</p>
                            </NavLink>
                            {item.children &&
                                item.children.map((el) => (
                                    <ul key={Math.random()} className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to={`${el.path}`}>
                                                <span className="svg-icon svg-icon-light">
                                                    {star}
                                                </span>
                                                <p>{el.name}</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                ))}
                        </li>
                    </ul>
                ))}
        </li>
    );
}
