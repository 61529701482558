import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { D2ApprovalProvider } from '../unhcr/d2Approval/ContextAPI';
import Index from '../unhcr/d2Approval/Index';
import PermissionDenied from './PermissionDenied';

export default function D2Approval() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['d2-approval']) return <PermissionDenied />;

    return (
        <D2ApprovalProvider>
            <Index />
        </D2ApprovalProvider>
    );
}
