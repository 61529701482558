/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Requisition DataTable
 * Description: Requisition DataTable
 * Date: 28/06/2022
 */

import { toArray } from 'lodash';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { useFacilityStockDistribution } from './ContextAPI';

export default function RequisitionTable({ id }) {
    const { requisitionList, setRequisitionList } = useFacilityStockDistribution();
    const [data, setData] = useState();
    const axiosPrivate = useAxiosPrivate();

    const categoryHandler = async () => {
        try {
            setRequisitionList();
            const response = await axiosPrivate.get(
                UrlService.facilityStockOutMedicineListUrl(id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setData(response?.data?.medicine_list);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    useEffect(() => {
        categoryHandler();
    }, []);

    const requisitionRowHandler = (value, item) => {
        setRequisitionList();
        const itemdata = item;
        itemdata.supplied_qty = value;
        const list = { ...requisitionList };
        list[itemdata.drug_id] = itemdata;
        if (value === '') {
            delete list[itemdata.drug_id];
        }
        const arrOfObj = toArray(list);
        setRequisitionList(arrOfObj);
    };

    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Available Quantity',
            selector: (row) => row.available_stock,
            sortable: true,
            width: '250px',
        },
        {
            name: 'Distribution Quantity',
            selector: (row) => row.supplied_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        requisitionRowHandler(e.target.value, row);
                    }}
                    defaultValue={requisitionList?.[row.id]?.supplied_qty}
                />
            ),
            reorder: true,
            width: '150px',
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
