/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: D1 Form Context
 * Description: Page for D1 Form Context
 * Date: 15/03/2022
 */

import { createContext, useContext, useReducer, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Initial State
const initialState = {
    d1Forms: null,
};

// Reducer Function
const reducer = (state, action) => {
    switch (action.type) {
        case 'D1_FORM_LIST':
            return {
                ...state,
                d1Forms: action.payload,
            };
        case 'ADD_D1_FORM':
            return {
                ...state,
                d1Forms: [...state.d1Forms, action.payload],
            };
        case 'EDIT_D1_FORM':
            const updateD1Form = action.payload;

            const updateD1Forms = state.d1Forms.map((d1Form) => {
                if (d1Form.id === updateD1Form.id) {
                    return updateD1Form;
                }
                return d1Form;
            });
            return {
                ...state,
                d1Forms: updateD1Forms,
            };

        default:
            return state;
    }
};

// Create Context
const ContextAPI = createContext(initialState);

export function useD1Form() {
    return useContext(ContextAPI);
}

// Provider Component
export function D1FormProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [errMsg, setErrMsg] = useState('');
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [rowData, setRowData] = useState();
    const [requisitionData, setRequisitionData] = useState({});
    const [requisitionList, setRequisitionList] = useState({});

    // Dependency
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const facilityId = auth.employee_info?.facility_id;

    // API Function: Add/Edit Data
    const addOrUodateData = async (data, id) => {
        try {
            const response = await axiosPrivate.post(
                id ? UrlService.updateD1FormUrl(id) : UrlService.saveD1FormUrl(),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (id) {
                ToastifyService.updateNotify('Data Update Successfull');
            } else if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.msg);
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
            }
            setShow(false);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else if (id) {
                setErrMsg('Data Update Failed!');
            } else {
                setErrMsg('Data Insert Failed!');
            }
            ToastifyService.errorNotify(errMsg);
        }
    };

    // API Function: Fatch All Data
    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllD1FormUrl(facilityId), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            dispatch({
                type: 'D1_FORM_LIST',
                payload: response?.data?.d1_preparation_info,
            });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // API Function: Fatch All Data
    // const getRequisitionData = async (dateData) => {
    //     try {
    //         const response = await axiosPrivate.get(
    //             UrlService.getD2FormFacilityListUrl(
    //                 facilityId,
    //                 dateData?.year || new Date().getFullYear(),
    //                 dateData?.month ? dateData.month + 1 : new Date().getMonth() + 1
    //             ),
    //             {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //             }
    //         );
    //         setRequisitionData(response?.data?.d2_facility_list);
    //     } catch (err) {
    //         if (!err?.response) {
    //             console.log('Server Not Responding!');
    //         } else if (err.response?.status === 401) {
    //             console.log('Unauthorized!');
    //         } else {
    //             console.log('Data Not Found!');
    //         }
    //     }
    // };

    // Add D1Form
    const addD1Form = (data) => {
        addOrUodateData(data);
        dispatch({
            type: 'ADD_D1_FORM',
            payload: data,
        });
    };

    // Edit D1Form
    const editD1Form = (data, id) => {
        addOrUodateData(data, id);
        dispatch({
            type: 'EDIT_D1_FORM',
            payload: data,
        });
    };

    return (
        <ContextAPI.Provider
            value={{
                d1Forms: state.d1Forms,
                getAllData,
                addD1Form,
                editD1Form,
                show,
                setShow,
                showModal,
                setShowModal,
                rowData,
                setRowData,
                requisitionList,
                setRequisitionList,
                requisitionData,
                setRequisitionData,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
