/*
 * Author M. Atoar Rahman
 * Title: Content Wrapper
 * Description: Content Wrapper
 * Date: 15/03/2022
 */

import React, { useEffect, useRef, useState } from 'react';
import useWindowWidth from '../../hooks/useWindowWidth';
import Header from './header/Header';
import { MenuProvider } from './menuSidebar/ContextAPI';
import MenuSidebar from './menuSidebar/MenuSidebar';

export default function ContentWrapper({ children }) {
    const [sidebarClass, setSitebarClass] = useState('');
    const [sidebarCollapse, setSitebarCollapse] = useState('false');

    const windowWidth = useWindowWidth();
    const pushmenuRef = useRef(null);
    const contentWrapperRef = useRef(null);

    useEffect(() => {
        if (windowWidth < 992) {
            window.addEventListener('load', () => {
                setSitebarClass('sidebar-closed sidebar-collapse');
                setSitebarCollapse('false');
            });
        }
        return windowWidth;
    }, [windowWidth]);

    useEffect(() => {
        if (windowWidth < 992) {
            pushmenuRef.current.addEventListener('click', () => {
                if (sidebarCollapse === 'false') {
                    setSitebarClass('sidebar-open');
                    setSitebarCollapse('true');
                } else {
                    setSitebarClass('sidebar-closed sidebar-collapse');
                    setSitebarCollapse('false');
                }
            });
            contentWrapperRef.current.addEventListener('click', () => {
                setSitebarClass('sidebar-closed sidebar-collapse');
                setSitebarCollapse('false');
            });
        } else {
            pushmenuRef.current.addEventListener('click', () => {
                if (sidebarCollapse === 'false') {
                    setSitebarClass('sidebar-collapse');
                    setSitebarCollapse('true');
                } else {
                    setSitebarClass('');
                    setSitebarCollapse('false');
                }
            });
        }
        return sidebarCollapse;
    }, [sidebarCollapse, windowWidth]);

    return (
        <div className={`main-wrapper ${sidebarClass}`}>
            <MenuProvider>
                <Header ref={pushmenuRef} />
                <MenuSidebar />
            </MenuProvider>
            <div ref={contentWrapperRef} className="content-wrapper">
                <div className="padding_15">{children}</div>
            </div>
        </div>
    );
}
