/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/*
 * Author M. Atoar Rahman
 * Title: Partner Info
 * Description: Master Setup of Partner Info Form: Create and Update
 * Date: 15/03/2022
 */

import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { addressPattern } from '../../../services/Helpers';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { usePartner } from './ContextAPI';

export default function FormInfo({ editData = null, instantCreate = false }) {
    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                // eslint-disable-next-line eqeqeq
                status: editData && editData?.status == '1' ? 1 : 0,
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            // eslint-disable-next-line eqeqeq
            status: editData && editData?.status == '1' ? 1 : 0,
        });
    }, [editData]);

    // API URL
    let SAVE_URL = '';
    if (editData == null) {
        SAVE_URL = UrlService.savePartnerUrl();
    } else {
        SAVE_URL = UrlService.updatePartnerUrl(editData.id);
    }

    // Dependency
    const axiosPrivate = useAxiosPrivate();
    const { getAllData, isSubmitted, setIsSubmitted } = usePartner();

    // Status Check Handling
    let checkeStatus;
    // eslint-disable-next-line eqeqeq
    if (editData != null && editData.status == 1) {
        checkeStatus = 'checked';
    } else if (editData === null) {
        checkeStatus = 'checked';
    } else {
        checkeStatus = '';
    }

    // State for Model
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState(checkeStatus);
    const [errMsg, setErrMsg] = useState('');

    // Closing the Model
    const handleClose = () => {
        setShow(false);
    };

    // Show the Model
    const handleShow = () => {
        setShow(true);
        setIsSubmitted(false);
    };

    const handleChecked = () => {
        if (checked === 'checked') {
            setChecked('');
        } else {
            setChecked('checked');
        }
    };

    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            code_no: data.code_no,
            name: data.name,
            address: data.address,
            status: data.status === true ? 1 : 0,
        };
        try {
            const response = await axiosPrivate.post(SAVE_URL, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!instantCreate) {
                getAllData();
            }

            if (response?.data?.status === 'error') {
                const message = Object.keys(response.data.message);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
                setIsSubmitted(false);
            } else if (editData != null) {
                ToastifyService.updateNotify('Data Update Successfull');
                handleClose();
                reset();
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
                handleClose();
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else {
                setErrMsg('Data Inserted Failed!');
            }
            ToastifyService.errorNotify(errMsg);
            setIsSubmitted(false);
        }
    };

    return (
        <>
            {editData != null ? (
                <Button className="btn btn-light actionBtn" variant="primary" onClick={handleShow}>
                    <i className="fas fa-edit" />
                </Button>
            ) : instantCreate ? (
                <Button className="btnBgNonePrimary" variant="light" onClick={handleShow}>
                    New
                </Button>
            ) : (
                <Button className="btnInfo" variant="primary" onClick={handleShow}>
                    Create New
                </Button>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Partner Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Code<span className="invalid">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="code_no"
                                        id="partnerCodeNo"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('code_no', {
                                            required: 'Please enter your code no.',
                                            // pattern: {
                                            //     value: /^[a-z]*?\-?\d*$/i,
                                            //     message:
                                            //         'Only allow example: AB-1234 or 1234 format',
                                            // },
                                        })}
                                        className={errors?.code_no && 'invalidInput'}
                                    />
                                    {errors?.code_no && (
                                        <span className="invalid">{errors?.code_no?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Name<span className="invalid">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="name"
                                        id="partnerName"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('name', {
                                            required: 'Please enter your partner name.',
                                        })}
                                        className={errors?.name && 'invalidInput'}
                                    />
                                    {errors.name && (
                                        <span className="invalid">{errors.name?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Address<span className="invalid">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="address"
                                        id="partnerAddress"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('address', {
                                            required: 'Please enter your partner address.',
                                            pattern: {
                                                value: addressPattern,
                                                message: 'Only use special charecter # , : - / .',
                                            },
                                        })}
                                        className={errors?.address && 'invalidInput'}
                                    />
                                    {errors.address && (
                                        <span className="invalid">{errors.address?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Status</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Check
                                        name="status"
                                        id="user-status-switch"
                                        type="switch"
                                        checked={checked}
                                        onClick={handleChecked}
                                        onChange={handleChecked}
                                        {...register('status')}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Modal.Footer className="borderTop0 pe-0">
                            <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                onClick={handleSubmit(formSubmitHandler)}
                                className="btnSuccess"
                                type="button"
                                variant="success"
                                disabled={isSubmitted}
                            >
                                {editData != null ? 'Update' : 'Create New'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
