/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Contex API
 * Description: Contex API
 * Date: 15/07/2022
 */

import { createContext, useContext, useState } from 'react';

// Create Context
const ContextAPI = createContext();

export function usePoReports() {
    return useContext(ContextAPI);
}

// Provider Component
export function PoReportsProvider({ children }) {
    // Dependency
    const [show, setShow] = useState(false);
    const [listShow, setListShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState();
    const [rowData, setRowData] = useState('');
    const [dateRange, setDateRange] = useState('');

    const [showPdf, setShowPdf] = useState(false);

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                data,
                setData,
                showPdf,
                setShowPdf,
                listShow,
                setListShow,
                showModal,
                setShowModal,
                rowData,
                setRowData,
                dateRange,
                setDateRange,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
