/*
 * Author M. Atoar Rahman
 * Title: Employee Information
 * Description: Page for Employee Information
 * Date: 30/04/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { EmployeeProvider } from '../userManagement/employeeInfo/ContextAPI';
import Index from '../userManagement/employeeInfo/Index';
import PermissionDenied from './PermissionDenied';

export default function EmployeeInformation() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['employee-info']) return <PermissionDenied />;

    return (
        <EmployeeProvider>
            <Index />
        </EmployeeProvider>
    );
}
