import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { ApprovalProvider } from '../unhcr/partnerInternalRequest/ContextAPI';
import Index from '../unhcr/partnerInternalRequest/Index';
import PermissionDenied from './PermissionDenied';

export default function PartnerInternalRequestUnhcr() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['partner-internal-request-unhcr-init']) return <PermissionDenied />;

    return (
        <ApprovalProvider>
            <Index />
        </ApprovalProvider>
    );
}
