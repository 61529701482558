/* eslint-disable eqeqeq */
/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Stock Return Request Context
 * Description: Page for Stock Return Request Context
 * Date: 30/05/2022
 */

import { createContext, useContext, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Create Context
const ContextAPI = createContext();

export function useApproval() {
    return useContext(ContextAPI);
}

// Provider Component
export function StockReturnRequestProvider({ children }) {
    const [show, setShow] = useState(false);
    const [showReturn, setShowReturn] = useState(false);
    const [formShow, setFormShow] = useState(false);
    const [rowData, setRowData] = useState('');
    const [pendingData, setPendingData] = useState();
    const [approvedData, setApprovedData] = useState();
    const [receiveData, setReceiveData] = useState({});
    const [receiveList, setReceiveList] = useState({});

    const [returnedData, setReturnedData] = useState();
    const [distributedData, setDistributedData] = useState();

    // Dependency
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const partnerId = auth?.employee_info?.partner_id;

    // API Function: Fatch All Data  All Data
    const getPendingData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllStockReturnRrequestUrl('request_approved_by_unhcr', 0),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setPendingData(
                response?.data?.stock_return_request_info?.filter(
                    (el) => el.partner_id == partnerId && !el.facility_id
                )
            );
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
            setPendingData([]);
        }
    };

    const getApprovedData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllStockReturnRrequestUrl('d_returned_by_partner', 0),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setApprovedData(response?.data?.stock_return_request_info);
            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
            setApprovedData([]);
        }
    };

    const getReturnedData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllStockReturnRrequestUrl('return_approved_by_facility', 0),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setReturnedData(response?.data?.stock_return_request_info);
            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
            setReturnedData([]);
        }
    };

    const getDistributedData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllStockReturnRrequestUrl('return_approving_by_partner', 0),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setDistributedData(response?.data?.stock_return_request_info);
            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
            setDistributedData([]);
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                showReturn,
                setShowReturn,
                rowData,
                setRowData,
                formShow,
                setFormShow,
                pendingData,
                getPendingData,
                approvedData,
                getApprovedData,
                receiveData,
                setReceiveData,
                receiveList,
                setReceiveList,
                returnedData,
                getReturnedData,
                distributedData,
                getDistributedData,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
