/*
 * Author M. Atoar Rahman
 * Title: Dispensary Item Dispatch
 * Description: Index for Dispensary Item Dispatch
 * Date: 30/04/2022
 */

import { Button, Card } from 'react-bootstrap';
import { useD2Form } from './ContextAPI';
import DataTable from './DataTable';
import FormInfo from './FormInfo';
import ShowModel from './ShowModel';

export default function Index() {
    const { formShow, setFormShow, getRequisitionData } = useD2Form();
    const createHander = () => {
        setFormShow(true);
        getRequisitionData();
    };
    const backHander = () => {
        setFormShow(false);
    };
    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>D2 Report: Agency Level Consumption Report</h3>
                {formShow ? (
                    <div>
                        <Button onClick={() => backHander()} className="btnInfo" variant="primary">
                            Back to List
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button
                            onClick={() => createHander()}
                            className="btnInfo"
                            variant="primary"
                        >
                            Generate New
                        </Button>
                    </div>
                )}
            </Card.Header>
            <Card.Body>{formShow ? <FormInfo /> : <DataTable />}</Card.Body>
            <ShowModel />
        </Card>
    );
}
