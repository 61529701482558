/*
 * Author M. Atoar Rahman
 * Title: Current Stock
 * Description: Current Stock
 * Date: 13/07/2022
 */

import useDataTable from '../../../hooks/useDataTable';

export default function DataTable({ data }) {
    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            grow: 3,
        },
        {
            name: 'Available Stock',
            selector: (row) => row.available_stock,
            sortable: true,
        },
    ];

    return useDataTable({ columns, data, pagination: false });
}
