import { useAuth } from '../../contexts/AuthContext';
import { D2DistributionProvider } from '../centerWarehouse/d2Distribution/ContextAPI';
import Index from '../centerWarehouse/d2Distribution/Index';
import PermissionDenied from './PermissionDenied';

export default function D2Distribution() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['d2-distribution']) return <PermissionDenied />;
    return (
        <D2DistributionProvider>
            <Index />
        </D2DistributionProvider>
    );
}
