/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Purchase Order
 * Description: Purchase Order Form: Create and Update
 * Date: 26/04/2022
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import CommonApiService from '../../../services/CommonApiService';
import { usePurchaseOrder } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    // Dependancy
    const { drugInfo, getDrugInfo, suppliers, getSuppliers, centerWarehouse, getCenterWarehouse } =
        CommonApiService();
    const { addPurchaseOrder, editPurchaseOrder, setShow, setRowData } = usePurchaseOrder();

    // Local State
    const [drugData, setDrugData] = useState();
    const [supplierData, setSupplierData] = useState();
    // const [isSubmitted, setIsSubmitted] = useState(false);

    // Order Details Info
    const [orderDetails, setOrderDetails] = useState([]);
    const [warehouseData, setWarehouseData] = useState();

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                po_date: editData && new Date(editData.po_date),
                supplied_date: editData && new Date(editData.supplied_date),
                expected_delivery_date: editData && new Date(editData.expected_delivery_date),
                supplier_id: editData && {
                    label: editData?.supplier?.name,
                    value: editData?.supplier_id,
                },
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            po_date: editData && new Date(editData.po_date),
            supplied_date: editData && new Date(editData.supplied_date),
            expected_delivery_date: editData && new Date(editData.expected_delivery_date),
            supplier_id: editData && {
                label: editData?.supplier?.name,
                value: editData?.supplier_id,
            },
        });
    }, [editData]);

    // Order Details
    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        control: control2,
        reset: reset2,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // React.useEffect(() => {
    //     const subscription = watch((value, { name, type }) => console.log(value, name, type));
    //     return () => subscription.unsubscribe();
    // }, [watch]);

    // Set default values
    useEffect(() => {
        setValue('items', orderDetails);
    }, [orderDetails]);

    const orderDetailAppendHandler = (data) => {
        const formDetailsData = {
            ...data,
            drug_id: data.item.value,
            drug_name: data.item.label,
        };

        setOrderDetails([...orderDetails, formDetailsData]);
        reset2({
            item: '',
            req_qty: '',
        });
    };

    // console.log({ orderDetails });

    const orderDetailRemoveHandler = (id) => {
        const updatedOrderDetail = orderDetails && orderDetails.filter((m) => m.drug_id !== id);
        setOrderDetails(updatedOrderDetail);
    };

    // Access from context API
    const dataList = useCallback(() => {
        getDrugInfo();
        getSuppliers();
        getCenterWarehouse(); // Warehouse
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        // Drug
        setDrugData(
            drugInfo?.map((el) => ({
                value: el.id,
                label: el.drug_name,
            }))
        );
        // Supplier
        setSupplierData(
            suppliers?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Warehouse
        setWarehouseData(
            centerWarehouse?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [drugInfo, suppliers, centerWarehouse]);

    // Form Close
    const handleClose = () => {
        setShow(false);
        setRowData('');
    };

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        // setIsSubmitted(true);
        const supplyQty = orderDetails.reduce(
            (accumulator, currentValue) => accumulator + Number(currentValue.req_qty),
            0
        );

        const formData = {
            ...data,
            supply_qty: supplyQty,
            supplier_id: data.supplier_id.value,
            warehouse_id: data.warehouse_id.value,
            po_date: data.po_date.toLocaleDateString('sv-SE'),
            supplied_date: data.supplied_date.toLocaleDateString('sv-SE'),
            expected_delivery_date: data.expected_delivery_date.toLocaleDateString('sv-SE'),
        };

        // console.log(formData);

        if (editData) {
            editPurchaseOrder(formData, editData.id); // Update Data
        } else {
            addPurchaseOrder(formData); // Create Data
        }
        reset();
    };

    register('items', { required: 'Drug list is required!' });

    return (
        <Form>
            <fieldset style={{ background: '#f3f3f3' }} className="customFieldset p-3 pb-0 mb-3">
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Purchase No.<span className="invalid">*</span>
                            </Form.Label>

                            <Form.Control
                                name="po_no"
                                id="po_no"
                                type="text"
                                placeholder="Enter purchese no."
                                autoComplete="off"
                                {...register('po_no', {
                                    required: 'Please enter your Purchase No.',
                                })}
                                className={errors?.po_no && 'invalidInput'}
                            />
                            {errors.po_no && (
                                <span className="invalid">{errors.po_no?.message}</span>
                            )}
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Purchase Date<span className="invalid">*</span>
                            </Form.Label>

                            <Controller
                                control={control}
                                name="po_date"
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        closeOnScroll
                                        placeholderText="Select date"
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        className="form-control"
                                        popperPlacement="top-end"
                                        // minDate={new Date()}
                                        // maxDate={new Date()}
                                    />
                                )}
                                rules={{
                                    required: 'Date is required.',
                                    valueAsDate: true,
                                }}
                            />
                            {errors.po_date && (
                                <span className="invalid">{errors.po_date?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Supplier<span className="invalid">*</span>
                            </Form.Label>
                            <Controller
                                name="supplier_id"
                                id="supplier_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.supplier_id && 'invalidInput'}
                                        options={supplierData}
                                    />
                                )}
                                rules={{
                                    required: 'Please select the supplier.',
                                }}
                            />
                            {errors.supplier_id && (
                                <span className="invalid">{errors.supplier_id?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Supply Date<span className="invalid">*</span>
                            </Form.Label>

                            <Controller
                                control={control}
                                name="supplied_date"
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        closeOnScroll
                                        placeholderText="Select date"
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        className="form-control"
                                        popperPlacement="top-end"
                                        // minDate={new Date()}
                                        // maxDate={new Date()}
                                    />
                                )}
                                rules={{
                                    required: 'Date is required.',
                                    valueAsDate: true,
                                }}
                            />
                            {errors.supplied_date && (
                                <span className="invalid">{errors.supplied_date?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Delivery Location<span className="invalid">*</span>
                            </Form.Label>

                            <Controller
                                name="warehouse_id"
                                id="warehouse_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.warehouse_id && 'invalidInput'}
                                        options={warehouseData}
                                    />
                                )}
                                rules={{ required: 'Please select your location.' }}
                            />
                            {errors.warehouse_id && (
                                <span className="invalid">{errors.warehouse_id?.message}</span>
                            )}
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Expected Delivery Date<span className="invalid">*</span>
                            </Form.Label>

                            <Controller
                                control={control}
                                name="expected_delivery_date"
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        closeOnScroll
                                        placeholderText="Select date"
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        className="form-control"
                                        popperPlacement="top-end"
                                        // minDate={new Date()}
                                        // maxDate={new Date()}
                                    />
                                )}
                                rules={{
                                    required: 'Date is required.',
                                    valueAsDate: true,
                                }}
                            />
                            {errors.expected_delivery_date && (
                                <span className="invalid">
                                    {errors.expected_delivery_date?.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>

            <Row>
                <Col lg={7} className="pe-0">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Item Name<span className="invalid">*</span>
                        </Form.Label>
                        <Controller
                            name="item"
                            id="item"
                            control={control2}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    classNamePrefix={errors2?.item && 'invalidInput'}
                                    options={drugData}
                                />
                            )}
                            rules={{
                                required: 'Please select the item.',
                            }}
                        />
                        {errors2.item && <span className="invalid">{errors2.item?.message}</span>}
                    </Form.Group>
                </Col>

                <Col lg={2} className="pe-0">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Purchase Qty<span className="invalid">*</span>
                        </Form.Label>
                        <Form.Control
                            name="req_qty"
                            id="req_qty"
                            type="number"
                            placeholder=""
                            autoComplete="off"
                            {...register2('req_qty', {
                                required: 'Field is required!',
                            })}
                            className={errors2?.req_qty && 'invalidInput'}
                        />
                        {errors2.req_qty && (
                            <span className="invalid">{errors2.req_qty?.message}</span>
                        )}
                    </Form.Group>
                </Col>
                <Col lg={1} className="mt-1 pe-0">
                    <Button
                        className="btnSteelBlue mt-3"
                        variant="primary"
                        onClick={handleSubmit2(orderDetailAppendHandler)}
                    >
                        Add
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    {errors.orderDetails && (
                        <span className="invalid">{errors.orderDetails?.message}</span>
                    )}
                    <Table className="borderDashed" responsive="sm">
                        <tbody>
                            {orderDetails.map((item) => (
                                <tr key={Math.random()}>
                                    <td>{item.drug_name}</td>
                                    <td>{item.req_qty}</td>
                                    <td className="text-end">
                                        <Button
                                            className="btn btn-light actionBtn"
                                            variant="primary"
                                            onClick={() => orderDetailRemoveHandler(item.drug_id)}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {errors.transports && (
                        <span className="invalid">{errors.transports?.message}</span>
                    )}
                </Col>
            </Row>

            {errors.items && <span className="invalid">{errors.items?.message}</span>}

            <div className="formFooter">
                <Button className="btnCancel me-2" variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    onClick={handleSubmit(formSubmitHandler)}
                    className="btnSuccess"
                    type="button"
                    variant="success"
                    // disabled={isSubmitted}
                >
                    {editData ? 'Update' : 'Submit'}
                </Button>
            </div>
        </Form>
    );
}
