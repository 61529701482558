/* eslint-disable eqeqeq */
import { Card } from 'react-bootstrap';
import { usePoStock } from './ContextAPI';
import DataTable from './DataTable';
import PendingShowModel from './PendingShowModel';
import ShowModel from './ShowModel';

export default function Index() {
    const { show, pendingModalShow } = usePoStock();
    return (
        <>
            <Card>
                <Card.Header className="cardHeader" style={{ background: '#17a2b8' }}>
                    <h3 className="text-white">Stock received (%) as per pipeline</h3>
                </Card.Header>
                <Card.Body>
                    <DataTable />
                </Card.Body>
            </Card>
            {show && <ShowModel />}
            {pendingModalShow && <PendingShowModel />}
        </>
    );
}
