/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Internal Request Context
 * Description: Page for Internal Request Context
 * Date: 30/05/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Create Context
const ContextAPI = createContext();

export function useApproval() {
    return useContext(ContextAPI);
}

// Provider Component
export function ApprovalProvider({ children }) {
    const [show, setShow] = useState(false);
    const [formShow, setFormShow] = useState(false);
    const [rowData, setRowData] = useState('');
    const [pendingDistributeData, setPendingDistributeData] = useState();
    const [approvedDistributeData, setApprovedDistributeData] = useState();
    const [pendingForwardData, setPendingForwardData] = useState();
    const [approvedForwardData, setApprovedForwardData] = useState();
    const [receiveData, setReceiveData] = useState({});
    const [receiveList, setReceiveList] = useState({});
    const [forwardData, setForwardData] = useState({});
    const [forwardList, setForwardList] = useState({});

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data  All Data
    const getPendingDistributeData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllInternalRrequestUrl('partner_distributed', 1),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setPendingDistributeData(
                response?.data?.internal_request_info.filter(
                    (el) => el.status === 'partner_distributed'
                )
            );
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const getApprovedDistributeData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllInternalRrequestUrl('partner_distribute_apv', 1),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setApprovedDistributeData(response?.data?.internal_request_info);
            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const getPendingForwardData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllInternalRrequestUrl('partner_forwarded', 1),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setPendingForwardData(
                response?.data?.internal_request_info.filter(
                    (el) => el.status === 'partner_forwarded'
                )
            );
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const getApprovedForwardData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllInternalRrequestUrl('partner_forward_apv', 1),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setApprovedForwardData(response?.data?.internal_request_info);
            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                rowData,
                setRowData,
                formShow,
                setFormShow,
                pendingDistributeData,
                getPendingDistributeData,
                approvedDistributeData,
                getApprovedDistributeData,
                receiveData,
                setReceiveData,
                receiveList,
                setReceiveList,
                pendingForwardData,
                getPendingForwardData,
                approvedForwardData,
                getApprovedForwardData,
                forwardData,
                setForwardData,
                forwardList,
                setForwardList,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
