/* eslint-disable import/no-unresolved */
import React from 'react';
import { Card } from 'react-bootstrap';
import { PieChart } from 'react-minimal-pie-chart';

const defaultLabelStyle = {
    fontSize: '5px',
    fontFamily: 'sans-serif',
};
export default function PieCharts({ dataPie }) {
    return (
        <Card>
            <Card.Body className="py-4">
                <PieChart
                    data={dataPie}
                    radius={50}
                    label={({ dataEntry }) => dataEntry.value}
                    labelStyle={{
                        ...defaultLabelStyle,
                    }}
                    style={{ height: '330px' }}
                />
            </Card.Body>
        </Card>
    );
}
