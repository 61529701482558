/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Requisition DataTable
 * Description: Requisition DataTable
 * Date: 05/06/2022
 */

import { toArray } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useStockReturnRequest } from './ContextAPI';

export default function ReturnTable({ data }) {
    const { setReturnList, returnData, setReturnData } = useStockReturnRequest();

    const requisitionRowHandler = (value, item) => {
        const itemdata = {};
        itemdata.drug_id = item.drug_id;
        itemdata.return_request_qty = value;

        const list = { ...returnData };
        list[itemdata.drug_id] = itemdata;
        if (value === '') {
            delete list[itemdata.drug_id];
        }
        setReturnData(list);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(returnData);
        setReturnList(arrOfObj);
    }, [returnData]);

    const tableStyle = {
        paddingLeft: '2px',
        paddingRight: '2px',
        lineHeight: '33px',
    };

    function Title() {
        return (
            <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                <thead>
                    <tr style={{ borderTopWidth: '0px', borderBottomWidth: '0px' }}>
                        <th style={{ lineHeight: '54px' }} width="100px" className="m-0">
                            Batch No
                        </th>
                        <th style={{ lineHeight: '54px' }} width="100px" className="m-0">
                            Expired Date
                        </th>
                        <th width="100px" className="m-0">
                            Available Qty
                        </th>
                    </tr>
                </thead>
            </table>
        );
    }

    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            width: '250px',
            fixed: true,
            style: { borderBottom: '1px solid #ddd' },
        },

        {
            name: <Title />,
            width: '300px',
            // style: { borderLeft: '0px', borderRight: '0px' },
            cell: (row) => (
                <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                    <tbody>
                        {row?.batch_info?.map((item) => (
                            <tr key={Math.random() * 1000} style={{ borderTopWidth: '0px' }}>
                                <td width="100px" className="m-0">
                                    {item.batch_no}
                                </td>
                                <td width="100px" className="m-0">
                                    {moment(item.expire_date).format('DD MMM YYYY')}
                                </td>
                                <td width="100px" className="m-0">
                                    {item.available_qty}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ),
        },
        {
            name: 'Total Available Qty',
            selector: (row) =>
                row?.batch_info?.reduce(
                    (accumulator, item) => accumulator + Number(item.available_qty),
                    0
                ),
            sortable: true,
            style: { justifyContent: 'center' },
        },
        {
            name: 'Per Month Avg. Consumption',
            selector: (row) => (row.avg_monthly_consumption ? row.avg_monthly_consumption : 0),
            sortable: true,
            style: { justifyContent: 'center' },
        },
        {
            name: 'Next 3 Months Need',
            selector: (row) => (row.next_3_months_need ? row.next_3_months_need : 0),
            sortable: true,
            style: { justifyContent: 'center' },
        },
        {
            name: '10 % Buffer',
            selector: (row) => (row.ten_percent_buffer ? row.ten_percent_buffer : 0),
            sortable: true,
            style: { justifyContent: 'center' },
        },
        {
            name: '20 % Buffer',
            selector: (row) => (row.twenty_percent_buffer ? row.twenty_percent_buffer : 0),
            sortable: true,
            style: { justifyContent: 'center' },
        },
        {
            name: 'Return Request Qty',
            selector: (row) => row.return_request_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        requisitionRowHandler(e.target.value, row);
                    }}
                />
            ),
            reorder: true,
            width: '150px',
        },
    ];

    const search_column = {
        // drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
