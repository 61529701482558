/* eslint-disable jsx-a11y/anchor-is-valid */
/*
 * Author M. Atoar Rahman
 * Title: Window Fullscreen
 * Description: Window Fullscreen in Admin Header
 * Date: 15/03/2022
 */

import React, { useEffect, useRef } from 'react';

export default function WindowFullscreen() {
    const fullscreeRef = useRef(null);
    useEffect(() => {
        fullscreeRef.current.addEventListener('click', () => {
            if (
                (document.fullScreenElement && document.fullScreenElement !== null) ||
                (!document.mozFullScreen && !document.webkitIsFullScreen)
            ) {
                if (document.documentElement.requestFullScreen) {
                    document.documentElement.requestFullScreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullScreen) {
                    document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        });
        return null;
    }, []);

    return (
        <a ref={fullscreeRef} className="nav-link" href="#" role="button">
            <i className="fas fa-expand-arrows-alt" />
        </a>
    );
}
