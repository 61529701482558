/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/*
 * Author M. Atoar Rahman
 * Title: Drug Supplier
 * Description: Master Setup of Drug Supplier Form: Create and Update
 * Date: 15/03/2022
 */

import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Countries from '../../../json/Countries.json';
import * as pattern from '../../../services/Helpers';
import { useSupplier } from './ContextAPI';

export default function FormInfo({ editData = null, instantCreate = false }) {
    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                // eslint-disable-next-line eqeqeq
                status: editData && editData?.status == '1' ? 1 : 0,
                country_id: editData && {
                    label: editData?.country_id,
                    value: editData?.country_id,
                },
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        const rowData = Countries.filter((el) => el.id === editData?.country_id).map((ele) => ({
            value: ele.id,
            label: ele.name,
        }));
        reset({
            ...editData,
            // eslint-disable-next-line eqeqeq
            status: editData && editData?.status == '1' ? 1 : 0,
            country_id: rowData,
        });
    }, [editData]);

    // Supplier Context
    const { addSupplier, editSupplier, showModal, isSubmitted, setIsSubmitted } = useSupplier();
    const [countryData, setCountryData] = useState();

    // Status Check Handling
    let checkeStatus;
    // eslint-disable-next-line eqeqeq
    if (editData != null && editData.status == '1') {
        checkeStatus = 'checked';
    } else if (editData === null) {
        checkeStatus = 'checked';
    } else {
        checkeStatus = '';
    }

    useEffect(() => {
        setCountryData(
            Countries?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, []);

    // Local State
    const [checked, setChecked] = useState(checkeStatus);
    const [show, setShow] = useState(false);

    // Modal Show/Close
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Check Data Handler
    const handleChecked = () => {
        if (checked === 'checked') {
            setChecked('');
        } else {
            setChecked('checked');
        }
    };

    // Form Submit Handelar
    const formSubmitHandler = (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            country_id: data.country_id.value,
            status: data.status === true ? 1 : 0,
        };
        if (editData) {
            editSupplier(formData, editData.id); // Update Data
        } else {
            addSupplier(formData); // Create Data
        }
        setShow(false);
        reset();
    };

    // Modal close After Add/Edit Success
    useEffect(() => {
        if (showModal) {
            setShow(false);
        }
    }, [showModal]);

    return (
        <>
            {editData != null ? (
                <Button className="btn btn-light actionBtn" variant="primary" onClick={handleShow}>
                    <i className="fas fa-edit" />
                </Button>
            ) : instantCreate ? (
                <Button
                    className="btnBgNonePrimary float-start"
                    variant="light"
                    onClick={handleShow}
                >
                    New
                </Button>
            ) : (
                <Button className="btnInfo" variant="primary" onClick={handleShow}>
                    Create New
                </Button>
            )}

            <Modal className="modalWidth55" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Supplier Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {editData && (
                            <Form.Group className="mb-2">
                                <Row>
                                    <Col lg={2}>
                                        <Form.Label>Code</Form.Label>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Control
                                            name="code_no"
                                            id="code_no"
                                            type="text"
                                            placeholder=""
                                            disabled
                                            autoComplete="off"
                                            {...register('code_no')}
                                            className={errors?.code_no && 'invalidInput'}
                                        />
                                        {errors.code_no && (
                                            <span className="invalid">
                                                {errors.code_no?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        )}
                        <Form.Group className="mb-2">
                            <Row>
                                <Col lg={2}>
                                    <Form.Label>
                                        Name<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={10}>
                                    <Form.Control
                                        name="name"
                                        id="name"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('name', {
                                            required: 'Please enter your name.',
                                        })}
                                        className={errors?.name && 'invalidInput'}
                                    />
                                    {errors.name && (
                                        <span className="invalid">{errors.name?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-2">
                            <Row>
                                <Col lg={2}>
                                    <Form.Label>
                                        Address
                                        {/* Block By babu for CLient requrement */}
                                        {/* <span className="required">*</span> */}
                                    </Form.Label>
                                </Col>
                                <Col lg={10}>
                                    <Form.Control
                                        name="address"
                                        id="address"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register(
                                            'address'
                                            // {
                                            //     required: 'Please enter your address.',
                                            //     pattern: pattern.addressPattern,
                                            // }
                                        )}
                                        className={errors?.address && 'invalidInput'}
                                    />
                                    {errors.address && (
                                        <span className="invalid">{errors.address?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Phone</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="phone"
                                                id="phone"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('phone', {
                                                    // required: 'Please enter your phone.',
                                                    pattern: pattern.phonePattern,
                                                })}
                                                className={errors?.phone && 'invalidInput'}
                                            />
                                            {errors.phone && (
                                                <span className="invalid">
                                                    {errors.phone?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Fax</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="fax"
                                                id="fax"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('fax', {
                                                    // required: 'Please enter your fax.',
                                                })}
                                                className={errors?.fax && 'invalidInput'}
                                            />
                                            {errors.fax && (
                                                <span className="invalid">
                                                    {errors.fax?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Mobile</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="mobile"
                                                id="mobile"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('mobile', {
                                                    //   required: 'Please enter your mobile.',
                                                    pattern: pattern.mobilePattern,
                                                })}
                                                className={errors?.mobile && 'invalidInput'}
                                            />
                                            {errors.mobile && (
                                                <span className="invalid">
                                                    {errors.mobile?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Email</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="email"
                                                id="email"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('email', {
                                                    // required: 'Please enter your email.',
                                                    pattern: pattern.emailPattern,
                                                })}
                                                className={errors?.email && 'invalidInput'}
                                            />
                                            {errors.email && (
                                                <span className="invalid">
                                                    {errors.email?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>City</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="city"
                                                id="city"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('city', {
                                                    // required: 'Please enter your city.',
                                                })}
                                                className={errors?.city && 'invalidInput'}
                                            />
                                            {errors.city && (
                                                <span className="invalid">
                                                    {errors.city?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Country</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Group className="mb-3">
                                                <Controller
                                                    name="country_id"
                                                    id="country_id"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            classNamePrefix={
                                                                errors?.country_id && 'invalidInput'
                                                            }
                                                            options={countryData}
                                                        />
                                                    )}
                                                    // rules={{ required: 'Please select your country.', }}
                                                    // {...register('country_id', {
                                                    //     required: 'Please select your country.',
                                                    // })}
                                                />

                                                {errors?.country_id && (
                                                    <span className="invalid">
                                                        {errors?.country_id?.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Web</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="web"
                                                id="web"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('web', {
                                                    // required: 'Please enter your web address.',
                                                    pattern: pattern.webPattern,
                                                })}
                                                className={errors?.web && 'invalidInput'}
                                            />
                                            {errors.web && (
                                                <span className="invalid">
                                                    {errors.web?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Contact</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="contact"
                                                id="contact"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('contact', {
                                                    //  required: 'Please enter your contact.',
                                                    pattern: pattern.mobilePattern,
                                                })}
                                                className={errors?.contact && 'invalidInput'}
                                            />
                                            {errors.contact && (
                                                <span className="invalid">
                                                    {errors.contact?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-2">
                            <Row>
                                <Col lg={2}>
                                    <Form.Label>Status</Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Check
                                        name="status"
                                        id="user-status-switch"
                                        type="switch"
                                        checked={checked}
                                        onClick={handleChecked}
                                        onChange={handleChecked}
                                        {...register('status')}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Modal.Footer className="borderTop0 pe-0">
                            <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                onClick={handleSubmit(formSubmitHandler)}
                                className="btnSuccess"
                                type="button"
                                variant="success"
                                disabled={isSubmitted}
                            >
                                {editData != null ? 'Update' : 'Create New'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
