/*
 * Author M. Atoar Rahman
 * Title: Partner Stock Distribution
 * Description: Page for Partner Stock Distribution
 * Date: 28/04/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { PartnerStockDistributionProvider } from '../partner/stockDistribution/ContextAPI';
import Index from '../partner/stockDistribution/Index';
import PermissionDenied from './PermissionDenied';

export default function PartnerStockDistribution() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['partner-stock-distribution']) return <PermissionDenied />;

    return (
        <PartnerStockDistributionProvider>
            <Index />
        </PartnerStockDistributionProvider>
    );
}
