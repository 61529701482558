/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from 'react';
import { Button, Card, Row } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import printer from '../../../../assets/printer.png';
import { useCurrentStock } from '../ContextAPI';
import ComponentToPrint from './ComponentToPrint';

export default function PrintIndex() {
    const { setShowPdf } = useCurrentStock();
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleClose = () => {
        setShowPdf(false);
    };

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Dispensary Current Stock</h3>
                <div>
                    <Button className="btn btn-light me-2" onClick={handlePrint}>
                        <img style={{ width: '22px' }} src={printer} alt="" />
                    </Button>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <ComponentToPrint ref={componentRef} />
                </Row>
            </Card.Body>
        </Card>
    );
}
