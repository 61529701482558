/* eslint-disable react-hooks/exhaustive-deps */
import { toArray } from 'lodash';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useDonation } from './ContextAPI';

export default function DrugTable({ drugList }) {
    const { donateData, setDonateData, setDonateList, rowData, setRowData, setRowDataList } =
        useDonation();

    const tableStyle = {
        paddingLeft: '5px',
        paddingRight: '5px',
    };

    const cellStyle = {
        minWidth: '150px',
        maxWidth: '150px',
        lineHeight: '33px',
    };

    const donateHandler = (value, item, row) => {
        const itemdata = item;
        itemdata.donation_qty = value;
        const list = { ...donateData };
        list[itemdata.batch_no] = itemdata;
        if (value === '') {
            delete list[itemdata.batch_no];
        }
        setDonateData(list);

        const rowdata = row;
        const rlist = { ...rowData };
        rlist[rowdata.drug_id] = rowdata;
        if (value === '') {
            delete rlist[rowdata.drug_id];
        }
        setRowData(rlist);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(donateData);
        setDonateList(arrOfObj);
    }, [donateData]);

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(rowData);
        setRowDataList(arrOfObj);
    }, [donateData]);

    function ItemTitle() {
        return <span className="ps-2">Item Name</span>;
    }

    function Title() {
        return (
            <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                <thead>
                    <tr>
                        <th className="m-0" style={cellStyle}>
                            Batch No
                        </th>

                        <th className="m-0" style={cellStyle}>
                            Expire Date
                        </th>

                        <th className="m-0" style={cellStyle}>
                            Quantity
                        </th>

                        <th className="m-0" style={cellStyle}>
                            Month Consumption
                        </th>

                        <th className="m-0" style={cellStyle}>
                            10% Consumption
                        </th>

                        <th className="m-0" style={cellStyle}>
                            Donate Qty
                        </th>
                    </tr>
                </thead>
            </table>
        );
    }

    const columns = [
        {
            name: <ItemTitle />,
            cell: (row) => <span className="ps-2">{row.drug_name}</span>,
            sortable: true,
            wrap: true,
            width: '250px',
            fixed: true,
        },
        {
            name: <Title />,
            cell: (row) => (
                <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                    <tbody>
                        <tr style={{ borderTopWidth: '0px' }}>
                            <td style={cellStyle}>{row.batch_info?.map((el) => el.batch_no)}</td>
                            <td style={cellStyle}>{row.batch_info?.map((el) => el.expire_date)}</td>
                            <td style={cellStyle}>
                                {row.batch_info?.map((el) => el.available_qty)}
                            </td>
                            <td style={cellStyle}>{row.consumption_info?.consumption_ratio}</td>
                            <td style={cellStyle}>
                                {row.consumption_info?.consumption_percentage}
                            </td>
                            <td style={cellStyle}>
                                {row.batch_info?.map((el) => (
                                    <Form.Control
                                        size="sm"
                                        type="number"
                                        onBlur={(e) => {
                                            donateHandler(e.target.value, el, row);
                                        }}
                                    />
                                ))}
                            </td>
                        </tr>
                    </tbody>
                </table>
            ),
        },
    ];

    const search_column = { name: 'Name' };
    return useDataTable({
        columns,
        data: drugList,
        search_column,
        pagination: false,
        cellPadding: '0px',
        borderWidth: '0px',
    });
}
