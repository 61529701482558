/*
 * Author M. Atoar Rahman
 * Title: Hook: useWindowWidth
 * Description: Hook: useWindowWidth
 * Date: 15/03/2022
 */

import { useEffect, useState } from 'react';

export default function useWindowWidth() {
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenSize(window.innerWidth);
        });
        return () =>
            window.removeEventListener('resize', () => {
                setScreenSize(window.innerWidth);
            });
    }, []);

    return screenSize;
}
