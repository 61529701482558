/*
 * Author M. Atoar Rahman
 * Title: Forget Password
 * Description: Page for Forget Password
 * Date: 15/03/2022
 */

import React from 'react';
import { Button, ButtonGroup, ButtonToolbar, Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import logoWhite from '../../assets/unhcr-logo-white.png';
import styles from '../../styles/LoginForm.module.css';

export default function ForgetPassword() {
    return (
        <div className={styles.login}>
            <div className={styles.logo}>
                <img src={logoWhite} alt="" height={50} />
            </div>

            <Form>
                <h4>Forget Password ?</h4>
                <span>Enter your email to reset your password</span>
                <Form.Group className="mb-3 formGroup" controlId="forSendEmail">
                    <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>

                <ButtonToolbar
                    className={styles.buttonGroups}
                    aria-label="Toolbar with button groups"
                >
                    <ButtonGroup className="me-2" aria-label="Request">
                        <NavLink exact="true" to="/code-send-to-email">
                            <Button className={styles.btnGroup} variant="primary" type="submit">
                                Request
                            </Button>
                        </NavLink>
                    </ButtonGroup>
                    <ButtonGroup className="ms-2" aria-label="Cancel">
                        <NavLink exact="true" to="/login">
                            <Button className={styles.btnGroup} variant="primary" type="submit">
                                Cancel
                            </Button>
                        </NavLink>
                    </ButtonGroup>
                </ButtonToolbar>
            </Form>

            <p>Developed &amp; Maintenance By Nanosoft</p>
        </div>
    );
}
