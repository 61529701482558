/*
 * Author M. Atoar Rahman
 * Title: D2 Final Approval
 * Description: Page for D2 Final Approval
 * Date: 20/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { D2ApprovalProvider } from '../unhcr/d2FinalApproval/ContextAPI';
import Index from '../unhcr/d2FinalApproval/Index';
import PermissionDenied from './PermissionDenied';

export default function D2FinalApproval() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['d2-final-approval']) return <PermissionDenied />;

    return (
        <D2ApprovalProvider>
            <Index />
        </D2ApprovalProvider>
    );
}
