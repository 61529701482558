/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Internal Request Approve
 * Description: Dispensary Internal Request Approve
 * Date: 30/06/2022
 */

import { toArray } from 'lodash';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useInternalRequest } from './ContextAPI';

export default function ApproveTable({ internalReqId }) {
    const { receiveData, setReceiveData, setApproveList, getAllData, rowDataSingleReq } =
        useInternalRequest(internalReqId);

    useEffect(() => {
        rowDataSingleReq?.map((el) => {
            const itemdata = {};
            itemdata.id = el.id;
            itemdata.drug_id = el.drug_id;
            itemdata.req_qty = el.req_qty;
            itemdata.available_qty = el.available_qty;
            itemdata.req_remark = el.req_remark;
            itemdata.supplied_qty =
                Number(el.available_qty) > Number(el.req_qty) ? el.req_qty : el.available_qty;
            itemdata.approve_qty =
                Number(el.available_qty) > Number(el.req_qty) ? el.req_qty : el.available_qty;

            const list = receiveData;
            list[itemdata.drug_id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }

            setReceiveData(list);

            return receiveData;
        });
    }, [rowDataSingleReq.length]);

    const approveRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.supplied_qty = value;
        itemdata.approve_qty = value;
        const list = { ...receiveData };
        list[itemdata.drug_id] = itemdata;
        if (value === '') {
            delete list[itemdata.drug_id];
        }
        setReceiveData(list);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(receiveData);
        setApproveList(arrOfObj);
        getAllData(internalReqId);
    }, [receiveData]);

    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Available Qty',
            selector: (row) => row.available_qty,
            sortable: true,
        },
        {
            name: 'Request Qty',
            selector: (row) => row.req_qty,
            sortable: true,
        },
        {
            name: 'Approve Qty',
            selector: (row) => row.approve_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        approveRowHandler(e.target.value, row);
                    }}
                    defaultValue={receiveData?.[row.drug_id]?.approve_qty}
                />
            ),
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({
        columns,
        data: rowDataSingleReq,
        search_column,
        pagination: false,
    });
}
