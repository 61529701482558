/*
 * Author M. Atoar Rahman
 * Title: Center Stockin
 * Description: Index for Center Stockin
 * Date: 30/04/2022
 */

import { Button, Card } from 'react-bootstrap';
import { useCenterStockIn } from './ContextAPI';
import DataTable from './DataTable';
import FormInfo from './FormInfo';
import PrintIndex from './pdf/PrintIndex';

export default function Index() {
    const { show, setShow, rowData, setRowData, showPdf, setIsSubmitted } = useCenterStockIn();
    const createHander = () => {
        setShow(true);
        setRowData('');
        setIsSubmitted(false);
    };
    const backHander = () => {
        setShow(false);
        setRowData('');
    };
    return showPdf ? (
        <PrintIndex />
    ) : (
        <Card>
            <Card.Header className="cardHeader">
                <h3>GRN Stock In</h3>
                {show ? (
                    <div>
                        <Button onClick={() => backHander()} className="btnInfo" variant="primary">
                            Back to List
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button
                            onClick={() => createHander()}
                            className="btnInfo"
                            variant="primary"
                        >
                            Create New
                        </Button>
                    </div>
                )}
            </Card.Header>
            <Card.Body>{show ? <FormInfo editData={rowData} /> : <DataTable />}</Card.Body>
        </Card>
    );
}
