/*
 * Author M. Atoar Rahman
 * Title: Medicines Requisition Facility
 * Description: Page for Medicines Requisition Facility
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { D1FormProvider } from '../facility/d1Form/ContextAPI';
import Index from '../facility/d1Form/Index';
import PermissionDenied from './PermissionDenied';

export default function D1Form() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['d1-preparation']) return <PermissionDenied />;

    return (
        <D1FormProvider>
            <Index />
        </D1FormProvider>
    );
}
