/*
 * Author M. Atoar Rahman
 * Title: Expired Medicine
 * Description: Page for Expired Medicine
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { ExpiredMedicineProvider } from '../misReports/expiredMedicine/ContextAPI';
import Index from '../misReports/expiredMedicine/Index';
import PermissionDenied from './PermissionDenied';

export default function ExpiredMedicine() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['expired-medicine']) return <PermissionDenied />;

    return (
        <ExpiredMedicineProvider>
            <Index />
        </ExpiredMedicineProvider>
    );
}
