/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Facility Stock Receive Approval Context
 * Description: Facility Stock Receive Approval Context
 * Date: 27/06/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Create Context
const ContextAPI = createContext();

export function useApproval() {
    return useContext(ContextAPI);
}

// Provider Component
export function FacilityStockReceiveApprovalProvider({ children }) {
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState({});
    const [pendingData, setPendingData] = useState();
    const [approvedData, setApprovedData] = useState();
    const [showPdf, setShowPdf] = useState(false);
    const [pdfData, setPdfData] = useState();

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data  All D1 Data
    const getPendingData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllD1Url('received', 1), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setPendingData(response?.data?.d1_preparation_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const getApprovedData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllD1Url('receive_approved', 1), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setApprovedData(response?.data?.d1_preparation_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // API Function: Add Approval
    const addFacilityStockReceiveApproval = async (data) => {
        try {
            await axiosPrivate.post(UrlService.approvalD1StockReceiveUrl(), JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setShow(false);
            getPendingData();
            getApprovedData();
            ToastifyService.updateNotify('Approve Successfull');
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                rowData,
                setRowData,
                pendingData,
                getPendingData,
                approvedData,
                getApprovedData,
                addFacilityStockReceiveApproval,
                showPdf,
                setShowPdf,
                pdfData,
                setPdfData,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
