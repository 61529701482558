/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect } from 'react';
import { Row, Table } from 'react-bootstrap';
import logo from '../../../../assets/unhcr-logo-blue.png';
import CommonApiService from '../../../../services/CommonApiService';
import { useCurrentStock } from '../ContextAPI';

function ComponentToPrint(props, ref) {
    const { drugCategory, getDrugCategory } = CommonApiService();
    const { data } = useCurrentStock();

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    console.log(data);

    return (
        <fieldset ref={ref} className="customFieldset donation p-5">
            <div className="text-center mb-4">
                <img className="mb-3" width="200px" src={logo} alt="" />
                <br />
                <h5 className="d-block text-decoration-underline mb-5">
                    {data[0]?.warehouse_name}, Warehouse
                </h5>
            </div>
            <Row>
                {drugCategory?.map((category) => (
                    <Fragment key={category.id}>
                        <h6 className="ps-0">{category.name}</h6>
                        <Table striped bordered hover className="mb-5">
                            <thead>
                                <tr>
                                    <th width="300px">Drug Name</th>
                                    <th className="text-center">Available Stock</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.map(
                                    (item) =>
                                        category.id == item.category_id && (
                                            <tr key={Math.random()}>
                                                <td width="300px">{item?.drug_name}</td>

                                                <td className="text-center" width="150px">
                                                    {item?.available_stock}
                                                </td>
                                            </tr>
                                        )
                                )}
                            </tbody>
                        </Table>
                    </Fragment>
                ))}
            </Row>
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;
