/*
 * Author M. Atoar Rahman
 * Title: D1 Approval
 * Description: Page for D1 Approval
 * Date: 20/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { D1ApprovalProvider } from '../facility/d1Approval/ContextAPI';
import Index from '../facility/d1Approval/Index';
import PermissionDenied from './PermissionDenied';

export default function D1Approval() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['d1-approval']) return <PermissionDenied />;

    return (
        <D1ApprovalProvider>
            <Index />
        </D1ApprovalProvider>
    );
}
