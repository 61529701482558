/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/*
 * Author M. Atoar Rahman
 * Title: PO Wise Stock DataTable
 * Description: PO Wise Stock DataTable
 * Date: 15/03/2022
 */

import moment from 'moment';
import { Badge, Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { usePoReports } from './ContextAPI';

export default function DataTable() {
    const { data, setRowData, setShowModal } = usePoReports();
    const axiosPrivate = useAxiosPrivate();

    const handleShow = async (row) => {
        try {
            const response = await axiosPrivate.post(
                UrlService.getPurchaseOrderDetailsUrl(),
                JSON.stringify({ p_id: row.id }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setRowData(response?.data?.request_data);
            setShowModal(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const columns = [
        {
            name: 'PO No.',
            selector: (row) => row.purshase_order_no,
            sortable: true,
            center: true,
        },
        {
            name: 'PO Date',
            selector: (row) => row.purshase_order_date,
            format: (row) =>
                row.purshase_order_date
                    ? moment(row.purshase_order_date).format('DD MMM YYYY')
                    : '',
            sortable: true,
            center: true,
        },
        {
            name: 'Supplied Name',
            selector: (row) => row.supplier_name,
            sortable: true,
            wrap: true,
            center: true,
        },
        {
            name: 'Supplied Date',
            selector: (row) => row.purchase_order_supplied_date,
            format: (row) =>
                row.purchase_order_supplied_date
                    ? moment(row.purchase_order_supplied_date).format('DD MMM YYYY')
                    : '',
            sortable: true,
            center: true,
        },
        {
            name: 'Warehouse Name',
            selector: (row) => row.warehouse_name,
            sortable: true,
            wrap: true,
            center: true,
        },
        {
            name: 'Status',
            sortable: true,
            center: true,
            cell: (row) =>
                row.purchase_order_status == 'received' ? (
                    <Badge className="badgeActive" bg="success">
                        Received
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            center: true,
            cell: (row) => (
                <Button
                    onClick={() => handleShow(row)}
                    className="btnSuccess"
                    type="button"
                    variant="success"
                >
                    View
                </Button>
            ),
        },
    ];

    const search_column = {};
    return useDataTable({ columns, data, search_column });
}
