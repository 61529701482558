import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { ApprovalProvider } from '../facility/internalRequestReceive/ContextAPI';
import Index from '../facility/internalRequestReceive/Index';
import PermissionDenied from './PermissionDenied';

export default function InternalRequestFacilityReceive() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['facility-internal-request-receive']) return <PermissionDenied />;

    return (
        <ApprovalProvider>
            <Index />
        </ApprovalProvider>
    );
}
