/* eslint-disable jsx-a11y/anchor-is-valid */
/*
 * Author M. Atoar Rahman
 * Title: Admin Header
 * Description: Admin Header
 * Date: 15/03/2022
 */

import React from 'react';
import UserDropdown from './UserDropdown';
import WindowFullscreen from './WindowFullscreen';
import { useAuth } from '../../../contexts/AuthContext';

function Header(props, ref) {
    const { auth } = useAuth();
    return (
        <nav className="main-header navbar navbar-expand navbar-dark navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a ref={ref} className="nav-link" href="#" role="button">
                        <i className="fas fa-exchange-alt" />
                    </a>
                </li>
            </ul>

            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* Notifications Dropdown Menu */}
                <li className="nav-item dropdown notification">
                    <a
                        className="nav-link"
                        style={{ fontSize: '12px' }}
                        data-toggle="dropdown"
                        href="#"
                    >
                        {auth?.employee_info?.partner_name}{' '}
                        {auth?.employee_info?.facility_name
                            ? ` | ${auth?.employee_info?.facility_name} `
                            : ' '}
                        {auth?.employee_info?.dispensary_name
                            ? ` | ${auth?.employee_info?.dispensary_name} `
                            : `${
                                  auth?.employee_info?.layer_id === '2'
                                      ? ' central UNHCR '
                                      : auth?.employee_info?.designation
                              } `}
                    </a>
                </li>
                <li className="nav-item">
                    <WindowFullscreen />
                </li>
                {/* User Info  */}
                <UserDropdown />
            </ul>
        </nav>
    );
}
const forwardedHeader = React.forwardRef(Header);
export default forwardedHeader;
