import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import React from 'react';
import DataTable from 'react-data-table-component';
import ContentWrapper from '../layouts/ContentWrapper';

function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
}

function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i += 1) {
        results.push(i);
    }

    return results;
}

const columns = [
    {
        name: 'Title',
        selector: (row) => row.title,
        sortable: true,
    },
    {
        name: 'Year',
        selector: (row) => row.year,
        sortable: true,
    },
    {
        button: true,
        cell: () => (
            <div className="App">
                <div className="openbtn text-center">
                    <button
                        type="button"
                        className="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                    >
                        <i className="fa-solid fa-pencil" />
                    </button>
                    <div className="modal" tabIndex="-1" id="myModal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Modal title</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body">
                                    <p>Modal body text goes here.</p>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button type="button" className="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ),
    },
];

function BootyPagination({
    rowsPerPage,
    rowCount,
    onChangePage,
    // onChangeRowsPerPage, // available but not used here
    currentPage,
}) {
    const handleBackButtonClick = () => {
        onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = () => {
        onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
        onChangePage(Number(e.target.value));
    };

    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    return (
        <nav>
            <ul className="pagination">
                <li className="page-item">
                    <button
                        type="button"
                        className="page-link"
                        onClick={handleBackButtonClick}
                        disabled={previosDisabled}
                        aria-disabled={previosDisabled}
                        aria-label="previous page"
                    >
                        Previous
                    </button>
                </li>
                {pageItems.map((page) => {
                    const className = page === currentPage ? 'page-item active' : 'page-item';

                    return (
                        <li key={page} className={className}>
                            <button
                                type="button"
                                className="page-link"
                                onClick={handlePageNumber}
                                value={page}
                            >
                                {page}
                            </button>
                        </li>
                    );
                })}
                <li className="page-item">
                    <button
                        type="button"
                        className="page-link"
                        onClick={handleNextButtonClick}
                        disabled={nextDisabled}
                        aria-disabled={nextDisabled}
                        aria-label="next page"
                    >
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    );
}

const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
        <input
            htmlFor="booty-check"
            type="checkbox"
            className="form-check-input"
            ref={ref}
            onClick={onClick}
            {...rest}
        />
        {/* <label htmlFor="booty-check" className="form-check-label" id="booty-check" /> */}
    </div>
));

const data = [
    {
        id: 1,
        title: 'Beetlejuice',
        year: '1988',
    },
    {
        id: 2,
        title: 'Ghostbusters',
        year: '1984',
    },
];

export default function ProductList() {
    return (
        <ContentWrapper>
            <div style={{ width: '80%', textAlign: 'center', paddingTop: 50 }}>
                <h2>Medicine List</h2>
                {/* <DataTable
                    columns={columns}
                    data={data}
                    selectableRows
                    theme="solarized"
                    expandableRowsComponent={ExpandedComponent}
                />
                ; */}
                <div className="card">
                    <DataTable
                        // title="Medicines"
                        columns={columns}
                        data={data}
                        defaultSortFieldID={1}
                        pagination
                        paginationComponent={BootyPagination}
                        selectableRows
                        selectableRowsComponent={BootyCheckbox}
                        tableClass="table"
                        tableHeadClass="table head"
                        tableHeadRowClass="table rowhead"
                        tableBodyClass="table body"
                        norecordsfound={{ align: 'center', title: 'no records' }}
                    />
                </div>
            </div>
        </ContentWrapper>
    );
}
