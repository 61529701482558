/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Requisition DataTable
 * Description: Requisition DataTable
 * Date: 28/06/2022
 */

import { toArray } from 'lodash';
import { Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useApproval } from './ContextAPI';

export default function ShowTable({ data }) {
    const { setReceiveList, receiveData, setReceiveData } = useApproval();

    const requisitionRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.approved_supply_qty = value;
        const list = { ...receiveData, [item.id]: itemdata };
        if (value === '') {
            delete list[item.id];
        }
        setReceiveData(list);
        const arrOfObj = toArray(list);
        setReceiveList(arrOfObj);
    };

    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Available Quantity',
            selector: (row) => row.available_stock,
            sortable: true,
        },
        {
            name: 'Distribution Quantity',
            selector: (row) => row.supplied_qty,
            sortable: true,
        },

        {
            name: 'Approve Distribution Quantity',
            selector: (row) => row.approved_supply_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        requisitionRowHandler(e.target.value, row);
                    }}
                    defaultValue={receiveData?.[row.id]?.approved_supply_qty}
                />
            ),
            reorder: true,
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
