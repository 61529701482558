/*
 * Author M. Atoar Rahman
 * Title: Consumption Report
 * Description: Index for Consumption Report
 * Date: 05/06/2022
 */

import { Button, Card } from 'react-bootstrap';
import { usePoReports } from './ContextAPI';
import DataTable from './DataTable';
import FormInfo from './FormInfo';
import PrintIndex from './pdf/PrintIndex';
import ShowModel from './ShowModel';

export default function Index() {
    const { showPdf, listShow, setListShow, setShowPdf } = usePoReports();
    const backToForm = () => {
        setListShow(false);
        setShowPdf(false);
    };
    const printPreview = () => {
        setListShow(false);
        setShowPdf(true);
    };

    return showPdf ? (
        <PrintIndex />
    ) : (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Purchase Order Reports</h3>
                {listShow && (
                    <div>
                        <Button
                            onClick={() => printPreview()}
                            className="btnDark me-1"
                            variant="dark"
                        >
                            Print Preview
                        </Button>
                        <Button onClick={() => backToForm()} className="btnInfo" variant="primary">
                            Go Back
                        </Button>
                    </div>
                )}
            </Card.Header>
            <Card.Body>{listShow ? <DataTable /> : <FormInfo />}</Card.Body>
            <ShowModel />
        </Card>
    );
}
