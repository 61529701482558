/*
 * Author M. Atoar Rahman
 * Title: dispensary Current Stock
 * Description: Page for dispensary Current Stock
 * Date: 13/07/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { CurrentStockProvider } from '../dispensary/currentStock/ContextAPI';
import Index from '../dispensary/currentStock/Index';
import PermissionDenied from './PermissionDenied';

export default function DispensaryCurrentStock() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['dispensary-current-stock']) return <PermissionDenied />;

    return (
        <CurrentStockProvider>
            <Index />
        </CurrentStockProvider>
    );
}
