/*
 * Author M. Atoar Rahman
 * Title: Bin Card
 * Description: Bin Card
 * Date: 15/07/2022
 */
import { useBinCard } from './ContextAPI';
import FormInfo from './FormInfo';
import PrintIndex from './PrintIndex';

export default function Index() {
    const { showBin } = useBinCard();

    return showBin ? <PrintIndex /> : <FormInfo />;
}
