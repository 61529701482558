/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: ContextAPI
 * Description: ContextAPI
 * Date: 15/03/2022
 */

import { createContext, useContext, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import UrlService from '../../../services/UrlService';

const ContextAPI = createContext({});

export function useEmployee() {
    return useContext(ContextAPI);
}

export function EmployeeProvider({ children }) {
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState();
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState('');

    const { auth } = useAuth();
    const partnerId = auth?.employee_info?.partner_id ? auth?.employee_info?.partner_id : null;

    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(
                partnerId
                    ? UrlService.getEmployeeByPartnerIdUrl(partnerId)
                    : UrlService.getAllEmployeeUrl(),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setData(response?.data?.employee_info);
            // console.log(response?.data.data);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                data,
                setData,
                getAllData,
                show,
                setShow,
                rowData,
                setRowData,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
