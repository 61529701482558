import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import logo from '../../../../assets/unhcr-logo-blue.png';
import { useDonation } from '../ContextAPI';

function ComponentToPrint(props, ref) {
    const { rowData } = useDonation();

    let totalAmount = 0;

    return (
        <fieldset ref={ref} className="customFieldset donation p-5">
            <div className="text-center mb-4">
                <img width="200px" src={logo} alt="" />
            </div>
            <Row>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>
                                <h6 className="mb-0 text-center">
                                    <b>DONATION CERTIFICATE</b>
                                </h6>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>

            <Row>
                <Table className="table-borderless">
                    <tbody>
                        <tr>
                            <td colSpan={4}>Date: {rowData.donation_date}</td>
                        </tr>
                        <tr>
                            <td colSpan={4}>NOTRE/Our Code: {rowData.our_code}</td>
                        </tr>
                        <tr>
                            <td width="20%">
                                <b>By the present:</b>
                            </td>
                            <td width="40%">
                                <b>UNHCR</b>
                            </td>
                            <td width="20%">
                                <b>Represented by:</b>
                            </td>
                            <td width="20%">{rowData.outer_agency_name}</td>
                        </tr>
                        <tr>
                            <td width="20%">Name:</td>
                            <td colSpan={3}>{rowData.unhcr_focal_person}</td>
                        </tr>
                        <tr>
                            <td width="20%">Position:</td>
                            <td colSpan={3}>{rowData.unhcr_designation}</td>
                        </tr>
                        <tr>
                            <td width="20%">Based in:</td>
                            <td colSpan={3}>{rowData.unhcr_address}</td>
                        </tr>
                        <tr>
                            <td colSpan={4}>
                                <b>Certificates that the following material:</b>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Expiry Date</th>
                            <th>Total Value (US$)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rowData?.drug_list?.map((el) => {
                            totalAmount += Math.floor(el.donation_qty * el.unit_price);
                            return (
                                <tr key={Math.random()}>
                                    <td>{el.drug_name}</td>
                                    <td>{el.donation_qty}</td>
                                    <td>{Math.floor(el.unit_price)}</td>
                                    <td>{el.expire_date}</td>
                                    <td>{Math.floor(el.donation_qty * el.unit_price)}</td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td colSpan={4} className="text-end">
                                <b>Total:</b>
                            </td>
                            <td>{totalAmount}</td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Col>
                    <Table bordered>
                        <tbody>
                            <tr>
                                <td>
                                    <b className="text-center">For the Donor</b>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table className="table-borderless">
                        <tbody>
                            <tr>
                                <td>UNHCR</td>
                            </tr>
                            <tr>
                                <td>{rowData.unhcr_focal_person}</td>
                            </tr>
                            <tr>
                                <td>{rowData.unhcr_designation}</td>
                            </tr>
                            <tr>
                                <td>{rowData.unhcr_address}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table bordered>
                        <tbody>
                            <tr>
                                <td>
                                    <b className="text-center">For the Receiver</b>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table className="table-borderless">
                        <tbody>
                            <tr>
                                <td>{rowData.outer_agency_name}</td>
                            </tr>
                            <tr>
                                <td>{rowData.receiver_focal_person}</td>
                            </tr>
                            <tr>
                                <td>{rowData.receiver_designation}</td>
                            </tr>
                            <tr>
                                <td>{rowData.receiver_address}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;
