/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Return Request
 * Description: Return Request Form: Create and Update
 * Date: 26/04/2022
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useStockReturnRequest } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const { setShow, setRowData, setFirstSubmitInfo, setListShow } = useStockReturnRequest();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    // Local State
    const [partnerData, setPartnerData] = useState();
    const [facilityData, setFacilityData] = useState();
    // const [dispensaryData, setDispensaryData] = useState();

    const {
        // register,
        formState: { errors },
        reset,
        resetField,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                partner_id: auth?.employee_info?.partner_id && {
                    label: auth?.employee_info?.partner_name,
                    value: auth?.employee_info?.partner_id,
                },
                facility_id: auth?.employee_info?.facility_id && {
                    label: auth?.employee_info?.facility_name,
                    value: auth?.employee_info?.facility_id,
                },
                // dispensary_id: auth?.employee_info?.dispensary_id && {
                //     label: auth?.employee_info?.dispensary_name,
                //     value: auth?.employee_info?.dispensary_id,
                // },
            }),
            []
        ),
    });

    const {
        partner,
        getPartner,
        facilityByPartner,
        getFacilityByPartner,
        // dispensaryByFacility,
        getDispensaryByFacility,
    } = CommonApiService();

    const dataList = useCallback(() => {
        getPartner(); // Partner
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        //
        // setDispensaryData(
        //     dispensaryByFacility?.map((el) => ({
        //         value: el.id,
        //         label: el.name,
        //     }))
        // );
    }, [partner, facilityByPartner]);

    // Get Facility by Partner login
    useEffect(() => {
        if (auth?.employee_info?.partner_id) {
            getFacilityByPartner(auth?.employee_info?.partner_id);
        }
    }, [auth?.employee_info?.partner_id]);

    // Get Dispensary by Facility login
    useEffect(() => {
        if (auth?.employee_info?.facility_id) {
            getDispensaryByFacility(auth?.employee_info?.facility_id);
        }
    }, [auth?.employee_info?.partner_id]);

    const durationData = [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
    ];

    const formSubmitHandler = async (data) => {
        const formData = {
            partner_id: data?.partner_id?.value,
            facility_id: data?.facility_id?.value,
            dispensary_id: data?.dispensary_id?.value,
            duration: data?.duration?.value,
        };

        setFirstSubmitInfo(formData);

        try {
            const response = await axiosPrivate.post(
                UrlService.getMedicineListForReturnRequest(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.message);
                const message = Object.keys(response.data.message);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
                setShow(false);
            } else {
                setRowData(response.data.drug_list);
                setShow(true);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Card>
            <Card.Header className="cardHeader">
                <>
                    <h3>Stock Return Request</h3>
                    <div>
                        <Button
                            onClick={() => setListShow(true)}
                            className="btnInfo"
                            variant="primary"
                        >
                            Back to List
                        </Button>
                    </div>
                </>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Row>
                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Partner Agency<span className="invalid">*</span>
                                </Form.Label>
                                <Controller
                                    name="partner_id"
                                    id="partner_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors?.partner_id && 'invalidInput'}
                                            options={partnerData}
                                            isDisabled={auth?.employee_info?.partner_id}
                                            onBlur={async () => {
                                                await resetField('facility_id');
                                                await resetField('dispensary_id');
                                                await getFacilityByPartner(field?.value?.value);
                                            }}
                                        />
                                    )}
                                    rules={{ required: 'Please select your partner.' }}
                                />
                                {errors?.partner_id && (
                                    <span className="invalid">{errors?.partner_id?.message}</span>
                                )}
                            </Form.Group>
                        </Col>

                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Facility Name</Form.Label>
                                <Controller
                                    name="facility_id"
                                    id="facility_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors?.facility_id && 'invalidInput'}
                                            options={facilityData}
                                            isDisabled={
                                                auth?.employee_info?.partner_id &&
                                                auth?.employee_info?.facility_id
                                            }
                                            onBlur={async () => {
                                                await resetField('dispensary_id');
                                                await getDispensaryByFacility(field?.value?.value);
                                            }}
                                        />
                                    )}
                                    // rules={{ required: 'Please select your facility.' }}
                                />
                                {errors?.facility_id && (
                                    <span className="invalid">{errors?.facility_id?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        {/* <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Department Name</Form.Label>
                                <Controller
                                    name="dispensary_id"
                                    id="dispensary_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={
                                                errors?.dispensary_id && 'invalidInput'
                                            }
                                            options={dispensaryData}
                                            isDisabled={
                                                auth?.employee_info?.partner_id &&
                                                auth?.employee_info?.facility_id &&
                                                auth?.employee_info?.dispensary_id
                                            }
                                        />
                                    )}
                                    // rules={{ required: 'Please select your facility.' }}
                                />
                                {errors?.dispensary_id && (
                                    <span className="invalid">
                                        {errors?.dispensary_id?.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col> */}
                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Duration<span className="invalid">*</span>
                                </Form.Label>
                                <Controller
                                    name="duration"
                                    id="duration"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors?.duration && 'invalidInput'}
                                            options={durationData}
                                        />
                                    )}
                                    rules={{ required: 'Please select your duration.' }}
                                />
                                {errors?.duration && (
                                    <span className="invalid">{errors?.duration?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <Row className="text-end">
                                <Col lg={12}>
                                    <Button
                                        className="btnSuccess"
                                        onClick={handleSubmit(formSubmitHandler)}
                                        type="button"
                                        variant="success"
                                    >
                                        View Stock
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}
