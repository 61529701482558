/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect } from 'react';
import { Row, Table } from 'react-bootstrap';
import logo from '../../../../assets/unhcr-logo-blue.png';
import CommonApiService from '../../../../services/CommonApiService';
import { useCurrentStock } from '../ContextAPI';
import { makeGroupBy, sumOfTotal } from '../../../../Utils';

function ComponentToPrint(props, ref) {
    const { drugCategory, getDrugCategory } = CommonApiService();
    const { data, responseLayer } = useCurrentStock();

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const tableStyle = {
        paddingLeft: '0px',
        paddingRight: '0px',
        lineHeight: '33px',
    };

    return (
        <fieldset ref={ref} className="customFieldset donation p-5">
            <div className="text-center mb-4">
                <img className="mb-3" width="200px" src={logo} alt="" />
                <br />
                <h5 className="d-block text-decoration-underline mb-5">
                    {responseLayer === 'central'
                        ? data[0]?.warehouse_name
                        : responseLayer === 'partner'
                        ? data[0]?.partner_name
                        : responseLayer === 'facility'
                        ? data[0]?.facility_name
                        : responseLayer === 'dispensary'
                        ? data[0]?.dispensary_name
                        : ''}
                    , Warehouse
                </h5>
            </div>
            <Row>
                {drugCategory?.map((category) => (
                    <Fragment key={category.id}>
                        <h6 className="ps-0">{category.name}</h6>
                        <Table striped bordered hover className="mb-5">
                            <thead>
                                <tr>
                                    <th
                                        width="300px"
                                        style={{
                                            lineHeight: '33px',
                                        }}
                                    >
                                        Drug Name
                                    </th>
                                    <th className="p-0 text-center">
                                        <table
                                            className="table table-bordered mb-0 text-center"
                                            style={tableStyle}
                                        >
                                            <thead>
                                                <tr
                                                    style={{
                                                        borderBottom: '0px',
                                                        borderTop: '0px',
                                                    }}
                                                >
                                                    <th
                                                        width="150px"
                                                        className="m-0"
                                                        style={{
                                                            lineHeight: '33px',
                                                        }}
                                                    >
                                                        Batch No
                                                    </th>
                                                    <th
                                                        width="150px"
                                                        className="m-0"
                                                        style={{
                                                            lineHeight: '33px',
                                                        }}
                                                    >
                                                        Expired Date
                                                    </th>
                                                    <th
                                                        width="150px"
                                                        className="m-0"
                                                        style={{
                                                            lineHeight: '33px',
                                                        }}
                                                    >
                                                        Available Qty
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {data?.map(
                                    (item) =>
                                        category.id == item.category_id && (
                                            <Fragment key={Math.random() * 1000}>
                                                <tr key={Math.random()}>
                                                    <td width="300px">{item?.drug_name}</td>
                                                    <td
                                                        width="300px"
                                                        style={{
                                                            paddingTop: '0px',
                                                            paddingBottom: '0px',
                                                            paddingLeft: '0px',
                                                            paddingRight: '0px',
                                                            lineHeight: '33px',
                                                        }}
                                                    >
                                                        <table
                                                            className="table table-bordered mb-0 text-center"
                                                            style={tableStyle}
                                                        >
                                                            <tbody>
                                                                {(() => {
                                                                    const groupData = makeGroupBy(
                                                                        item?.batch_info,
                                                                        'batch_no'
                                                                    );
                                                                    const entriesArray =
                                                                        Object.entries(groupData);
                                                                    return (
                                                                        // eslint-disable-next-line react/jsx-no-useless-fragment
                                                                        <>
                                                                            {entriesArray?.map(
                                                                                // eslint-disable-next-line no-shadow
                                                                                (item) => (
                                                                                    <tr
                                                                                        key={
                                                                                            Math.random() *
                                                                                            1000
                                                                                        }
                                                                                        style={{
                                                                                            borderTopWidth:
                                                                                                '0px',
                                                                                            borderBottomWidth:
                                                                                                '0px',
                                                                                        }}
                                                                                    >
                                                                                        <td width="150px">
                                                                                            {
                                                                                                item[1][0]
                                                                                                    .batch_no
                                                                                            }
                                                                                        </td>
                                                                                        <td width="150px">
                                                                                            {
                                                                                                item[1][0]
                                                                                                    .expire_date
                                                                                            }
                                                                                        </td>
                                                                                        <td width="150px">
                                                                                            {sumOfTotal(
                                                                                                item[1]
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )}
                                                                        </>
                                                                    );
                                                                })()}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        )
                                )}
                            </tbody>
                        </Table>
                    </Fragment>
                ))}
            </Row>
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;
