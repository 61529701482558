/*
 * Author M. Atoar Rahman
 * Title: D4Report
 * Description: Page for D4Report
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { D4ReportProvider } from '../unhcr/d4Reports/ContextAPI';
import Index from '../unhcr/d4Reports/Index';
import PermissionDenied from './PermissionDenied';

export default function D4Report() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['d4-reports']) return <PermissionDenied />;

    return (
        <D4ReportProvider>
            <Index />
        </D4ReportProvider>
    );
}
