/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: ContextAPI
 * Description: ContextAPI
 * Date: 15/03/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import UrlService from '../../../services/UrlService';

const LIST_URL = UrlService.getAllDrugUrl();
const ContextAPI = createContext({});

export function useDrug() {
    return useContext(ContextAPI);
}

export function DrugProvider({ children }) {
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState();
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(LIST_URL, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setData(response?.data?.drug_info);
            // console.log(response?.data.data);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                data,
                setData,
                getAllData,
                show,
                setShow,
                rowData,
                setRowData,
                isSubmitted,
                setIsSubmitted,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
