/*
 * Author M. Atoar Rahman
 * Title: Stock Reigister
 * Description: Stock Reigister
 * Date: 15/07/2022
 */
import { useStockRegister } from './ContextAPI';
import FormInfo from './FormInfo';
import PrintIndex from './PrintIndex';

export default function Index() {
    const { showBin } = useStockRegister();

    return showBin ? <PrintIndex /> : <FormInfo />;
}
