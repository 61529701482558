/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/*
 * Author M. Atoar Rahman
 * Title: User Reg.
 * Description: Master Setup of User Reg. Form: Create and Update
 * Date: 15/03/2022
 */

import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useUser } from './ContexAPI';

export default function FormInfo({ editData = null }) {
    const axiosPrivate = useAxiosPrivate();
    const { getAllData } = useUser();
    const { auth } = useAuth();
    const { route_permissions } = auth;

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
        getValues,
        setValue,
        setError,
        clearErrors,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',

        defaultValues: useMemo(
            () => ({
                ...editData,
                code_no: editData && {
                    label: editData?.employee?.code_no,
                    value: editData?.employee?.code_no,
                },
                role_id: editData && editData.roles,
                employee_id: editData && {
                    label: `${editData?.employee?.first_name}  ${editData?.employee?.last_name}`,
                    value: editData?.employee_id,
                },
                email: editData && editData?.email,
                status: editData ? (editData?.status == '1' ? 1 : 0) : 1,
                isPassword: !editData,
                password: editData && null,
            }),
            [editData]
        ),
    });

    // API URL
    let SAVE_URL = '';
    if (editData == null) {
        SAVE_URL = UrlService.saveUserUrl();
    } else {
        SAVE_URL = UrlService.updateUserUrl(editData.id);
    }

    const [show, setShow] = useState(false);
    const [employeeCodeData, setEmployeeCodeData] = useState('');
    const [employeeNameData, setEmployeeNameData] = useState('');
    const [roleData, setRoleData] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [changeState, setChangeState] = useState(!editData);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const partnerId = auth?.employee_info?.partner_id ? auth?.employee_info?.partner_id : null;

    const employeeList = async () => {
        try {
            const response = await axiosPrivate.get(
                partnerId
                    ? UrlService.getEmployeeByPartnerIdUrl(partnerId)
                    : UrlService.getAllEmployeeUrl(),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const empCodeNo = response?.data?.employee_info?.map((el) => ({
                value: el.id,
                label: el.code_no,
                codeNo: el.code_no,
                name: `${el.first_name} ${el.last_name}`,
                email: el.email,
            }));

            const empName = response?.data?.employee_info?.map((el) => ({
                value: el.id,
                label: `${el.first_name} ${el.last_name}`,
                codeNo: el.code_no,
                name: `${el.first_name} ${el.last_name}`,
                email: el.email,
            }));
            setEmployeeCodeData(empCodeNo);
            setEmployeeNameData(empName);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Role Data Not Found!');
            }
        }
    };

    const employeeCodeNoHandler = (data) => {
        if (data) {
            setValue('employee_id', { label: data.name, value: data.value });
            setValue('email', data.email);
        }
    };
    const employeeNameHandler = (data) => {
        if (data) {
            setValue('code_no', { label: data.codeNo, value: data.codeNo });
            setValue('email', data.email);
        }
    };

    const existUserEmail = async (emailData) => {
        const response = await axiosPrivate.get(UrlService.isExistUserEmailUrl(emailData), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (emailData && response?.data?.user_email && editData?.email !== emailData) {
            setError('employee_id', {
                type: 'coustom',
                message: 'Already! user exist on this employee!!',
            });
        } else {
            clearErrors('employee_id');
        }
    };

    const roleList = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllRoleUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const roles = response?.data?.role_info?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setRoleData(roles);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Role Data Not Found!');
            }
        }
    };

    const handleClose = () => {
        setShow(false);
        reset();
    };

    const handleShow = () => {
        setShow(true);
        employeeList();
        roleList();
    };
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            code_no: data.code_no.value,
            employee_id: data.employee_id.value,
            role_id: data.role_id.map((el) => el.value),
            status: data.status === true ? 1 : 0,
        };
        try {
            await axiosPrivate.post(SAVE_URL, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (editData != null) {
                ToastifyService.updateNotify('Data Update Successfull');
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
            }
            reset();
            getAllData();
            setShow(false);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else {
                setErrMsg('Data Inserted Failed!');
            }
            ToastifyService.errorNotify(errMsg);
        }
    };

    return (
        <>
            {editData != null ? (
                <Button className="btn btn-light actionBtn" variant="primary" onClick={handleShow}>
                    <i className="fas fa-edit" />
                </Button>
            ) : (
                <Button variant="primary" onClick={handleShow}>
                    Create New
                </Button>
            )}

            <Modal className="modalWidth55" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit User Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Username<span className="invalid">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="username"
                                        id="username"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('username', {
                                            required: 'Please enter your username!',
                                            // pattern: pattern.namePattern,
                                            validate: {
                                                isExist: async (value) => {
                                                    const response = await axiosPrivate.get(
                                                        UrlService.isExistUserNameUrl(value),
                                                        {
                                                            headers: {
                                                                'Content-Type': 'application/json',
                                                            },
                                                        }
                                                    );
                                                    if (
                                                        editData?.username ===
                                                            response?.data?.user_name?.username ||
                                                        editData?.username === value
                                                    ) {
                                                        return true;
                                                    }

                                                    return isEmpty(response?.data?.user_name);
                                                },
                                            },
                                        })}
                                        className={errors?.username && 'invalidInput'}
                                    />
                                    {errors.username && errors.username.type === 'isExist' && (
                                        <span className="invalid">Username exist!</span>
                                    )}
                                    {errors.username && (
                                        <span className="invalid">{errors.username?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Employee Info<span className="invalid">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={3} className="pe-1">
                                    <Controller
                                        name="code_no"
                                        id="code_no"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={errors?.code_no && 'invalidInput'}
                                                options={employeeCodeData}
                                                onBlur={() => {
                                                    employeeCodeNoHandler(field.value);
                                                    existUserEmail(field?.value?.email);
                                                }}
                                            />
                                        )}
                                        rules={{ required: 'Field is required!' }}
                                    />
                                    {errors.code_no && (
                                        <span className="invalid">{errors.code_no?.message}</span>
                                    )}
                                </Col>
                                <Col lg={6} className="ps-0">
                                    <Controller
                                        name="employee_id"
                                        id="employee_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.employee_id && 'invalidInput'
                                                }
                                                options={employeeNameData}
                                                onBlur={() => {
                                                    employeeNameHandler(field.value);
                                                    existUserEmail(field?.value?.email);
                                                }}
                                            />
                                        )}
                                        rules={{ required: 'Please select your employee!' }}
                                    />
                                    {errors.employee_id && (
                                        <span className="invalid">
                                            {errors.employee_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Role Info<span className="invalid">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        name="role_id"
                                        id="role_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="basic-multi-select"
                                                classNamePrefix={errors?.role_id && 'invalidInput'}
                                                options={roleData}
                                                isMulti
                                            />
                                        )}
                                        rules={{ required: 'Please select your role!' }}
                                    />
                                    {errors.role_id && (
                                        <span className="invalid">{errors.role_id?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        {editData && (!editData || route_permissions['user-info'].change_password) && (
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col lg={3}>
                                        <Form.Label>Are you want to change Password?</Form.Label>
                                    </Col>
                                    <Col lg={9}>
                                        <Form.Check
                                            type="switch"
                                            id="isPassword"
                                            name="isPassword"
                                            {...register('isPassword')}
                                            onChange={(e) => setChangeState(e.target.checked)}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        )}

                        {changeState &&
                            (!editData || route_permissions['user-info'].change_password) && (
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={9} className="offset-3">
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Label>
                                                        Password<span className="invalid">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="password"
                                                        id="password"
                                                        type="password"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register('password', {
                                                            required: 'Please enter your password.',
                                                            validate: {
                                                                isNotMin8: (value) =>
                                                                    /(?=.{8,})/.test(value),
                                                                isNotLower: (value) =>
                                                                    /^(?=.*[a-z]).*$/.test(value),
                                                                isNotUpper: (value) =>
                                                                    /^(?=.*[A-Z]).*$/.test(value),
                                                                isNotDigit: (value) =>
                                                                    /(?=.*\d)/.test(value),
                                                                isNotSpecial: (value) =>
                                                                    /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/.test(
                                                                        value
                                                                    ),
                                                                isNotMax20: (value) =>
                                                                    /^.{8,20}$/.test(value),
                                                                isNotWhiteSpace: (value) =>
                                                                    /^\S*$/.test(value),
                                                            },
                                                        })}
                                                        className={
                                                            errors?.password && 'invalidInput'
                                                        }
                                                    />
                                                    {errors.password &&
                                                        errors.password.type === 'isNotMin8' && (
                                                            <span className="invalid">
                                                                Password must minimum 8 characters!
                                                            </span>
                                                        )}
                                                    {errors.password &&
                                                        errors.password.type === 'isNotLower' && (
                                                            <span className="invalid">
                                                                Password at least one lowercase
                                                                character!
                                                            </span>
                                                        )}
                                                    {errors.password &&
                                                        errors.password.type === 'isNotUpper' && (
                                                            <span className="invalid">
                                                                Password at least one uppercase
                                                                character!
                                                            </span>
                                                        )}
                                                    {errors.password &&
                                                        errors.password.type === 'isNotDigit' && (
                                                            <span className="invalid">
                                                                Password at least one digit!
                                                            </span>
                                                        )}
                                                    {errors.password &&
                                                        errors.password.type === 'isNotSpecial' && (
                                                            <span className="invalid">
                                                                Password at least one special
                                                                character!
                                                            </span>
                                                        )}
                                                    {errors.password &&
                                                        errors.password.type === 'isNotMax20' && (
                                                            <span className="invalid">
                                                                Password should maximum 20
                                                                characters!
                                                            </span>
                                                        )}
                                                    {errors.password &&
                                                        errors.password.type ===
                                                            'isNotWhiteSpace' && (
                                                            <span className="invalid">
                                                                Password not contain white spaces!
                                                            </span>
                                                        )}
                                                    {errors.password && (
                                                        <span className="invalid">
                                                            {errors.password?.message}
                                                        </span>
                                                    )}
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Label>
                                                        Confirm Password
                                                        <span className="invalid">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="confirm_password"
                                                        id="confirm_password"
                                                        type="password"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register('confirm_password', {
                                                            required:
                                                                'Please enter your confirm password!',
                                                            validate: (value) => {
                                                                const { password } = getValues();
                                                                return (
                                                                    password === value ||
                                                                    'Passwords should match!'
                                                                );
                                                            },
                                                        })}
                                                        className={
                                                            errors?.confirm_password &&
                                                            'invalidInput'
                                                        }
                                                    />
                                                    {errors.confirm_password && (
                                                        <span className="invalid">
                                                            {errors.confirm_password?.message}
                                                        </span>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Status</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Check
                                        name="status"
                                        id="user-status-switch"
                                        type="switch"
                                        {...register('status')}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Modal.Footer className="borderTop0 pe-0">
                            <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                onClick={handleSubmit(formSubmitHandler)}
                                className="btnSuccess"
                                type="button"
                                variant="success"
                                disabled={isSubmitted}
                            >
                                {editData != null ? 'Update' : 'Create New'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
