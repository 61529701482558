/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
import { toArray } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useD1Approval } from './ContextAPI';

export default function ShowTable({ data }) {
    const { setReceiveList, receiveData, setReceiveData } = useD1Approval();

    const requisitionRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.approved_request_qty = value;
        const list = { ...receiveData };
        list[itemdata.drug_id] = itemdata;
        if (value === '') {
            delete list[itemdata.drug_id];
        }
        setReceiveData(list);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(receiveData);
        setReceiveList(arrOfObj);
    }, [receiveData]);

    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            width: '250px',
            fixed: true,
        },
        {
            name: 'Opening Balance',
            selector: (row) => row.opening_balance,
            sortable: true,
        },
        {
            name: 'Total Quantity Received During The Month',
            selector: (row) => row.received_qty,
            sortable: true,
        },
        {
            name: 'Total Quantity Consumed By the Facility (OPD, IPD, Emergency, any other dept)',
            selector: (row) => row.consumed_qty,
            sortable: true,

            width: '150px',
        },
        {
            name: 'Number Of Days Out Of Stock In The Pharmacy',
            selector: (row) => row.stock_out_days,
            sortable: true,
        },
        {
            name: 'Average Monthly Consumption',
            selector: (row) => row.avg_monthly_consumption,
            sortable: true,
        },
        {
            name: 'Total Quantities Returned To Main Agency Warehouse (Due To over stock)',
            selector: (row) => row.total_return,
            sortable: true,

            width: '150px',
        },
        {
            name: 'Total Quanties Expired or Damaged',
            selector: (row) => row.expired_qty,
            sortable: true,
        },
        {
            name: 'Earliest Date of Expiry of Physical Stocks',
            selector: (row) => row.expire_date,
            format: (row) => moment(row.expire_date).format('DD MMM YYYY'),
            sortable: true,

            width: '150px',
        },
        {
            name: 'Closing Balance (Physical Stock)',
            selector: (row) => row.closing_balance,
            sortable: true,
        },
        {
            name: 'Theoretical Stock',
            selector: (row) => row.theoritical_stock,
            sortable: true,
        },
        {
            name: 'Number of Days of Physical Stock Available',
            selector: (row) => row.number_of_days_available_stock,
            sortable: true,
        },
        {
            name: 'Request Qty',
            selector: (row) => row.requested_qty,
            sortable: true,
        },
        {
            name: 'Approve Quantity',
            selector: (row) => row.approved_request_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        requisitionRowHandler(e.target.value, row);
                    }}
                    defaultValue={receiveData?.[row.drug_id]?.approved_request_qty}
                />
            ),
            reorder: true,
            width: '150px',
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({
        columns,
        data,
        search_column,
        pagination: false,
    });
}
