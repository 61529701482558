/*
 * Author M. Atoar Rahman
 * Title: Dispensary Stock Loss
 * Description: Page for Dispensary Stock Loss
 * Date: 15/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { DispensaryStockLossProvider } from '../dispensary/stockLoss/ContextAPI';
import Index from '../dispensary/stockLoss/Index';
import PermissionDenied from './PermissionDenied';

export default function DispensaryStockLoss() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['dispensary-stock-loss']) return <PermissionDenied />;

    return (
        <DispensaryStockLossProvider>
            <Index />
        </DispensaryStockLossProvider>
    );
}
