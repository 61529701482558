import React from 'react';
import Barcode from 'react-barcode';
import { Col, Row, Table } from 'react-bootstrap';
import { useAuth } from '../../../../contexts/AuthContext';
import { useApproval } from '../ContextAPI';

function ComponentToPrint(props, ref) {
    const { pdfData } = useApproval();
    const { auth } = useAuth();
    console.log({ pdfData });
    return (
        <fieldset ref={ref} className="customFieldset p-5">
            <Row className="mb-4">
                <Col className="text-center">
                    <h3>GOODS RECEIVING NOTE</h3>
                    <h5>{pdfData?.grn_details?.partner_name}</h5>
                </Col>
            </Row>
            <Row>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>
                                <h6>GRN Number:</h6>
                            </td>
                            <td>
                                <Barcode
                                    height="30"
                                    displayValue
                                    value={pdfData?.grn_details?.grn_no}
                                    format="CODE128"
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>

            <Row>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td width="25%">
                                <b>Sender Name</b>
                            </td>
                            <td colSpan={3}>{pdfData?.grn_details?.warehouse_name}</td>
                        </tr>
                        <tr>
                            <td width="25%">
                                <b>Sender Address</b>
                            </td>
                            <td colSpan={3}>{pdfData?.grn_details?.warehouse_address}</td>
                        </tr>
                        <tr>
                            <td width="25%">
                                <b>Transporter</b>
                            </td>
                            <td width="25%">{pdfData?.transport_details?.transporter}</td>
                            <td width="25%">
                                <b>Date Received</b>
                            </td>
                            <td width="25%" />
                        </tr>
                        <tr>
                            <td width="25%">
                                <b>Vehicle Registration Number</b>
                            </td>
                            <td width="25%">{pdfData?.transport_details?.vehicle_no}</td>
                            <td width="25%">
                                <p>
                                    <b>Cointainer/Seal No.</b>
                                </p>
                                <p>(If Applicable)</p>
                            </td>
                            <td width="25%">{pdfData?.transport_details?.cointainer_no}</td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>ITEM DISCRIPTION</th>
                            <th>BATCH NO</th>
                            <th>TOTAL ITEMS RECEIVED</th>
                            <th>NO. ACCEPTED</th>
                            <th>NO. REJECTED</th>
                            <th>REJECT REASONS/REMARKS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pdfData?.stock_in_details?.map((el, index) => (
                            <tr key={Math.random()}>
                                <td>{index + 1}</td>
                                <td>{el.drug_name}</td>
                                <td>{el.batch_no}</td>
                                <td>{el.receive_qty}</td>
                                <td>{el.accepted_qty}</td>
                                <td>{el.rejected_qty}</td>
                                <td>{el.rejected_reason}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td width="30%">
                                <b>Driver Name:</b>
                            </td>
                            <td width="40%">{pdfData?.transport_details?.driver}</td>
                            <td width="30%" height="100px">
                                <b>Driver Signature:</b>
                            </td>
                        </tr>
                        <tr>
                            <td width="30%">
                                <b>Uploading Supervisor/ Warehouse Manager:</b>
                            </td>
                            <td width="40%">
                                {auth.employee_info
                                    ? `${auth.employee_info.first_name} ${auth.employee_info.last_name}`
                                    : auth.users?.username}
                            </td>
                            <td width="30%" height="100px">
                                <b>Signature Of Supervisor:</b>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;
