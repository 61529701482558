/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Requisition DataTable
 * Description: Requisition DataTable
 * Date: 05/06/2022
 */

import { toArray } from 'lodash';
import { useEffect } from 'react';
import { Badge, Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useApproval } from './ContextAPI';

export default function DistributeRequisitionTable({ data }) {
    const { setReceiveList, receiveData, setReceiveData } = useApproval();

    const requisitionRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.approved_supply_qty = value;
        const list = { ...receiveData };
        list[itemdata.drug_id] = itemdata;
        if (value === '') {
            delete list[itemdata.drug_id];
        }
        setReceiveData(list);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(receiveData);
        setReceiveList(arrOfObj);
    }, [receiveData]);

    const conditionalCellStyles = [
        {
            when: (row) => Number(row.partner_available_qty) <= 0,
            style: {
                backgroundColor: '#ffb4c1',
            },
        },
    ];

    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            conditionalCellStyles,
            wrap: true,
            grow: 2,
            fixed: true,
        },
        {
            name: 'Category Name',
            selector: (row) => row.category_name,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Status',
            sortable: true,
            conditionalCellStyles,
            cell: (row) =>
                row.status === 'distributed' ? (
                    <Badge className="badgeActive" bg="success">
                        Distributed
                    </Badge>
                ) : row.status === 'forwarded' ? (
                    <Badge className="badgeActive" bg="success">
                        Forwarded
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ),
        },
        {
            name: 'Facility Request Qty',
            selector: (row) => row.facility_approved_qty,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Availability in Partner Stock',
            selector: (row) => (row.partner_available_qty ? row.partner_available_qty : 0),
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Input Qty',
            selector: (row) => row.approved_supply_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        requisitionRowHandler(e.target.value, row);
                    }}
                    defaultValue={receiveData?.[row.drug_id]?.approved_supply_qty}
                />
            ),
            reorder: true,
            width: '150px',
            conditionalCellStyles,
        },
    ];

    const search_column = {
        // drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
