/*
 * Author M. Atoar Rahman
 * Title: Dispensary Stock Return
 * Description: Page for Dispensary Stock Return
 * Date: 19/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { StockReturnApprovalProvider } from '../partner/stockReturnApproval/ContextAPI';
import Index from '../partner/stockReturnApproval/Index';
import PermissionDenied from './PermissionDenied';

export default function PartnerStockReturnApproval() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['partner-stock-return-approval']) return <PermissionDenied />;

    return (
        <StockReturnApprovalProvider>
            <Index />
        </StockReturnApprovalProvider>
    );
}
