/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Current Stock Context
 * Description: Dispensary Current Stock Context
 * Date: 15/03/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import UrlService from '../../../services/UrlService';

const ContextAPI = createContext({});

export function useCurrentStock() {
    return useContext(ContextAPI);
}

export function CurrentStockProvider({ children }) {
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState();
    const [showPdf, setShowPdf] = useState(false);

    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getDispensaryCurrentStockUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setData(response?.data?.current_stock_info);
            // console.log(response?.data.data);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContextAPI.Provider value={{ data, setData, getAllData, showPdf, setShowPdf }}>
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
