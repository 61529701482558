/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: D4 Report
 * Description: D4 Report
 * Date: 15/07/2022
 */

import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useD4Report } from './ContextAPI';

export default function FormInfo() {
    const { setShow, setData } = useD4Report();
    const axiosPrivate = useAxiosPrivate();

    const [checkedOne, setCheckedOne] = useState(true);
    const [checkedTwo, setCheckedTwo] = useState(false);

    const {
        formState: { errors },
        handleSubmit,
        control,
        reset,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    const handleCheckedOne = () => {
        reset();
        setCheckedOne(!checkedOne);
        setCheckedTwo(!checkedTwo);
    };
    const handleCheckedTwo = () => {
        reset();
        setCheckedOne(!checkedOne);
        setCheckedTwo(!checkedTwo);
    };

    const formSubmitHandler = async (data) => {
        const formData = {
            ...data,
            month: data?.month?.getMonth(),
            year: data?.year?.getFullYear(),
            start_date: data?.start_date?.toLocaleDateString('sv-SE'),
            end_date: data?.end_date?.toLocaleDateString('sv-SE'),
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.d4ReportsUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!isEmpty(response.data.d4_records)) {
                setData(response.data);
                setShow(true);
            } else {
                ToastifyService.errorNotify('Data not found!');
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h6>D4 Reports: Global Dispatch Reports</h6>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Form>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={10}>
                                            <Form.Label>Monthly Report</Form.Label>
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Check
                                                name="status"
                                                id="user-status-switch"
                                                type="switch"
                                                checked={checkedOne}
                                                onClick={handleCheckedOne}
                                                onChange={handleCheckedOne}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={10}>
                                            <Form.Label>Date Wise Report</Form.Label>
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Check
                                                name="status"
                                                id="user-status-switch"
                                                type="switch"
                                                checked={checkedTwo}
                                                onClick={handleCheckedTwo}
                                                onChange={handleCheckedTwo}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <hr />
                        </Row>
                        <Row>
                            {checkedOne === true && (
                                <>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Month</Form.Label>
                                            <Controller
                                                control={control}
                                                name="month"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        placeholderText="Select Month"
                                                        dateFormat="MMMM"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        showMonthYearPicker
                                                        showFullMonthYearPicker
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Month is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.month && (
                                                <span className="invalid">
                                                    {errors.month?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Year</Form.Label>
                                            <Controller
                                                control={control}
                                                name="year"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        placeholderText="Select Year"
                                                        dateFormat="yyyy"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        showYearPicker
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Year is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.year && (
                                                <span className="invalid">
                                                    {errors.year?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </>
                            )}
                            {checkedTwo === true && (
                                <>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Date From</Form.Label>
                                            <Controller
                                                control={control}
                                                name="start_date"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        placeholderText="Select date"
                                                        dateFormat="dd-MM-yyyy"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        // minDate={new Date()}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Date is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.start_date && (
                                                <span className="invalid">
                                                    {errors.start_date?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Date To</Form.Label>
                                            <Controller
                                                control={control}
                                                name="end_date"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        placeholderText="Select date"
                                                        dateFormat="dd-MM-yyyy"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        // minDate={new Date()}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Date is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.end_date && (
                                                <span className="invalid">
                                                    {errors.end_date?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </>
                            )}

                            <Col lg={2} className="mt-3">
                                <Button
                                    className="btnSuccess mt-1"
                                    onClick={handleSubmit(formSubmitHandler)}
                                    type="button"
                                    variant="success"
                                >
                                    Search <i className="fa fa-search" />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Card.Body>
        </Card>
    );
}
