/*
 * Author M. Atoar Rahman
 * Title: Dispensary Item Dispatch
 * Description: Page for Dispensary Item Dispatch
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { ItemDispatchProvider } from '../dispensary/itemDispatch/ContextAPI';
import Index from '../dispensary/itemDispatch/Index';
import PermissionDenied from './PermissionDenied';

export default function DispensaryItemDispatch() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['item-dispatch']) return <PermissionDenied />;

    return (
        <ItemDispatchProvider>
            <Index />
        </ItemDispatchProvider>
    );
}
