/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Donation DataTable
 * Description: Master Setup of Donation DataTable
 * Date: 15/03/2022
 */

import { useCallback, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { layout } from '../../SvgIcon';
import { useDonation } from './ContextAPI';

export default function DataTable() {
    const axiosPrivate = useAxiosPrivate();
    const { data, getAllData, setRowData, setShowPdf } = useDonation();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const showHander = async (row) => {
        try {
            const response = await axiosPrivate.get(UrlService.getDonationCertificateUrl(row.id), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setRowData(response?.data?.certificate_info);
            setShowPdf(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const columns = [
        {
            name: 'NOTRE/Our Code',
            selector: (row) => row.our_code,
            sortable: true,
        },
        {
            name: 'Agency Name',
            selector: (row) => row.agency_name,
            sortable: true,
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    onClick={() => showHander(row)}
                    type="button"
                    className="btn btn-light actionBtn"
                >
                    {layout} Certificate
                </Button>
            ),
        },
    ];

    const search_column = {
        drug_name: 'Item Name',
        agency_name: 'Agency Name',
        quantity: 'Quantity',
    };
    return useDataTable({ columns, data, search_column });
}
