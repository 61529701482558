/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Facility Stock Distribution Approval Table
 * Description: Facility Stock Distribution Approval Table
 * Date: 27/06/2022
 */
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { layout } from '../../SvgIcon';
import { useApproval } from './ContextAPI';

export default function ApprovedTable() {
    const { setShow, setRowData, approvedData, getApprovedData, setShowPdf, setPdfData } =
        useApproval();
    const axiosPrivate = useAxiosPrivate();

    const handleShow = async (row) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getStockOutDetailsByIdUrl(row.id, row.status),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const row_info = {
                stock_out_master_id: row.id,
                dispensary_name: row.dispensary_name,
                stockout_date: row.stockout_date,
                status: row.status,
                records: response?.data?.medicine_list,
            };
            setRowData(row_info);
            setShow(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const dataList = useCallback(() => {
        getApprovedData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const handlePrint = async (row) => {
        const form = 'facility';
        try {
            const response = await axiosPrivate.get(
                UrlService.waybillFacilityDistributionUrl(form, row.id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setShowPdf(true);
            setPdfData(response?.data?.waybill_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const columns = [
        {
            name: 'Department',
            selector: (row) => row.dispensary_name,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Distribution Type',
            selector: (row) => row.distribution_type,
            sortable: true,
            cell: (row) =>
                row.distribution_type === 'internal_request' ? (
                    <Badge className="badgeActive" bg="success">
                        Internal Request
                    </Badge>
                ) : (
                    <Badge className="badgeActive" bg="success">
                        Regular
                    </Badge>
                ),
        },
        {
            name: 'Stockout Date',
            selector: (row) => row.stockout_date,
            format: (row) => moment(row.stockout_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: () => (
                <Badge className="badgeActive" bg="success">
                    Approved
                </Badge>
            ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    <Button
                        className="btn btn-light actionBtn"
                        variant="primary"
                        onClick={() => handlePrint(row)}
                    >
                        <i className="fa fa-file-pdf" /> Waybill
                    </Button>
                    <Button
                        onClick={() => handleShow(row)}
                        className="btn btn-light actionBtn"
                        type="button"
                        variant="success"
                    >
                        {layout}
                    </Button>
                </>
            ),
        },
    ];

    const search_column = {
        facility_name: 'Facility',
        submited_date: 'Submited Date',
    };
    return useDataTable({ columns, data: approvedData, search_column, pagination: false });
}
