/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Employee Info
 * Description: Master Setup of Employee Info Form: Create and Update
 * Date: 15/03/2022
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import blankImg from '../../../assets/blank.png';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import * as pattern from '../../../services/Helpers';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useEmployee } from './ContextAPI';

const PUBLIC_URL = UrlService.publicUrl();

export default function FormInfo({ editData = null }) {
    const { auth } = useAuth();
    const partnerId = auth?.employee_info?.partner_id ? auth?.employee_info?.partner_id : null;
    const partnerName = auth?.employee_info?.partner_name
        ? auth?.employee_info?.partner_name
        : null;

    const {
        register,
        formState: { errors },
        reset,
        resetField,
        handleSubmit,
        control,
        setValue,
        watch,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                code_no: editData && editData?.code_no,
                first_name: editData && editData?.first_name,
                last_name: editData && editData?.last_name,
                father_name: editData && editData?.father_name,
                mother_name: editData && editData?.mother_name,
                nid_no: editData && editData?.nid_no,
                date_of_birth: editData ? new Date(editData.date_of_birth) : new Date(),
                mobile: editData && editData?.mobile,
                email: editData && editData?.email,
                address: editData && editData?.address,
                designation: editData && editData?.designation,
                // department: editData && editData?.department,
                joining_date: editData ? new Date(editData.joining_date) : new Date(),
                organization: partnerId
                    ? {
                          label: 'Partner',
                          value: '3',
                      }
                    : editData && {
                          label:
                              editData.organization == '1'
                                  ? 'UNHCR'
                                  : editData.organization == '2'
                                  ? 'Center'
                                  : editData.organization == '3'
                                  ? 'Partner'
                                  : editData.organization == '4'
                                  ? 'Facility'
                                  : 'Department',
                          value: editData?.organization,
                      },
                partner_id: partnerId
                    ? {
                          label: partnerName,
                          value: partnerId,
                      }
                    : editData && {
                          label: editData?.partner?.name,
                          value: editData?.partner_id,
                      },
                facility_id: editData && {
                    label: editData?.facility?.name,
                    value: editData?.facility_id,
                },
                dispensary_id: editData && {
                    label: editData?.dispensary?.name,
                    value: editData?.dispensary_id,
                },
                photo: editData && editData?.photo,
                status: editData ? (editData?.status == '1' ? 1 : 0) : 1,
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            code_no: editData && editData?.code_no,
            first_name: editData && editData?.first_name,
            last_name: editData && editData?.last_name,
            father_name: editData && editData?.father_name,
            mother_name: editData && editData?.mother_name,
            nid_no: editData && editData?.nid_no,
            date_of_birth: editData ? new Date(editData.date_of_birth) : new Date(),
            mobile: editData && editData?.mobile,
            email: editData && editData?.email,
            address: editData && editData?.address,
            designation: editData && editData?.designation,
            // department: editData && editData?.department,
            joining_date: editData ? new Date(editData.joining_date) : new Date(),
            organization: partnerId
                ? {
                      label: 'Partner',
                      value: '3',
                  }
                : editData && {
                      label:
                          editData.organization == '1'
                              ? 'UNHCR'
                              : editData.organization == '2'
                              ? 'Center'
                              : editData.organization == '3'
                              ? 'Partner'
                              : editData.organization == '4'
                              ? 'Facility'
                              : 'Department',
                      value: editData?.organization,
                  },
            partner_id: partnerId
                ? {
                      label: partnerName,
                      value: partnerId,
                  }
                : editData && {
                      label: editData?.partner?.name,
                      value: editData?.partner_id,
                  },
            facility_id: editData && {
                label: editData?.facility?.name,
                value: editData?.facility_id,
            },
            dispensary_id: editData && {
                label: editData?.dispensary?.name,
                value: editData?.dispensary_id,
            },
            photo: editData && editData?.photo,
            status: editData ? (editData?.status == '1' ? 1 : 0) : 1,
        });
    }, [editData]);

    // Use Hook
    const uploadImgRef = useRef();
    const axiosPrivate = useAxiosPrivate();

    // Employee Context
    const { setShow, setRowData } = useEmployee();
    const {
        partner,
        getPartner,
        facilityByPartner,
        getFacilityByPartner,
        dispensaryByFacility,
        getDispensaryByFacility,
    } = CommonApiService();

    // Local State
    const [partnerData, setPartnerData] = useState();
    const [facilityData, setFacilityData] = useState();
    const [dispensaryData, setDispensaryData] = useState();
    const [organizationInfo, setOrganizationInfo] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Watch data
    const organizationWatch = watch('organization');

    useEffect(() => {
        setOrganizationInfo(organizationWatch.value);
    }, [organizationWatch]);

    useEffect(() => {
        if (editData) {
            setOrganizationInfo(editData.organization);
        }
    }, [editData]);

    const [errMsg, setErrMsg] = useState('');

    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => console.log(value, name, type));
        return () => subscription.unsubscribe();
    }, [watch]);

    let SAVE_URL = '';
    if (editData) {
        SAVE_URL = UrlService.updateEmployeeUrl(editData.id);
    } else {
        SAVE_URL = UrlService.saveEmployeeUrl();
    }

    const dataList = useCallback(() => {
        getPartner(); // Partner
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Dispensary
        setDispensaryData(
            dispensaryByFacility?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner, facilityByPartner, dispensaryByFacility]);

    // Form Close
    const handleClose = () => {
        setShow(false);
        setRowData('');
    };

    // Organization List
    const orgList = [
        { value: '1', label: 'UNHCR' },
        { value: '2', label: 'Center' },
        { value: '3', label: 'Partner' },
        { value: '4', label: 'Facility' },
        { value: '5', label: 'Department' },
    ];

    // Upload Image Custom Button
    const [photo, setPhoto] = useState();
    function importData() {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = () => {
            Array.from(input.files);
            uploadImgRef.current.src = URL.createObjectURL(input.files[0]);
            setPhoto(input.files[0]);
        };
        input.click();
    }
    // Set default values
    useEffect(() => {
        setValue('photo', photo);
    }, [photo]);
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);
        // alert(data.partner_id.value);
        const formData = new FormData();
        formData.append('code_no', data.code_no);
        formData.append('first_name', data.first_name);
        formData.append('last_name', data.last_name);
        formData.append('father_name', data.father_name);
        formData.append('mother_name', data.mother_name);
        formData.append('nid_no', data.nid_no);
        formData.append('date_of_birth', data.date_of_birth.toLocaleDateString('sv-SE'));
        formData.append('mobile', data.mobile);
        formData.append('email', data.email);
        formData.append('address', data.address);
        formData.append('organization', data.organization.value);
        formData.append('designation', data.designation);
        // formData.append('department', data.department);
        formData.append('joining_date', data.joining_date.toLocaleDateString('sv-SE'));
        formData.append('partner_id', data.partner_id.value);
        formData.append('facility_id', data.facility_id.value);
        formData.append('dispensary_id', data.dispensary_id.value);
        formData.append('photo', data.photo);
        formData.append('status', data.status == (true || 1) ? 1 : 0);

        try {
            await axiosPrivate.post(SAVE_URL, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (editData) {
                ToastifyService.updateNotify('Data Update Successfull');
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
            }
            setShow(false);
            reset();
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else {
                setErrMsg('Data Insert/Update Failed!');
            }
            ToastifyService.errorNotify(errMsg);
        }
    };

    return (
        <Form onSubmit={handleSubmit(formSubmitHandler)} encType="multipart/form-data">
            <fieldset className="customFieldset">
                <legend className="customLegend">Basic Info:</legend>
                <Row>
                    <Col lg={9}>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>
                                                EMP Code<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="code_no"
                                                id="code_no"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('code_no', {
                                                    required: 'Please enter your code no.',
                                                    pattern: {
                                                        value: /^[a-z]*?\-?\d+$/i,
                                                        message:
                                                            'Only allow example: AB-1234 or 1234 format',
                                                    },
                                                })}
                                                className={errors?.code_no && 'invalidInput'}
                                            />
                                            {errors.code_no && (
                                                <span className="invalid">
                                                    {errors.code_no?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>
                                                First Name<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="first_name"
                                                id="first_name"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('first_name', {
                                                    required: 'Please enter your frist name.',
                                                })}
                                                className={errors?.first_name && 'invalidInput'}
                                            />
                                            {errors.first_name && (
                                                <span className="invalid">
                                                    {errors.first_name?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>
                                                Last Name<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="last_name"
                                                id="last_name"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('last_name', {
                                                    required: 'Please enter your last name.',
                                                })}
                                                className={errors?.last_name && 'invalidInput'}
                                            />
                                            {errors.last_name && (
                                                <span className="invalid">
                                                    {errors.last_name?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Father Name</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="father_name"
                                                id="father_name"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('father_name', {
                                                    // required: 'Please enter your father name.',
                                                })}
                                                className={errors?.father_name && 'invalidInput'}
                                            />
                                            {errors.father_name && (
                                                <span className="invalid">
                                                    {errors.father_name?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Mother Name</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="mother_name"
                                                id="mother_name"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('mother_name', {
                                                    // required: 'Please enter your mother name.',
                                                })}
                                                className={errors?.mother_name && 'invalidInput'}
                                            />
                                            {errors.mother_name && (
                                                <span className="invalid">
                                                    {errors.mother_name?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>NID no</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="nid_no"
                                                id="nid_no"
                                                type="number"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('nid_no', {
                                                    // required: 'Please enter your NID No.',
                                                    pattern: pattern.nidPattern,
                                                })}
                                                className={errors?.nid_no && 'invalidInput'}
                                            />
                                            {errors.nid_no && (
                                                <span className="invalid">
                                                    {errors.nid_no?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Date of Birth</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Controller
                                                control={control}
                                                name="date_of_birth"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        placeholderText="Select date"
                                                        dateFormat="dd-MM-yyyy"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        maxDate={new Date()}
                                                    />
                                                )}
                                                rules={{
                                                    // required: 'Date is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.date_of_birth && (
                                                <span className="invalid">
                                                    {errors.date_of_birth?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>
                                                Mobile<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="mobile"
                                                id="mobile"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('mobile', {
                                                    required: 'Please enter your mobile.',
                                                    pattern: pattern.mobilePattern,
                                                })}
                                                className={errors?.mobile && 'invalidInput'}
                                            />
                                            {errors.mobile && (
                                                <span className="invalid">
                                                    {errors.mobile?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>
                                                Email<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="email"
                                                id="email"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('email', {
                                                    required: 'Please enter your email.',
                                                    pattern: pattern.emailPattern,
                                                })}
                                                className={errors?.email && 'invalidInput'}
                                            />
                                            {errors.email && (
                                                <span className="invalid">
                                                    {errors.email?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={2}>
                                            <Form.Label>Address</Form.Label>
                                        </Col>
                                        <Col lg={10}>
                                            <Form.Control
                                                name="address"
                                                id="address"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('address', {
                                                    // required: 'Please enter your address.',
                                                })}
                                                className={errors?.address && 'invalidInput'}
                                            />
                                            {errors.address && (
                                                <span className="invalid">
                                                    {errors.address?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={3}>
                        <img
                            ref={uploadImgRef}
                            style={{ width: '100%' }}
                            src={
                                editData.photo ? `${PUBLIC_URL}/upload/${editData.photo}` : blankImg
                            }
                            alt=""
                        />
                        <button
                            type="button"
                            className="btn btnCancel w-100 mt-2"
                            onClick={importData}
                        >
                            Upload Profile Picture
                        </button>
                    </Col>
                </Row>
            </fieldset>
            <fieldset className="customFieldset mt-4">
                <legend className="customLegend">ORG Info:</legend>
                <Row>
                    <Form.Group className="mb-3">
                        <Row>
                            <Col lg={2}>
                                <Form.Label>
                                    Organization Type<span className="invalid">*</span>
                                </Form.Label>
                            </Col>
                            <Col lg={4}>
                                <Controller
                                    name="organization"
                                    id="organization"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors?.organization && 'invalidInput'}
                                            options={orgList}
                                            isDisabled={partnerId}
                                        />
                                    )}
                                    rules={{
                                        required: 'Please select your organization.',
                                    }}
                                />
                                {errors.organization && (
                                    <span className="invalid">{errors.organization?.message}</span>
                                )}
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>
                {(organizationInfo == 1 || organizationInfo == 2) && (
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>
                                            Designation<span className="invalid">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Control
                                            name="designation"
                                            id="designation"
                                            type="text"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register('designation', {
                                                required: 'Please enter your designation.',
                                            })}
                                            className={errors?.designation && 'invalidInput'}
                                        />
                                        {errors.designation && (
                                            <span className="invalid">
                                                {errors.designation?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        {/* <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>
                                            Department<span className="invalid">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Control
                                            name="department"
                                            id="department"
                                            type="text"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register('department', {
                                                required: 'Please enter your department.',
                                            })}
                                            className={errors?.department && 'invalidInput'}
                                        />
                                        {errors.department && (
                                            <span className="invalid">
                                                {errors.department?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col> */}
                        <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>
                                            Joining Date<span className="invalid">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Controller
                                            control={control}
                                            name="joining_date"
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    closeOnScroll
                                                    placeholderText="Select date"
                                                    dateFormat="dd-MM-yyyy"
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                    className="form-control"
                                                    popperPlacement="top-end"
                                                    // minDate={new Date()}
                                                />
                                            )}
                                            rules={{
                                                required: 'Please enter your joining date.',
                                                valueAsDate: true,
                                            }}
                                        />
                                        {errors.joining_date && (
                                            <span className="invalid">
                                                {errors.joining_date?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>
                )}
                {(organizationInfo == 3 || organizationInfo == 4 || organizationInfo == 5) && (
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>
                                            Partner Agency<span className="invalid">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Controller
                                            name="partner_id"
                                            id="partner_id"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    classNamePrefix={
                                                        errors?.partner_id && 'invalidInput'
                                                    }
                                                    options={partnerData}
                                                    onBlur={async () => {
                                                        await resetField('facility_id');
                                                        await resetField('dispensary_id');
                                                        await getFacilityByPartner(
                                                            field?.value?.value
                                                        );
                                                    }}
                                                    isDisabled={partnerId}
                                                />
                                            )}
                                            rules={{ required: 'Please select your partner.' }}
                                        />
                                        {errors?.partner_id && (
                                            <span className="invalid">
                                                {errors?.partner_id?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col lg={6} className={organizationInfo == 3 && 'd-none'}>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>Facility Name</Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Controller
                                            name="facility_id"
                                            id="facility_id"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    classNamePrefix={
                                                        errors?.facility_id && 'invalidInput'
                                                    }
                                                    options={facilityData}
                                                    onBlur={async () => {
                                                        await resetField('dispensary_id');
                                                        await getDispensaryByFacility(
                                                            field?.value?.value
                                                        );
                                                    }}
                                                />
                                            )}
                                            // rules={{ required: 'Please select your facility.' }}
                                        />
                                        {errors?.facility_id && (
                                            <span className="invalid">
                                                {errors?.facility_id?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col
                            lg={6}
                            className={(organizationInfo == 3 || organizationInfo == 4) && 'd-none'}
                        >
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>Department Name</Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Controller
                                            name="dispensary_id"
                                            id="dispensary_id"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    classNamePrefix={
                                                        errors?.dispensary_id && 'invalidInput'
                                                    }
                                                    options={dispensaryData}
                                                />
                                            )}
                                            // rules={{ required: 'Please select your dispensary.' }}
                                        />
                                        {errors?.dispensary_id && (
                                            <span className="invalid">
                                                {errors?.dispensary_id?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>
                                            Designation<span className="invalid">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Control
                                            name="designation"
                                            id="designation"
                                            type="text"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register('designation', {
                                                required: 'Please enter your designation.',
                                            })}
                                            className={errors?.designation && 'invalidInput'}
                                        />
                                        {errors.designation && (
                                            <span className="invalid">
                                                {errors.designation?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        {/* <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>
                                            Department<span className="invalid">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Control
                                            name="department"
                                            id="department"
                                            type="text"
                                            placeholder=""
                                            autoComplete="off"
                                            {...register('department', {
                                                required: 'Please enter your department.',
                                            })}
                                            className={errors?.department && 'invalidInput'}
                                        />
                                        {errors.department && (
                                            <span className="invalid">
                                                {errors.department?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col> */}
                        <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>
                                            Joining Date<span className="invalid">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Controller
                                            control={control}
                                            name="joining_date"
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    closeOnScroll
                                                    placeholderText="Select date"
                                                    dateFormat="dd-MM-yyyy"
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                    className="form-control"
                                                    popperPlacement="top-end"
                                                    // minDate={new Date()}
                                                />
                                            )}
                                            rules={{
                                                required: 'Please enter your joining date.',
                                                valueAsDate: true,
                                            }}
                                        />
                                        {errors.joining_date && (
                                            <span className="invalid">
                                                {errors.joining_date?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>
                )}
            </fieldset>

            <Form.Group className="mt-3">
                <Row>
                    <Col lg={2}>
                        <Form.Label>Status</Form.Label>
                    </Col>
                    <Col lg={10}>
                        <Form.Check
                            name="status"
                            id="user-status-switch"
                            type="switch"
                            {...register('status')}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <div className="formFooter">
                <Button className="btnCancel me-2" variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    className="btnSuccess"
                    type="submit"
                    variant="success"
                    disabled={isSubmitted}
                >
                    {editData ? 'Update' : 'Create New'}
                </Button>
            </div>
        </Form>
    );
}
