/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: D2 Final Approval Context
 * Description: D2 Final Approval Context
 * Date: 20/06/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Create Context
const ContextAPI = createContext();

export function useD2Approval() {
    return useContext(ContextAPI);
}

// Provider Component
export function D2ApprovalProvider({ children }) {
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState({});
    const [d2PendingData, setD2PendingData] = useState();
    const [d2ApprovedData, setD2ApprovedData] = useState();
    const [receiveData, setReceiveData] = useState({});
    const [receiveList, setReceiveList] = useState({});

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data  All D2 Data
    const getD2PendingData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllD2Url('approved', 1), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setD2PendingData(response?.data?.d2_preparation_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const getD2ApprovedData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllD2Url('final_approved', 1), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setD2ApprovedData(response?.data?.d2_preparation_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // API Function: Add Approval
    const addD2FinalApproval = async (data) => {
        try {
            await axiosPrivate.post(UrlService.D2FinalApprovedUrl(), JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setShow(false);
            getD2PendingData();
            getD2ApprovedData();
            ToastifyService.updateNotify('Approve Successfull');
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                rowData,
                setRowData,
                d2PendingData,
                getD2PendingData,
                d2ApprovedData,
                getD2ApprovedData,
                addD2FinalApproval,
                receiveData,
                setReceiveData,
                receiveList,
                setReceiveList,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
