/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Facility Internal Request
 * Description: Facility Internal Request Form: Create and Update
 * Date: 26/04/2022
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import CommonApiService from '../../../services/CommonApiService';
import { useInternalRequest } from './ContextAPI';

export default function FormInfo({ editData = null, drugData }) {
    const { setShow, addInternalRequest, editInternalRequest } = useInternalRequest();
    const { auth } = useAuth();
    const partnerId = auth?.employee_info?.partner_id ? auth?.employee_info?.partner_id : null;
    const partnerName = auth?.employee_info?.partner_name
        ? auth?.employee_info?.partner_name
        : null;
    const facilityId = auth?.employee_info?.facility_id ? auth?.employee_info?.facility_id : null;
    const facilityName = auth?.employee_info?.facility_name
        ? auth?.employee_info?.facility_name
        : null;
    // Local State
    const [partnerData, setPartnerData] = useState([]);
    const [facilityData, setFacilityData] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    // const [itemDetailsRowData, setItemDetailsRowData] = useState({});

    const { partner, getPartner, facilityByPartner, getFacilityByPartner } = CommonApiService();

    const {
        register,
        formState: { errors },
        reset,
        resetField,
        handleSubmit,
        control,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                partner_id: partnerId
                    ? { label: partnerName, value: partnerId }
                    : editData && {
                          label: editData?.partner_name,
                          value: editData?.partner_id,
                      },
                facility_id: facilityId
                    ? { label: facilityName, value: facilityId }
                    : editData && {
                          label: editData?.facility_name,
                          value: editData?.facility_id,
                      },
                priority: editData && {
                    label: editData?.priority,
                    value: editData?.priority,
                },
                expected_receive_date: editData && new Date(editData.expected_receive_date),
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            partner_id: partnerId
                ? { label: partnerName, value: partnerId }
                : editData && {
                      label: editData?.partner_name,
                      value: editData?.partner_id,
                  },
            facility_id: facilityId
                ? { label: facilityName, value: facilityId }
                : editData && {
                      label: editData?.facility_name,
                      value: editData?.facility_id,
                  },
            priority: editData && {
                label: editData?.priority,
                value: editData?.priority,
            },
            expected_receive_date: editData && new Date(editData.expected_receive_date),
        });
    }, [editData]);

    // Transports
    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        reset: reset2,
        control: control2,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        setValue('itemDetails', itemDetails);
    }, [itemDetails]);

    const dataList = useCallback(() => {
        getPartner(); // Partner
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner, facilityByPartner]);

    // Item Details: Add
    const itemDetailsAppendHandler = (data) => {
        const detailFormData = {
            ...data,
            item_id: data.item_id.value,
            item: { id: data.item_id.value, name: data.item_id.label },
        };
        setItemDetails([...itemDetails, detailFormData]);
        reset2({
            item_id: '',
            req_qty: '',
            remark: '',
        });
    };
    // Item Details: Remove
    const itemDetailsRemoveHandler = (item_id) => {
        const updatedItemDetails = itemDetails && itemDetails.filter((m) => m.item_id !== item_id);
        setItemDetails(updatedItemDetails);
    };

    // Item Details Edit: Row Data
    useEffect(() => {
        if (editData) {
            setItemDetails(...itemDetails, editData.item_details);
        }
    }, [editData]);

    register('itemDetails', { required: 'Item details is required!' });

    // Form Submit Handelar
    const formSubmitHandler = (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            partner_id: data?.partner_id?.value,
            facility_id: data?.facility_id?.value,
            priority: data?.priority?.value,
            expected_receive_date: data?.expected_receive_date?.toLocaleDateString('sv-SE'),
        };
        if (editData) {
            editInternalRequest(formData, editData.id); // Update Data
        } else {
            addInternalRequest(formData); // Create Data
        }
        setShow(false);
        reset();
    };

    const priorities = [
        { label: 'Very Urgent', value: 'Very Urgent' },
        { label: 'Urgent', value: 'Urgent' },
        { label: 'General', value: 'General' },
    ];

    return (
        <Form>
            <fieldset style={{ background: '#f5f7ff' }} className="customFieldset p-3 pb-0 mb-3">
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Partner <span className="text-danger">*</span>
                            </Form.Label>
                            <Controller
                                name="partner_id"
                                id="partner_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.partner_id && 'invalidInput'}
                                        options={partnerData}
                                        isDisabled={partnerId}
                                        onBlur={async () => {
                                            await resetField('facility_id');
                                            await getFacilityByPartner(field.value.value);
                                        }}
                                    />
                                )}
                                rules={{ required: 'Please select your partner.' }}
                            />
                            {errors?.partner_id && (
                                <span className="invalid">{errors?.partner_id?.message}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Expected Receive Date <span className="text-danger">*</span>
                            </Form.Label>
                            <Controller
                                control={control}
                                name="expected_receive_date"
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        closeOnScroll
                                        placeholderText="Select date"
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        className="form-control"
                                        popperPlacement="top-end"
                                        // minDate={new Date()}
                                    />
                                )}
                                rules={{
                                    required: 'Please select your date.',
                                    valueAsDate: true,
                                }}
                            />
                            {errors.expected_receive_date && (
                                <span className="invalid">
                                    {errors.expected_receive_date?.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Facility <span className="text-danger">*</span>
                            </Form.Label>
                            <Controller
                                name="facility_id"
                                id="facility_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.facility_id && 'invalidInput'}
                                        options={facilityData}
                                        isDisabled={facilityId}
                                    />
                                )}
                                rules={{ required: 'Please select your facility.' }}
                            />
                            {errors?.facility_id && (
                                <span className="invalid">{errors?.facility_id?.message}</span>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>
                                Priority <span className="text-danger">*</span>
                            </Form.Label>
                            <Controller
                                name="priority"
                                id="priority"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.priority && 'invalidInput'}
                                        options={priorities}
                                    />
                                )}
                                rules={{
                                    required: 'Please select priority.',
                                }}
                            />
                            {errors.priority && (
                                <span className="invalid">{errors.priority?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>
            <Row>
                <Col lg={6} className="pe-0">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Item Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller
                            name="item_id"
                            id="item_id"
                            control={control2}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    classNamePrefix={errors2?.item_id && 'invalidInput'}
                                    options={drugData}
                                />
                            )}
                            rules={{
                                required: 'Please select drug item.',
                            }}
                        />
                        {errors2.item_id && (
                            <span className="invalid">{errors2.item_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>
                <Col lg={2} className="pe-0">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Request Qty <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            name="req_qty"
                            id="req_qty"
                            type="number"
                            placeholder=""
                            autoComplete="off"
                            {...register2('req_qty', {
                                required: 'Field is required.',
                            })}
                            className={errors2?.req_qty && 'invalidInput'}
                        />
                        {errors2.req_qty && (
                            <span className="invalid">{errors2.req_qty?.message}</span>
                        )}
                    </Form.Group>
                </Col>
                <Col lg={3} className="pe-0">
                    <Form.Group className="mb-3">
                        <Form.Label>Remark</Form.Label>
                        <Form.Control
                            name="remark"
                            id="remark"
                            type="text"
                            placeholder=""
                            autoComplete="off"
                            {...register2('remark', {
                                // required: 'Please enter your remark.',
                            })}
                            className={errors2?.remark && 'invalidInput'}
                        />
                        {errors2.remark && (
                            <span className="invalid">{errors2.remark?.message}</span>
                        )}
                    </Form.Group>
                </Col>
                <Col lg={1} className="mt-1">
                    <Button
                        className="btnSteelBlue mt-3"
                        variant="primary"
                        onClick={handleSubmit2(itemDetailsAppendHandler)}
                    >
                        Add
                    </Button>
                </Col>
            </Row>
            <Row>
                {errors.itemDetails && (
                    <span className="invalid">{errors.itemDetails?.message}</span>
                )}
                <Col lg={12}>
                    <Table className="borderDashed" responsive="sm">
                        <tbody>
                            {itemDetails.map((item) => (
                                <tr key={Math.random()}>
                                    <td>{item.item?.name}</td>
                                    <td>{item.req_qty}</td>
                                    <td>{item.remark}</td>
                                    <td className="text-end">
                                        {/* <Button
                                            className="btn btn-light actionBtn"
                                            variant="primary"
                                            onClick={() => itemDetailsModalHandler(item, i)}
                                        >
                                            <i className="fas fa-edit" />
                                        </Button> */}
                                        <Button
                                            className="btn btn-light actionBtn"
                                            variant="primary"
                                            onClick={() => itemDetailsRemoveHandler(item.item_id)}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Form.Group className="mb-3">
                    <Row className="text-start">
                        <Col lg={12}>
                            <Button
                                onClick={handleSubmit(formSubmitHandler)}
                                className="btnSuccess"
                                type="button"
                                variant="success"
                                disabled={isSubmitted}
                            >
                                {editData ? 'Update' : 'Submit'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
        </Form>
    );
}
