/* eslint-disable no-useless-escape */
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

export default function FormInfo() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const {
        register,
        formState: { errors },
        reset,
        getValues,
        handleSubmit,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    const handleClose = () => {
        navigate('/');
    };
    const formSubmitHandler = async (data) => {
        const formData = {
            ...data,
        };

        try {
            const response = await axiosPrivate.post(UrlService.changePasswordUrl(), formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response?.data?.status === 'success') {
                navigate('/');
                reset();
                ToastifyService.saveNotify('Password Changed Successfully');
            }

            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            }

            if (response?.data?.status === 'v_error') {
                if (response?.data?.message?.current_password) {
                    ToastifyService.dbErrorNotify(response?.data?.message?.current_password[0]);
                }
                if (response?.data?.message?.new_password) {
                    ToastifyService.dbErrorNotify(response?.data?.message?.new_password[0]);
                }
                if (response?.data?.message?.new_password_confirmation) {
                    ToastifyService.dbErrorNotify(
                        response?.data?.message?.new_password_confirmation[0]
                    );
                }
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Inserted Failed!');
            }
        }
    };
    return (
        <div
            className="d-grid justify-content-center align-items-center"
            style={{ height: 'calc(100vh - (5rem + 15px))' }}
        >
            <Form
                className="p-4"
                onSubmit={handleSubmit(formSubmitHandler)}
                encType="multipart/form-data"
                style={{ background: '#fff' }}
            >
                <Row>
                    <Col className="text-center mt-2">
                        <h4>Change Your Password</h4>
                    </Col>
                </Row>
                <hr />
                <Row className="mt-4 d-flex flex-column">
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Current Password<span className="invalid">*</span>
                            </Form.Label>
                            <Form.Control
                                name="current_password"
                                id="current_password"
                                type="password"
                                placeholder=""
                                autoComplete="off"
                                {...register('current_password', {
                                    required: 'Please enter your current password.',
                                })}
                                className={errors?.current_password && 'invalidInput'}
                            />
                            {errors.current_password && (
                                <span className="invalid">{errors.current_password?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                New Password<span className="invalid">*</span>
                            </Form.Label>
                            <Form.Control
                                name="new_password"
                                id="new_password"
                                type="password"
                                placeholder=""
                                autoComplete="off"
                                {...register('new_password', {
                                    required: 'Please enter your new password.',
                                    validate: {
                                        isNotMin8: (value) => /(?=.{8,})/.test(value),
                                        isNotLower: (value) => /^(?=.*[a-z]).*$/.test(value),
                                        isNotUpper: (value) => /^(?=.*[A-Z]).*$/.test(value),
                                        isNotDigit: (value) => /(?=.*\d)/.test(value),
                                        isNotSpecial: (value) =>
                                            /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/.test(
                                                value
                                            ),
                                        isNotMax20: (value) => /^.{8,20}$/.test(value),
                                        isNotWhiteSpace: (value) => /^\S*$/.test(value),
                                    },
                                })}
                                className={errors?.new_password && 'invalidInput'}
                            />

                            {errors.new_password && errors.new_password.type === 'isNotMin8' && (
                                <span className="invalid">Password must minimum 8 characters!</span>
                            )}
                            {errors.new_password && errors.new_password.type === 'isNotLower' && (
                                <span className="invalid">
                                    Password at least one lowercase character!
                                </span>
                            )}
                            {errors.new_password && errors.new_password.type === 'isNotUpper' && (
                                <span className="invalid">
                                    Password at least one uppercase character!
                                </span>
                            )}
                            {errors.new_password && errors.new_password.type === 'isNotDigit' && (
                                <span className="invalid">Password at least one digit!</span>
                            )}
                            {errors.new_password && errors.new_password.type === 'isNotSpecial' && (
                                <span className="invalid">
                                    Password at least one special character!
                                </span>
                            )}
                            {errors.new_password && errors.new_password.type === 'isNotMax20' && (
                                <span className="invalid">
                                    Password should maximum 20 characters!
                                </span>
                            )}
                            {errors.new_password &&
                                errors.new_password.type === 'isNotWhiteSpace' && (
                                    <span className="invalid">
                                        Password not contain white spaces!
                                    </span>
                                )}
                            {errors.new_password && (
                                <span className="invalid">{errors.new_password?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                Confirm Password<span className="invalid">*</span>
                            </Form.Label>
                            <Form.Control
                                name="new_password_confirmation"
                                id="new_password_confirmation"
                                type="password"
                                placeholder=""
                                autoComplete="off"
                                {...register('new_password_confirmation', {
                                    required: 'Please enter your confirm password.',
                                    validate: (value) => {
                                        const { new_password } = getValues();
                                        return new_password === value || 'Passwords should match!';
                                    },
                                })}
                                className={errors?.new_password_confirmation && 'invalidInput'}
                            />
                            {errors.new_password_confirmation && (
                                <span className="invalid">
                                    {errors.new_password_confirmation?.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <div className="d-flex mt-4">
                    <Button
                        className="btnCancel w-50 me-1"
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <Button className="btnSuccess w-50" type="submit" variant="success">
                        Submit
                    </Button>
                </div>
            </Form>
        </div>
    );
}
