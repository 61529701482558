/*
 * Author M. Atoar Rahman
 * Title: Partner Information
 * Description: Page for Partner Information
 * Date: 15/03/2022
 */

import React from 'react';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { PartnerProvider } from '../masterSetup/partnerInfo/ContextAPI';
import DataTable from '../masterSetup/partnerInfo/DataTable';
import FormInfo from '../masterSetup/partnerInfo/FormInfo';
import PermissionDenied from './PermissionDenied';

export default function PartnerInformation() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['partner-info']) return <PermissionDenied />;

    return (
        <PartnerProvider>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Partner Information</h3>
                    <div>
                        <FormInfo />
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable />
                </Card.Body>
            </Card>
        </PartnerProvider>
    );
}
