/* eslint-disable no-useless-escape */
export const namePattern = {
    value: /^[a-z0-9 \.]*$/i,
    message: 'Only use alphanumeric value!',
};
export const mobilePattern = {
    value: /^(\+88|0088|88)?01[3-9]{1}\d{8}$/i,
    message: 'Please enter valid mobile number!',
};
export const phonePattern = {
    value: /^[\d]{9,14}$/i,
    message: 'Please enter valid phone number!',
};
export const nidPattern = {
    value: /^[\d]{10,17}$/i,
    message: 'Please enter valid NID number!',
};
export const emailPattern = {
    // value: /^[a-z0-9\.]+@[a-z]+\.[a-z]{2,4}$/,
    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/,
    message: 'Please enter valid email address!',
};
export const addressPattern = {
    value: /^[A-Z\d\s\#\:\-\/\,]*$/i,
    message: 'Only use alphanumeric value!',
};
export const webPattern = {
    value: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
    message: 'Please enter valid web address!',
};
