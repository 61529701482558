/*
 * Author M. Atoar Rahman
 * Title: Country Setup DataTable
 * Description: Country Setup DataTable
 * Date: 15/03/2022
 */

import useDataTable from '../../hooks/useDataTable';
import Countries from '../../json/Countries.json';

export default function DataTable() {
    const columns = [
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Abbreviation',
            selector: (row) => row.iso2,
            sortable: true,
        },
        {
            name: 'Capital',
            selector: (row) => row.capital,
            sortable: true,
        },
        {
            name: 'Native',
            selector: (row) => row.native,
            sortable: true,
        },
        {
            name: 'Region',
            selector: (row) => row.region,
            sortable: true,
        },
    ];
    const search_column = { name: 'Name', capital: 'Capital', native: 'Native', region: 'Region' };
    return useDataTable({ columns, data: Countries, search_column });
}
