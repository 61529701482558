/* eslint-disable consistent-return */
/*
 * Author M. Atoar Rahman
 * Title: Hook: useRefreshToken
 * Description: Hook: useRefreshToken
 * Date: 15/03/2022
 */

import cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { useAuth } from '../contexts/AuthContext';
import UrlService from '../services/UrlService';

const REFRESH_URL = UrlService.refreshTokenUrl();

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const token = cookie.get('token');
    const navigate = useNavigate();

    const refresh = async () => {
        try {
            const response = await axios.post(
                REFRESH_URL,
                { token },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setAuth((prev) => {
                cookie.set('token', response.data.access_token);
                return {
                    ...prev,
                    users: response?.data?.users,
                    roles: response?.data?.roles,
                    menus: response?.data?.menus,
                    route_permissions: response?.data?.route_permissions,
                    accessToken: response?.data?.access_token,
                    employee_info: response?.data?.employee_info,
                };
            });
            return response.data.access_token;
        } catch (err) {
            cookie.remove('token');
            navigate('/login', { replace: true });
        }
    };
    return refresh;
};

export default useRefreshToken;
