/*
 * Author M. Atoar Rahman
 * Title: Layer Chaining
 * Description: Page for Layer Chaining
 * Date: 20/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { LayerChainingProvider } from '../masterSetup/layerChaining/ContextAPI';
import Index from '../masterSetup/layerChaining/Index';
import PermissionDenied from './PermissionDenied';

export default function LayerChaining() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['layer-chaining']) return <PermissionDenied />;

    return (
        <LayerChainingProvider>
            <Index />
        </LayerChainingProvider>
    );
}
