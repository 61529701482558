/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: D1 Form: Medicines Requisition for Facility
 * Description: D1 Form: Medicines Requisition for Facility
 * Date: 30/05/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { layout } from '../../SvgIcon';
import { useD1Form } from './ContextAPI';
import SweetAlertService from '../../../services/SweetAlertService';

export default function DataTable() {
    const { d1Forms, getAllData, setRowData, setShowModal } = useD1Form();
    const { deleteSwal } = SweetAlertService();
    const axiosPrivate = useAxiosPrivate();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const showHander = async (row) => {
        try {
            const response = await axiosPrivate.get(UrlService.getD1RecordByIdUrl(row.id), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const row_info = {
                facility_name: row.facility_name,
                month: row.month,
                year: row.year,
                records: response?.data?.d1_record_list,
            };
            setRowData(row_info);
            setShowModal(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const deleteHandler = (row) => {
        deleteSwal({
            id: row.id,
            url: UrlService.deleteD1FormUrl(),
            refreshList: getAllData,
            beforeTitle: 'Are you sure?',
            beforeText: "You won't be able to revert this!",
            afterTitle: 'Deleted!',
            afterText: 'Your file has been deleted.',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    };

    const columns = [
        {
            name: 'Month',
            selector: (row) => row.month,
            sortable: true,
            cell: (row) =>
                row.month == 1
                    ? 'January'
                    : row.month == 2
                    ? 'February'
                    : row.month == 3
                    ? 'March'
                    : row.month == 4
                    ? 'April'
                    : row.month == 5
                    ? 'May'
                    : row.month == 6
                    ? 'June'
                    : row.month == 7
                    ? 'July'
                    : row.month == 8
                    ? 'August'
                    : row.month == 9
                    ? 'September'
                    : row.month == 10
                    ? 'October'
                    : row.month == 11
                    ? 'November'
                    : row.month == 12
                    ? 'December'
                    : '',
        },
        {
            name: 'Year',
            selector: (row) => row.year,
            sortable: true,
        },
        {
            name: 'Submitted Date',
            selector: (row) => row.submitted_date,
            format: (row) => moment(row.submitted_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Approved Date',
            selector: (row) => row.approval_date,
            format: (row) =>
                row.approval_date ? moment(row.approval_date).format('DD MMM YYYY') : '',
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row) =>
                row.status === 'approved' ? (
                    <Badge className="badgeActive" bg="success">
                        Approved
                    </Badge>
                ) : row.status === 'd2_generated' ? (
                    <Badge className="badgeActive" bg="success">
                        D2 Generated
                    </Badge>
                ) : row.status === 'd2_prepared' ? (
                    <Badge className="badgeActive" bg="success">
                        D2 Prepared
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <div>
                    <Button
                        onClick={() => showHander(row)}
                        type="button"
                        className="btn btn-light actionBtn"
                    >
                        {layout}
                    </Button>
                    {row.status !== 'approved' &&
                        row.status !== 'd2_generated' &&
                        row.status !== 'd2_prepared' && (
                            <Button
                                onClick={() => {
                                    deleteHandler(row);
                                }}
                                type="button"
                                className="btn btn-light actionBtn"
                            >
                                <i className="fas fa-trash-alt" />
                            </Button>
                        )}
                </div>
            ),
        },
    ];

    return useDataTable({ columns, data: d1Forms });
}
