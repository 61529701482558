/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Date: 15/03/2022
 * Time: 11:40 AM
 */

import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useData } from './ContextAPI';

export default function FormInfo({ editData, hideFunction }) {
    // API URL
    let SAVE_URL = '';
    if (editData === '') {
        SAVE_URL = UrlService.saveMenuUrl();
    } else {
        SAVE_URL = UrlService.updateMenuUrl(editData.id);
    }

    // Dependency
    const axiosPrivate = useAxiosPrivate();
    const { getAllData } = useData();

    const [errMsg, setErrMsg] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [pageState, setPageState] = useState({
        form_data: {
            name: editData?.name ?? null,
            route: editData?.route ?? null,
            icon: editData?.icon ?? null,
            sort: editData?.sort ?? null,
            main_menu_id: { label: '', value: '' },
            sub_menu_1_id: { label: '', value: '' },
            sub_menu_2_id: { label: '', value: '' },
            sub_menu_3_id: { label: '', value: '' },
            status: editData?.status ?? true,
            extra_menu_status: editData?.extra_route?.length > 0 ? true : null,
            extra_route:
                editData?.extra_route?.length > 0
                    ? editData.extra_route.map((el) => ({
                          name: el.name,
                          section: el.section,
                          sort: el.sort,
                      }))
                    : [{ name: '', section: '', sort: '' }],
        },
        get_data: {
            main_menus: [],
            sub_1_menus: [],
            sub_2_menus: [],
            sub_3_menus: [],
        },
        errMsg: '',
    });

    const handleClose = () => {
        getAllData();
        hideFunction(false);
    };

    const getSubMenu = async (column, target_data, value) => {
        try {
            let list = {};
            if (column === 'main_menu_id') {
                list = { main_menu_id: value };
            } else if (column === 'sub_menu_1_id') {
                list = { sub_menu_1_id: value };
            } else if (column === 'sub_menu_2_id') {
                list = { sub_menu_2_id: value };
            } else if (column === 'sub_menu_3_id') {
                list = { sub_menu_3_id: value };
            }

            const response = await axiosPrivate.post(
                UrlService.getSubMenu(),
                JSON.stringify(list),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const sub_menus = response?.data?.sub_menus?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setPageState((prevState) => ({
                ...prevState,
                get_data: {
                    ...prevState.get_data,
                    [target_data]: sub_menus,
                },
            }));
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    useEffect(() => {
        getSubMenu(null, 'main_menus', null);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        try {
            const response = await axiosPrivate.post(SAVE_URL, pageState.form_data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (editData != null) {
                ToastifyService.updateNotify('Data Update Successfull');
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
            }
            handleClose();
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else {
                setErrMsg('Data Inserted Failed!');
            }
            ToastifyService.errorNotify(errMsg);
        }
    };

    console.log(editData);
    console.log(pageState);
    return (
        <Modal show onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add/Edit User Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Row>
                            <Col lg={6} className="mt-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder=""
                                    id="name"
                                    name="name"
                                    autoComplete="off"
                                    onChange={(e) =>
                                        setPageState({
                                            ...pageState,
                                            form_data: {
                                                ...pageState.form_data,
                                                name: e.target.value,
                                            },
                                        })
                                    }
                                    value={pageState.form_data.name}
                                />
                            </Col>
                            <Col lg={6} className="mt-3">
                                <Form.Label>Path</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder=""
                                    id="route"
                                    name="route"
                                    autoComplete="off"
                                    onChange={(e) =>
                                        setPageState({
                                            ...pageState,
                                            form_data: {
                                                ...pageState.form_data,
                                                route: e.target.value,
                                            },
                                        })
                                    }
                                    value={pageState.form_data.route}
                                />
                            </Col>
                            <Col lg={6} className="mt-3">
                                <Form.Label>Icon</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    id="icon"
                                    name="icon"
                                    autoComplete="off"
                                    onChange={(e) =>
                                        setPageState({
                                            ...pageState,
                                            form_data: {
                                                ...pageState.form_data,
                                                icon: e.target.value,
                                            },
                                        })
                                    }
                                    value={pageState.form_data.icon}
                                />
                            </Col>
                            <Col lg={6} className="mt-3">
                                <Form.Label>Sort</Form.Label>
                                <Form.Control
                                    required
                                    type="number"
                                    placeholder=""
                                    id="sort"
                                    name="sort"
                                    autoComplete="off"
                                    onChange={(e) =>
                                        setPageState({
                                            ...pageState,
                                            form_data: {
                                                ...pageState.form_data,
                                                sort: e.target.value,
                                            },
                                        })
                                    }
                                    value={pageState.form_data.sort}
                                />
                            </Col>

                            <Col lg={6} className="mt-3">
                                <Form.Label>Module</Form.Label>

                                <Select
                                    name="main_menu_id"
                                    options={pageState.get_data.main_menus}
                                    onChange={(e) => {
                                        setPageState({
                                            ...pageState,
                                            form_data: {
                                                ...pageState.form_data,
                                                main_menu_id: {
                                                    ...pageState.form_data.main_menu_id,
                                                    label: e.label,
                                                    value: e.value,
                                                },
                                            },
                                        });
                                        getSubMenu('main_menu_id', 'sub_1_menus', e.value);
                                    }}
                                    value={{
                                        label: pageState.form_data.main_menu_id.label,
                                        value: pageState.form_data.main_menu_id.value,
                                    }}
                                />
                            </Col>

                            <Col lg={6} className="mt-3">
                                <Form.Label>Sub Menu 1</Form.Label>

                                <Select
                                    name="sub_menu_1_id"
                                    options={pageState.get_data.sub_1_menus}
                                    onChange={(e) => {
                                        setPageState({
                                            ...pageState,
                                            form_data: {
                                                ...pageState.form_data,
                                                sub_menu_1_id: {
                                                    ...pageState.form_data.sub_menu_1_id,
                                                    label: e.label,
                                                    value: e.value,
                                                },
                                            },
                                        });
                                        getSubMenu('sub_menu_1_id', 'sub_2_menus', e.value);
                                    }}
                                    value={{
                                        label: pageState.form_data.sub_menu_1_id.label,
                                        value: pageState.form_data.sub_menu_1_id.value,
                                    }}
                                />
                            </Col>

                            <Col lg={6} className="mt-3">
                                <Form.Label>Sub menu 2</Form.Label>
                                <Select
                                    name="sub_menu_2_id"
                                    options={pageState.get_data.sub_2_menus}
                                    onChange={(e) => {
                                        setPageState({
                                            ...pageState,
                                            form_data: {
                                                ...pageState.form_data,
                                                sub_menu_2_id: {
                                                    ...pageState.form_data.sub_menu_2_id,
                                                    label: e.label,
                                                    value: e.value,
                                                },
                                            },
                                        });
                                        getSubMenu('sub_menu_2_id', 'sub_3_menus', e.value);
                                    }}
                                    value={{
                                        label: pageState.form_data.sub_menu_2_id.label,
                                        value: pageState.form_data.sub_menu_2_id.value,
                                    }}
                                />
                            </Col>

                            <Col lg={6} className="mt-3">
                                <Form.Label>Sub menu 3</Form.Label>

                                <Select
                                    name="sub_menu_3_id"
                                    options={pageState.get_data.sub_3_menus}
                                    onChange={(e) => {
                                        setPageState({
                                            ...pageState,
                                            form_data: {
                                                ...pageState.form_data,
                                                sub_menu_3_id: {
                                                    ...pageState.form_data.sub_menu_3_id,
                                                    label: e.label,
                                                    value: e.value,
                                                },
                                            },
                                        });
                                    }}
                                    value={{
                                        label: pageState.form_data.sub_menu_3_id.label,
                                        value: pageState.form_data.sub_menu_3_id.value,
                                    }}
                                />
                            </Col>
                            <Col lg={6} className="mt-3">
                                <Form.Label>Status</Form.Label>
                                <Form.Check
                                    type="switch"
                                    checked={pageState.form_data.status}
                                    id="status"
                                    name="status"
                                    onChange={(e) =>
                                        setPageState({
                                            ...pageState,
                                            form_data: {
                                                ...pageState.form_data,
                                                status: e.target.checked,
                                            },
                                        })
                                    }
                                    value={pageState.form_data.status}
                                />
                            </Col>
                            <Col lg={6} className="mt-3">
                                <Form.Label>Extra Menu</Form.Label>
                                <Form.Check
                                    type="switch"
                                    checked={pageState.form_data.extra_menu_status}
                                    id="status"
                                    name="status"
                                    onChange={(e) =>
                                        setPageState({
                                            ...pageState,
                                            form_data: {
                                                ...pageState.form_data,
                                                extra_menu_status: e.target.checked,
                                            },
                                        })
                                    }
                                    value={pageState.form_data.extra_menu_status}
                                />
                            </Col>
                        </Row>

                        {pageState.form_data.extra_menu_status &&
                            pageState.form_data.extra_route.map((x, i) => (
                                <Row key={Math.random()}>
                                    <Col lg={3} className="mt-3">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Extra Name"
                                            id="name"
                                            name="name"
                                            autoComplete="off"
                                            value={x.name}
                                            onChange={(e) => {
                                                const list = [...pageState.form_data.extra_route];
                                                list[i].name = e.target.value;
                                                setPageState({
                                                    ...pageState,
                                                    form_data: {
                                                        ...pageState.form_data,
                                                        extra_route: list,
                                                    },
                                                });
                                            }}
                                        />
                                    </Col>
                                    <Col lg={3} className="mt-3">
                                        <Form.Label>Section Name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Extra Section Name"
                                            id="section"
                                            name="section"
                                            autoComplete="off"
                                            value={x.section}
                                            onChange={(e) => {
                                                const list = [...pageState.form_data.extra_route];
                                                list[i].section = e.target.value;
                                                setPageState({
                                                    ...pageState,
                                                    form_data: {
                                                        ...pageState.form_data,
                                                        extra_route: list,
                                                    },
                                                });
                                            }}
                                        />
                                    </Col>
                                    <Col lg={3} className="mt-3">
                                        <Form.Label>Sort</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="Extra Sort"
                                            id="sort"
                                            name="sort"
                                            autoComplete="off"
                                            value={x.sort}
                                            onChange={(e) => {
                                                const list = [...pageState.form_data.extra_route];
                                                list[i].sort = e.target.value;
                                                setPageState({
                                                    ...pageState,
                                                    form_data: {
                                                        ...pageState.form_data,
                                                        extra_route: list,
                                                    },
                                                });
                                            }}
                                        />
                                    </Col>

                                    <Col lg={3} style={{ marginTop: '40px' }}>
                                        {pageState.form_data.extra_route.length - 1 === i && (
                                            <Button
                                                className="btn btn-success text-white actionBtn"
                                                onClick={() => {
                                                    setPageState({
                                                        ...pageState,
                                                        form_data: {
                                                            ...pageState.form_data,
                                                            extra_route: [
                                                                ...pageState.form_data.extra_route,
                                                                { name: '', section: '', sort: '' },
                                                            ],
                                                        },
                                                    });
                                                }}
                                            >
                                                <i className="fas fa-plus" />
                                            </Button>
                                        )}
                                        {pageState.form_data.extra_route.length !== 1 && (
                                            <Button
                                                className="btn btn-danger text-white actionBtn"
                                                onClick={() => {
                                                    const list = [
                                                        ...pageState.form_data.extra_route,
                                                    ];
                                                    list.splice(i, 1);
                                                    setPageState({
                                                        ...pageState,
                                                        form_data: {
                                                            ...pageState.form_data,
                                                            extra_route: list,
                                                        },
                                                    });
                                                }}
                                            >
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            ))}
                    </Form.Group>

                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            type="submit"
                            onSubmit={handleSubmit}
                            className="btnSuccess"
                            variant="success"
                            disabled={isSubmitted}
                        >
                            {editData ? 'Updated' : 'Create New'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
