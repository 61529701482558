/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { toArray } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import CommonApiService from '../../../services/CommonApiService';
import { useApproval } from './ContextAPI';
import ShowTable from './ShowTable';

export default function ShowModel() {
    const {
        show,
        setShow,
        rowData,
        addFacilityStockDistributionApproval,
        receiveData,
        setReceiveData,
        receiveList,
        setReceiveList,
    } = useApproval();
    const { drugCategory, getDrugCategory } = CommonApiService();
    const [page, setPage] = useState(0);
    const [pageLength, setPageLength] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        const list = {};
        rowData?.records?.map((el) => {
            const itemdata = {};
            itemdata.id = el.id;
            itemdata.drug_id = el.drug_id;
            itemdata.batch_no = el.batch_no;
            itemdata.category_id = el.category_id;
            itemdata.mfg_date = el.mfg_date;
            itemdata.expire_date = el.expire_date;
            itemdata.supplied_qty = el.supplied_qty;
            itemdata.approved_supply_qty =
                el.approved_supply_qty > 0 ? el.approved_supply_qty : el.supplied_qty;
            list[el.id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }

            setReceiveData({ ...list, [el.id]: itemdata });

            const arrOfObj = toArray(list);
            setReceiveList(arrOfObj);
            return receiveData;
        });
    }, []);
    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    // Closing the Model
    const handleClose = () => {
        setShow(false);
    };

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <ShowTable data={rowData?.records?.filter((el) => el.category_id == item.id)} />
            </Tab>
        ));

    const submitHandler = () => {
        setIsSubmitted(true);
        const data = {
            stock_out_master_id: rowData.stock_out_master_id,
            requisition: receiveList,
        };
        addFacilityStockDistributionApproval(data);
    };

    return (
        <Modal className="modalWidth80" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Facility Stock Distribution Approval</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Table bordered size="sm">
                                <thead className="table-light">
                                    <tr>
                                        <th>Dispensary Name</th>
                                        <th>Stockout Date</th>
                                    </tr>
                                </thead>
                                <tbody className="dark-light-bg">
                                    <tr>
                                        <td>{rowData?.dispensary_name}</td>
                                        <td>{rowData?.stockout_date}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Tabs
                            activeKey={page}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3 ps-2"
                            // eslint-disable-next-line radix
                            onSelect={(k) => setPage(parseInt(k))}
                        >
                            {tableDataDisplay()}
                        </Tabs>
                    </Row>

                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className="btnInfo me-2 ms-2"
                            variant="primary"
                            style={{ minWidth: '6rem' }}
                            disabled={page === 0}
                            onClick={() => {
                                setPage((currPage) => currPage - 1);
                            }}
                        >
                            Prev
                        </Button>
                        <Button
                            type="button"
                            className={`btnSuccess ${page === pageLength - 1 && 'd-none'}`}
                            variant="success"
                            style={{ minWidth: '6rem' }}
                            onClick={() => setPage((currPage) => currPage + 1)}
                        >
                            Next
                        </Button>
                        {rowData.status === 'distributed' && (
                            <Button
                                onClick={submitHandler}
                                className={`btnSuccess ${page !== pageLength - 1 && 'd-none'}`}
                                type="button"
                                variant="success"
                                style={{ minWidth: '6rem' }}
                                disabled={isSubmitted}
                            >
                                Submit
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
