/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useApproval } from './ContextAPI';
import ShowTable from './ShowTable';

export default function ShowModel() {
    const { show, setShow, rowData } = useApproval();

    // Closing the Model
    const handleClose = () => {
        setShow(false);
    };

    const tableDataDisplay = () => <ShowTable data={rowData?.records} />;

    return (
        <Modal className="modalWidth80" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Internal Request Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Table bordered hover size="sm">
                                <thead className="table-light">
                                    <tr>
                                        <th>Facility Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{rowData?.facility_name}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>{tableDataDisplay()}</Row>

                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
