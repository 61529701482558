/*
 * Author M. Atoar Rahman
 * Title: Dropdown Menu
 * Description: Dropdown Menu
 * Date: 15/03/2022
 */

import React, { useEffect, useRef } from 'react';

import { useMenu } from './ContextAPI';

export default function Dropdown({ buttonTemplate, menuTemplate, className }) {
    const dropdownRef = useRef(null);

    const { show, setShow } = useMenu();

    const toggleDropdown = () => {
        setShow(!show);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, false);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, false);
        };
    });

    return (
        <li ref={dropdownRef} className={`nav-item dropdown ${className ? ` ${className}` : ''}`}>
            <button onClick={toggleDropdown} type="button" className="nav-link">
                {buttonTemplate}
            </button>

            {show ? <ul className="dropdown-menu show">{menuTemplate}</ul> : null}
        </li>
    );
}
