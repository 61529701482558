/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Current Stock Entry
 * Description: Current Stock Entry
 * Date: 26/04/2022
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useCurrentStockEntry } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const { setShowForm, setStockData } = useCurrentStockEntry();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const layer = auth?.employee_info?.layer_id;

    // Local State
    const [centerWarehouseData, setCenterWarehouseData] = useState();
    const [partnerData, setPartnerData] = useState();
    const [facilityData, setFacilityData] = useState();
    const [dispensaryData, setDispensaryData] = useState();

    const {
        register,
        formState: { errors },
        reset,
        resetField,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                partner_id: auth?.employee_info?.partner_id && {
                    label: auth?.employee_info?.partner_name,
                    value: auth?.employee_info?.partner_id,
                },
                facility_id: auth?.employee_info?.facility_id && {
                    label: auth?.employee_info?.facility_name,
                    value: auth?.employee_info?.facility_id,
                },
                dispensary_id: auth?.employee_info?.dispensary_id && {
                    label: auth?.employee_info?.dispensary_name,
                    value: auth?.employee_info?.dispensary_id,
                },
            }),
            []
        ),
    });

    const {
        partner,
        getPartner,
        facilityByPartner,
        getFacilityByPartner,
        dispensaryByFacility,
        getDispensaryByFacility,
        centerWarehouse,
        getCenterWarehouse,
    } = CommonApiService();

    const dataList = useCallback(() => {
        getPartner(); // Partner
        getCenterWarehouse(); // Center Warehouse
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Dispensary
        setDispensaryData(
            dispensaryByFacility?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Center Warehouse
        setCenterWarehouseData(
            centerWarehouse?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner, facilityByPartner, dispensaryByFacility, centerWarehouse]);

    // Get Facility by Partner login
    useEffect(() => {
        if (auth?.employee_info?.partner_id) {
            getFacilityByPartner(auth?.employee_info?.partner_id);
        }
    }, [auth?.employee_info?.partner_id]);

    // Get Dispensary by Facility login
    useEffect(() => {
        if (auth?.employee_info?.facility_id) {
            getDispensaryByFacility(auth?.employee_info?.facility_id);
        }
    }, [auth?.employee_info?.facility_id]);

    const [checked, setChecked] = useState('');

    const handleCentralCurrentStock = () => {
        reset();
        resetField();
        setStockData('');

        if (checked === 'checked') {
            setChecked('');
        } else {
            setChecked('checked');
        }
    };

    // After Login
    useEffect(async () => {
        const formData = {
            layer,
            partner_id: auth?.employee_info?.partner_id,
            facility_id: auth?.employee_info?.facility_id,
            dispensary_id: auth?.employee_info?.dispensary_id,
        };
        try {
            const response = await axiosPrivate.post(
                UrlService.getAllCurrentStockEntryUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setStockData(response.data.stock_in_info);
            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.message);
                const message = Object.keys(response.data.message);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShowForm(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    }, []);

    const formSubmitHandler = async (data) => {
        const formData = {
            layer,
            warehouse_id: data?.warehouse_id?.value,
            partner_id: data?.partner_id?.value,
            facility_id: data?.facility_id?.value,
            dispensary_id: data?.dispensary_id?.value,
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.getAllCurrentStockEntryUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setStockData(response.data.stock_in_info);

            if (response.data.stock_in_info.length == 0) {
                ToastifyService.dbErrorNotify('Item Not found');
            }

            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.message);
                const message = Object.keys(response.data.message);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShowForm(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Form>
            {layer == 1 && (
                <Row>
                    <Col>
                        <Form.Group className="mb-3 d-flex lign-items-center">
                            <Form.Check
                                name="central_current_stock"
                                id="user-status-switch"
                                type="switch"
                                checked={checked}
                                onClick={() => {
                                    handleCentralCurrentStock();
                                }}
                                onChange={() => {
                                    handleCentralCurrentStock();
                                }}
                                {...register('central_current_stock')}
                            />
                            <span className="mt-1">Central Stock</span>
                        </Form.Group>
                    </Col>
                    <hr />
                </Row>
            )}
            {((layer == 1 && checked) || layer == 2) && (
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Warehouse<span className="invalid">*</span>
                            </Form.Label>
                            <Controller
                                name="warehouse_id"
                                id="warehouse_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.warehouse_id && 'invalidInput'}
                                        options={centerWarehouseData}
                                    />
                                )}
                                rules={{ required: 'Please select your warehouse.' }}
                            />
                            {errors?.warehouse_id && (
                                <span className="invalid">{errors?.warehouse_id?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col className="my-auto" lg={2}>
                        <Button
                            className="btnSuccess"
                            onClick={handleSubmit(formSubmitHandler)}
                            type="button"
                            variant="success"
                        >
                            View
                        </Button>
                    </Col>
                </Row>
            )}
            {!checked && layer != 2 && (
                <Row>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Partner Agency<span className="invalid">*</span>
                            </Form.Label>
                            <Controller
                                name="partner_id"
                                id="partner_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.partner_id && 'invalidInput'}
                                        options={partnerData}
                                        isDisabled={auth?.employee_info?.partner_id}
                                        onBlur={async () => {
                                            await resetField('facility_id');
                                            await resetField('dispensary_id');
                                            await getFacilityByPartner(field?.value?.value);
                                        }}
                                    />
                                )}
                                rules={{ required: 'Please select your partner.' }}
                            />
                            {errors?.partner_id && (
                                <span className="invalid">{errors?.partner_id?.message}</span>
                            )}
                        </Form.Group>
                    </Col>

                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Facility Name</Form.Label>
                            <Controller
                                name="facility_id"
                                id="facility_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.facility_id && 'invalidInput'}
                                        options={facilityData}
                                        isDisabled={
                                            auth?.employee_info?.partner_id &&
                                            auth?.employee_info?.facility_id
                                        }
                                        onBlur={async () => {
                                            await resetField('dispensary_id');
                                            await getDispensaryByFacility(field?.value?.value);
                                        }}
                                    />
                                )}
                                // rules={{ required: 'Please select your facility.' }}
                            />
                            {errors?.facility_id && (
                                <span className="invalid">{errors?.facility_id?.message}</span>
                            )}
                        </Form.Group>
                    </Col>

                    <Col lg={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>Department Name</Form.Label>
                            <Controller
                                name="dispensary_id"
                                id="dispensary_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isDisabled={
                                            auth?.employee_info?.partner_id &&
                                            auth?.employee_info?.facility_id &&
                                            auth?.employee_info?.dispensary_id
                                        }
                                        classNamePrefix={errors?.dispensary_id && 'invalidInput'}
                                        options={dispensaryData}
                                    />
                                )}
                                // rules={{ required: 'Please select your dispensary.' }}
                            />
                            {errors?.dispensary_id && (
                                <span className="invalid">{errors?.dispensary_id?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col className="m-auto" lg={1}>
                        <Button
                            className="btnSuccess"
                            onClick={handleSubmit(formSubmitHandler)}
                            type="button"
                            variant="success"
                        >
                            View
                        </Button>
                    </Col>
                </Row>
            )}
        </Form>
    );
}
