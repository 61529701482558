/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Contex API
 * Description: Contex API
 * Date: 15/07/2022
 */

import { createContext, useContext, useState } from 'react';

// Create Context
const ContextAPI = createContext();

export function usePoStock() {
    return useContext(ContextAPI);
}

// Provider Component
export function PoStockProvider({ children }) {
    const [stockData, setStockData] = useState();
    const [rowData, setRowData] = useState();

    const [showDetails, setShowDetails] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [pendingDetailsShow, setPendingDetailsShow] = useState(false);
    const [showList, setShowList] = useState(false);
    const [showPdfList, setShowPdfList] = useState(false);
    const [showPdfDetails, setShowPdfDetails] = useState(false);

    return (
        <ContextAPI.Provider
            value={{
                showDetails,
                setShowDetails,
                showList,
                setShowList,
                showForm,
                setShowForm,
                pendingDetailsShow,
                setPendingDetailsShow,
                stockData,
                setStockData,
                rowData,
                setRowData,
                showPdfList,
                setShowPdfList,
                showPdfDetails,
                setShowPdfDetails,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
