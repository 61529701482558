/* Author M. Atoar Rahman
 * Title: Partner Stock Receive
 * Description: Page for Partner Stock Receive
 * Date: 27/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { PartnerStockReceiveProvider } from '../partner/stockReceive/ContextAPI';
import Index from '../partner/stockReceive/Index';
import PermissionDenied from './PermissionDenied';

export default function PartnerStockReceive() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['partner-stock-receive']) return <PermissionDenied />;

    return (
        <PartnerStockReceiveProvider>
            <Index />
        </PartnerStockReceiveProvider>
    );
}
