/*
 * Author M. Atoar Rahman
 * Title: Dispensary Stock Return
 * Description: Page for Dispensary Stock Return
 * Date: 19/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { FacilityStockReturnProvider } from '../facility/stockReturn/ContextAPI';
import Index from '../facility/stockReturn/Index';
import PermissionDenied from './PermissionDenied';

export default function FacilityStockReturn() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['facility-stock-return']) return <PermissionDenied />;

    return (
        <FacilityStockReturnProvider>
            <Index />
        </FacilityStockReturnProvider>
    );
}
