/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useAuth } from '../../../contexts/AuthContext';

export default function MenuTree({ permissionSubmit, pageState }) {
    const { auth } = useAuth();
    const { route_permissions } = auth;

    const [nodes, setNodes] = useState([]);
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const onCheck = (checkeded) => {
        setChecked(checkeded);
    };

    const onExpand = (expandeded) => {
        setExpanded(expandeded);
    };

    useEffect(() => {
        const expandId = [];
        const checkedId = [];
        const { permission_menus } = pageState;

        const permission_menu_list = Object.values(permission_menus).map((el1) => {
            expandId.push(`111&${el1.id}&${el1.route}&${el1.menu_from}`);
            if (el1.permission === true) {
                checkedId.push(`111&${el1.id}&${el1.route}&${el1.menu_from}`);
            }
            return {
                value: `111&${el1.id}&${el1.route}&${el1.menu_from}`,
                label: el1.name,
                children:
                    (el1.child ? el1.child : el1.menu_route) &&
                    Object.values(el1.child ? el1.child : el1.menu_route).map((el2) => {
                        expandId.push(`222&${el2.id}&${el2.route}&${el2.menu_from}`);

                        if (el2.permission === true) {
                            checkedId.push(`222&${el2.id}&${el2.route}&${el2.menu_from}`);
                        }
                        return {
                            value: `222&${el2.id}&${el2.route}&${el2.menu_from}`,
                            label: el2.name,
                            children:
                                (el2.child ? el2.child : el2.menu_route) &&
                                Object.values(el2.child ? el2.child : el2.menu_route).map((el3) => {
                                    expandId.push(`333&${el3.id}&${el3.route}&${el3.menu_from}`);

                                    if (el3.permission === true) {
                                        checkedId.push(
                                            `333&${el3.id}&${el3.route}&${el3.menu_from}`
                                        );
                                    }

                                    return {
                                        value: `333&${el3.id}&${el3.route}&${el3.menu_from}`,
                                        label: el3.name,
                                        children:
                                            (el3.child ? el3.child : el3.menu_route) &&
                                            Object.values(
                                                el3.child ? el3.child : el3.menu_route
                                            ).map((el4) => {
                                                expandId.push(
                                                    `444&${el4.id}&${el4.route}&${el4.menu_from}`
                                                );

                                                if (el4.permission === true) {
                                                    checkedId.push(
                                                        `444&${el4.id}&${el4.route}&${el4.menu_from}`
                                                    );
                                                }
                                                return {
                                                    value: `444&${el4.id}&${el4.route}&${el4.menu_from}`,
                                                    label: el4.name,
                                                    children: null,
                                                };
                                            }),
                                    };
                                }),
                        };
                    }),
            };
        });

        setChecked(checkedId);
        setExpanded(expandId);
        setNodes(permission_menu_list);
    }, []);

    return (
        <>
            <div className="clickable-labels">
                <CheckboxTree
                    checked={checked}
                    expanded={expanded}
                    iconsClass="fa5"
                    nodes={nodes}
                    expandOnClick
                    showExpandAll
                    onCheck={onCheck}
                    onExpand={onExpand}
                />
            </div>
            {route_permissions['permission-management/role-permission'].edit && (
                <Button
                    type="submit"
                    onClick={() => permissionSubmit(checked)}
                    className="btnSuccess"
                    variant="success"
                    style={{ position: 'fixed', bottom: '20px', right: '30px' }}
                >
                    Submit
                </Button>
            )}
        </>
    );
}
