/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import CommonApiService from '../../../services/CommonApiService';
import { useApproval } from './ContextAPI';
import ShowTable from './ShowTable';

export default function ShowModel() {
    const { show, setShow, rowData } = useApproval();

    const { drugCategory, getDrugCategory } = CommonApiService();

    // Local State
    const [page, setPage] = useState(0);
    const [pageLength, setPageLength] = useState(0);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <ShowTable data={rowData?.records?.filter((el) => el.category_id == item.id)} />
            </Tab>
        ));

    // Closing the Model
    const handleClose = () => {
        setShow(false);
    };

    return (
        <Modal className="modalWidth80" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Stock Return Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Table bordered hover size="sm">
                                <thead className="table-light">
                                    <tr>
                                        <th>Partner Name</th>
                                        <th>Facility Name</th>
                                        <th>Requested Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{rowData?.partner_name}</td>
                                        <td>{rowData?.facility_name}</td>

                                        <td>
                                            {rowData?.requested_date
                                                ? moment(rowData?.requested_date).format(
                                                      'DD MMM YYYY'
                                                  )
                                                : ''}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Tabs
                            activeKey={page}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3 ps-3"
                            // eslint-disable-next-line radix
                            onSelect={(k) => setPage(parseInt(k))}
                        >
                            {tableDataDisplay()}
                        </Tabs>
                    </Row>
                    <div className="formFooter">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className="btnInfo me-2 ms-2"
                            variant="primary"
                            style={{ minWidth: '6rem' }}
                            disabled={page === 0}
                            onClick={() => {
                                setPage((currPage) => currPage - 1);
                            }}
                        >
                            Prev
                        </Button>
                        <Button
                            type="button"
                            className="btnSuccess"
                            variant="success"
                            style={{ minWidth: '6rem' }}
                            onClick={() => setPage((currPage) => currPage + 1)}
                            disabled={page === pageLength - 1}
                        >
                            Next
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
