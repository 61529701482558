/*
 * Author M. Atoar Rahman
 * Title: Drug Information
 * Description: Page for Drug Information
 * Date: 30/04/2022
 */

import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useDrug } from './ContextAPI';
import DataTable from './DataTable';
import FormInfo from './FormInfo';

export default function Index() {
    const { show, setShow, rowData, setRowData, setIsSubmitted } = useDrug();
    const createHander = () => {
        setShow(true);
        setIsSubmitted(false);
        setRowData('');
    };
    const backHander = () => {
        setShow(false);
        setRowData('');
    };
    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Drug Information</h3>
                {show ? (
                    <div>
                        <Button onClick={() => backHander()} className="btnInfo" variant="primary">
                            Back to List
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button
                            onClick={() => createHander()}
                            className="btnInfo"
                            variant="primary"
                        >
                            Create New
                        </Button>
                    </div>
                )}
            </Card.Header>
            <Card.Body>{show ? <FormInfo editData={rowData} /> : <DataTable />}</Card.Body>
        </Card>
    );
}
