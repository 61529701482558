import { Button, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import ApprovedTable from './ApprovedTable';
import { useStockReturnRequest } from './ContextAPI';
import FormInfo from './FormInfo';
import PendingTable from './PendingTable';
import ReturnForm from './ReturnForm';
import ShowModalData from './ShowModal';

export default function Index() {
    const { show, showList, setListShow, showModal } = useStockReturnRequest();
    console.log(showModal);

    if (showList) {
        return (
            <>
                <Card>
                    <Card.Header className="cardHeader">
                        <>
                            <h3>Stock Return Request</h3>
                            <div>
                                <Button
                                    onClick={() => setListShow(false)}
                                    className="btnInfo"
                                    variant="primary"
                                >
                                    New Request
                                </Button>
                            </div>
                        </>
                    </Card.Header>
                    <Card.Body>
                        <Row className="requisition_table">
                            <Col>
                                <Tabs
                                    defaultActiveKey="pending"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="pending" title="Pending">
                                        <PendingTable />
                                    </Tab>
                                    <Tab eventKey="approved" title="Approved">
                                        <ApprovedTable />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {showModal && <ShowModalData />}
            </>
        );
    }
    return show ? <ReturnForm /> : <FormInfo />;
}
