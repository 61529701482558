/*
 * Author M. Atoar Rahman
 * Title: PO Reports
 * Description: Page for PO Reports
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { PoReportsProvider } from '../misReports/poReports/ContextAPI';
import Index from '../misReports/poReports/Index';
import PermissionDenied from './PermissionDenied';

export default function PoReports() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['po-reports']) return <PermissionDenied />;

    return (
        <PoReportsProvider>
            <Index />
        </PoReportsProvider>
    );
}
