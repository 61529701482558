/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/*
 * Author M. Atoar Rahman
 * Title: Expired Medicine DataTable
 * Description: Expired Medicine DataTable
 * Date: 15/03/2022
 */

import { Fragment } from 'react';
import useDataTable from '../../../hooks/useDataTable';
import { useNearlyExpiredMedicine } from './ContextAPI';

export default function DataTable() {
    const { data } = useNearlyExpiredMedicine();

    const tableStyle = {
        paddingLeft: '5px',
        paddingRight: '5px',
        lineHeight: '33px',
    };

    function ItemTitle() {
        return <span className="ps-2">Item Name</span>;
    }

    function Title() {
        return (
            <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                <thead>
                    <tr style={{ borderTopWidth: '0px', borderBottomWidth: '0px' }}>
                        <th style={{ lineHeight: '34px' }} width="150px" className="m-0">
                            Batch No
                        </th>
                        <th width="150px" className="m-0">
                            Expired Date
                        </th>
                        <th width="150px" className="m-0">
                            Available Qty
                        </th>
                    </tr>
                </thead>
            </table>
        );
    }

    const columns = [
        {
            name: <ItemTitle />,
            cell: (row) => <span className="ps-2">{row.drug_name}</span>,
            sortable: true,
            wrap: true,
            width: '300px',
            fixed: true,
            style: { borderBottom: '1px solid #ddd' },
        },
        {
            name: <Title />,
            cell: (row) => (
                <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                    <tbody>
                        {row?.batch_info?.map((item) => (
                            <tr style={{ borderTopWidth: '0px' }}>
                                <Fragment key={Math.random() * 1000}>
                                    <td width="150px">{item.batch_no}</td>
                                    <td width="150px">{item.expire_date}</td>
                                    <td width="150px">{item.available_qty}</td>
                                </Fragment>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ),
        },
    ];

    const search_column = { name: 'Name' };
    return useDataTable({
        columns,
        data,
        search_column,
        pagination: false,
        cellPadding: '0px',
        borderWidth: '0px',
        tableHeight: '100%',
    });
}
