/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Employee DataTable
 * Description: Employee DataTable
 * Date: 26/04/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import SweetAlertService from '../../../services/SweetAlertService';
import UrlService from '../../../services/UrlService';
import { useEmployee } from './ContextAPI';
// import { layout } from '../../SvgIcon';
// import FormInfo from './FormInfo';

export default function DataTable() {
    const { data, getAllData, setShow, setRowData } = useEmployee();
    const { deleteSwal } = SweetAlertService();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const editHander = (row) => {
        setShow(true);
        setRowData(row);
    };

    const deleteHandler = (row) => {
        deleteSwal({
            id: row.id,
            url: UrlService.deleteEmployeeUrl(),
            refreshList: getAllData,
            beforeTitle: 'Are you sure?',
            beforeText: "You won't be able to revert this!",
            afterTitle: 'Deleted!',
            afterText: 'Your file has been deleted.',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    };

    const columns = [
        {
            name: 'Code',
            selector: (row) => row.code_no,
            sortable: true,
        },
        {
            name: 'Name',
            selector: (row) => (
                <>
                    {row?.first_name} {row?.last_name}
                </>
            ),
            sortable: true,
            grow: 2,
        },
        {
            name: 'Organization',
            selector: (row) =>
                row.organization === 1 ? (
                    'UNHCR'
                ) : row.organization === 2 ? (
                    'Center'
                ) : (
                    <>
                        {row.partner?.name}
                        {row.facility ? <> - {row.facility.name}</> : ''}
                        {row.dispensary ? <> - {row.dispensary.name}</> : ''}
                    </>
                ),
            sortable: true,
            grow: 3,
            wrap: true,
        },
        {
            name: 'Designation',
            selector: (row) => row.designation,
            sortable: true,
        },
        {
            name: 'Joining Date',
            selector: (row) => row.joining_date,
            format: (row) => moment(row.joining_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            cell: (row) =>
                row.status == 1 ? (
                    <Badge className="badgeActive" bg="success">
                        Active
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Inactive
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    {/* <Button data-id={row.id} type="button" className="btn btn-light actionBtn">
                        {layout}
                    </Button> */}

                    {/* <FormInfo editData={row} /> */}

                    <Button
                        className="btn btn-light actionBtn"
                        variant="primary"
                        onClick={() => editHander(row)}
                    >
                        <i className="fas fa-edit" />
                    </Button>

                    <Button
                        onClick={() => {
                            deleteHandler(row);
                        }}
                        type="button"
                        className="btn btn-light actionBtn"
                    >
                        <i className="fas fa-trash-alt" />
                    </Button>
                </>
            ),
        },
    ];

    const search_column = {
        code_no: 'Code No',
        first_name: 'First Name',
        last_name: 'Last Name',
        designation: 'Designation',
        joining_date: 'Joining Date',
    };
    return useDataTable({ columns, data, search_column, showFilterOrganization: true });
}
