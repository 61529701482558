/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Drug Presentation Strength DataTable
 * Description: Drug Presentation Strength DataTable
 * Date: 15/03/2022
 */

import React, { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import SweetAlertService from '../../../services/SweetAlertService';
import UrlService from '../../../services/UrlService';
import { layout } from '../../SvgIcon';
import { usePresentationStrength } from './ContextAPI';
import FormInfo from './FormInfo';

export default function DataTable() {
    const { data, getAllData } = usePresentationStrength();
    const { deleteSwal } = SweetAlertService();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const deleteHandler = (row) => {
        deleteSwal({
            id: row.id,
            url: UrlService.deletePresentationStrengthUrl(),
            refreshList: getAllData,
            beforeTitle: 'Are you sure?',
            beforeText: "You won't be able to revert this!",
            afterTitle: 'Deleted!',
            afterText: 'Your file has been deleted.',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    };

    const columns = [
        {
            name: 'SL',
            selector: 'serial',
            width: '50px',
        },
        {
            name: 'Strength',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            cell: (row) =>
                row.status == 1 ? (
                    <Badge className="badgeActive" bg="success">
                        Active
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Inactive
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    <button data-id={row.id} type="button" className="btn btn-light actionBtn">
                        {layout}
                    </button>

                    <FormInfo editData={row} />

                    <Button
                        onClick={() => {
                            deleteHandler(row);
                        }}
                        type="button"
                        className="btn btn-light actionBtn"
                    >
                        <i className="fas fa-trash-alt" />
                    </Button>
                </>
            ),
        },
    ];

    const search_column = { name: 'Name' };
    return useDataTable({ columns, data, search_column });
}
