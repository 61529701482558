/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Facility Stock Receive Approval Table
 * Description: Facility Stock Receive Approval Table
 * Date: 27/06/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { layout } from '../../SvgIcon';
import { useApproval } from './ContextAPI';

export default function ApprovedTable() {
    const { setShow, setRowData, approvedData, getApprovedData, setShowPdf, setPdfData } =
        useApproval();
    const axiosPrivate = useAxiosPrivate();

    const handleShow = async (row) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getFacilityStockInDetailsByIdUrl(row.id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const row_info = {
                d1_master_id: row.id,
                facility_name: row.facility_name,
                month: row.month,
                year: row.year,
                status: row.status,
                records: response?.data?.medicine_list,
            };
            setRowData(row_info);
            setShow(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const dataList = useCallback(() => {
        getApprovedData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const handlePrint = async (row) => {
        setShowPdf(true); // delete
        try {
            const response = await axiosPrivate.get(UrlService.grnFacilityUrl(row.id), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setShowPdf(true);
            setPdfData(response?.data?.grn_report_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const columns = [
        {
            name: 'Facility',
            selector: (row) => row.facility_name,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Month',
            selector: (row) => row.month,
            sortable: true,
            cell: (row) =>
                row.month == 1
                    ? 'January'
                    : row.month == 2
                    ? 'February'
                    : row.month == 3
                    ? 'March'
                    : row.month == 4
                    ? 'April'
                    : row.month == 5
                    ? 'May'
                    : row.month == 6
                    ? 'June'
                    : row.month == 7
                    ? 'July'
                    : row.month == 8
                    ? 'August'
                    : row.month == 9
                    ? 'September'
                    : row.month == 10
                    ? 'October'
                    : row.month == 11
                    ? 'November'
                    : row.month == 12
                    ? 'December'
                    : '',
        },
        {
            name: 'Year',
            selector: (row) => row.year,
            sortable: true,
        },
        {
            name: 'Submitted Date',
            selector: (row) => row.submitted_date,
            format: (row) => moment(row.submitted_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: () => (
                <Badge className="badgeActive" bg="success">
                    Approved
                </Badge>
            ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    <Button
                        className="btn btn-light actionBtn"
                        variant="primary"
                        onClick={() => handlePrint(row)}
                    >
                        <i className="fa fa-file-pdf" /> GRN
                    </Button>
                    <Button
                        onClick={() => handleShow(row)}
                        className="btn btn-light actionBtn"
                        type="button"
                        variant="success"
                    >
                        {layout}
                    </Button>
                </>
            ),
        },
    ];

    const search_column = {
        facility_name: 'Facility',
        submited_date: 'Submited Date',
    };
    return useDataTable({ columns, data: approvedData, search_column, pagination: false });
}
