/* eslint-disable eqeqeq */
/*
 * Author M. Atoar Rahman
 * Title: Dashboard
 * Description: Page for Dashboard
 * Date: 15/03/2022
 */
import { useEffect, useState } from 'react';
import { axiosDrugsAvailable } from '../../api/axios';
import { useAuth } from '../../contexts/AuthContext';
import PieCharts from '../dashboard/itemsInStock/PieChart';
import ItemWithLowStock from '../dashboard/itemsWithLowStock/Index';
import NearlyExpiredMedicine from '../dashboard/nearlyExpiredMedicine/Index';
import { PoStockProvider } from '../dashboard/poWiseStockReceive/ContextAPI';
import PoWiseStockReceive from '../dashboard/poWiseStockReceive/Index';

export default function Dashboard() {
    const { auth } = useAuth();
    const layer = auth?.employee_info?.layer_id;
    const [key, setKey] = useState('nearExpiryDrug');
    const [dataPie, setdataPie] = useState([]);
    const [reload, setreload] = useState(true);

    function percentage(num, per) {
        return parseFloat((per / num) * 100).toFixed(3);
    }
    const fetchData = async () => {
        const data = await axiosDrugsAvailable();
        if (data.data.status === 200) {
            const percent = percentage(data.data.total, data.data.available);
            // eslint-disable-next-line prefer-const
            // eslint-disable-next-line prettier/prettier, prefer-const
            let obj = [
                {
                    title: `Items availablity ${percent}  %`,
                    value: parseFloat(percent),
                    color: '#008000',
                },
                {
                    title: `Items unavailablity ${100 - percent}  %`,
                    value: 100 - percent,
                    color: '#ee080f',
                },
            ];
            setdataPie(obj);
            setreload(reload);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);
    return (
        <div id="tab-dashboard">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {/* <li className="nav-item" role="presentation">
                    <button
                        className="nav-link active"
                        id="summary-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#summary"
                        type="button"
                        role="tab"
                        aria-controls="summary"
                        aria-selected="true"
                        onClick={() => setKey('summary')}
                    >
                        <span style={{ fontSize: '36px', marginRight: '5px' }}>
                            <i className="fa-solid fa-helicopter-symbol" />
                        </span>
                        Summary
                    </button>
                </li> */}
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link"
                        id="nearExpiryDrug-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nearExpiryDrug"
                        type="button"
                        role="tab"
                        aria-controls="nearExpiryDrug"
                        aria-selected="false"
                        onClick={() => setKey('nearExpiryDrug')}
                    >
                        <span style={{ fontSize: '36px', marginRight: '5px' }}>
                            <i className="fa-solid fa-life-ring" />
                        </span>
                        Near Expiry Items
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link"
                        id="itemsWithLowStock-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#itemsWithLowStock"
                        type="button"
                        role="tab"
                        aria-controls="itemsWithLowStock"
                        aria-selected="false"
                        onClick={() => setKey('itemsWithLowStock')}
                    >
                        <span style={{ fontSize: '38px', marginRight: '5px' }}>
                            <i className="fa-solid fa-circle-radiation" />
                        </span>
                        Items with low stock
                    </button>
                </li>
                {(layer == 1 || layer == 2) && (
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link"
                            id="stockReceived-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#stockReceived"
                            type="button"
                            role="tab"
                            aria-controls="stockReceived"
                            aria-selected="false"
                            onClick={() => setKey('stockReceived')}
                        >
                            <span style={{ fontSize: '36px', marginRight: '5px' }}>
                                <i className="fa-solid fa-arrows-spin" />
                            </span>
                            Stock Received (%) as per pipeline
                        </button>
                    </li>
                )}
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link"
                        id="itemsPercentage-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#itemsPercentage"
                        type="button"
                        role="tab"
                        aria-controls="itemsPercentage"
                        aria-selected="false"
                        onClick={() => {
                            setKey('itemsPercentage');
                            fetchData();
                        }}
                    >
                        <span style={{ fontSize: '36px', marginRight: '5px' }}>
                            <i className="fa-solid fa-atom" />
                        </span>
                        Items in Stock (%)
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div
                    className="tab-pane fade"
                    id="summary"
                    role="tabpanel"
                    aria-labelledby="summary-tab"
                >
                    summary
                </div>
                <div
                    className="tab-pane fade show active"
                    id="nearExpiryDrug"
                    role="tabpanel"
                    aria-labelledby="nearExpiryDrug-tab"
                >
                    {key === 'nearExpiryDrug' && <NearlyExpiredMedicine />}
                </div>
                <div
                    className="tab-pane fade"
                    id="itemsWithLowStock"
                    role="tabpanel"
                    aria-labelledby="itemsWithLowStock-tab"
                >
                    {key === 'itemsWithLowStock' && <ItemWithLowStock />}
                </div>
                <div
                    className="tab-pane fade"
                    id="stockReceived"
                    role="tabpanel"
                    aria-labelledby="stockReceived-tab"
                >
                    {key === 'stockReceived' && (
                        <PoStockProvider>
                            <PoWiseStockReceive />
                        </PoStockProvider>
                    )}
                </div>
                <div
                    className="tab-pane fade"
                    id="itemsPercentage"
                    role="tabpanel"
                    aria-labelledby="itemsPercentage-tab"
                >
                    <PieCharts dataPie={dataPie} />
                </div>
            </div>
        </div>
    );
}
