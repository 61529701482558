/*
 * Author M. Atoar Rahman
 * Title: Drug Category
 * Description: Page for Drug Category
 * Date: 15/03/2022
 */

import React from 'react';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { DrugCategoryProvider } from '../masterSetup/drugCategory/ContextAPI';
import DataTable from '../masterSetup/drugCategory/DataTable';
import FormInfo from '../masterSetup/drugCategory/FormInfo';
import PermissionDenied from './PermissionDenied';

export default function DrugCategory() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['drug-category']) return <PermissionDenied />;

    return (
        <DrugCategoryProvider>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Drug Category</h3>
                    <div>
                        <FormInfo />
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable />
                </Card.Body>
            </Card>
        </DrugCategoryProvider>
    );
}
