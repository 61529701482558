/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Internal Request Approve
 * Description: Dispensary Internal Request Approve
 * Date: 30/06/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { layout } from '../../SvgIcon';
import { useInternalRequest } from './ContextAPI';

export default function DataTable() {
    const { internalRequests, getAllData, setShow, setRowData } = useInternalRequest();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const showHander = (row) => {
        setRowData(row);
        setShow(true);
    };

    const columns = [
        {
            name: 'Partner',
            selector: (row) => row.partner_name,
            sortable: true,
        },
        {
            name: 'Facility',
            selector: (row) => row.facility_name,
            sortable: true,
        },
        {
            name: 'Dispensary',
            selector: (row) => row.dispensary_name,
            sortable: true,
        },
        {
            name: 'Approved Date',
            selector: (row) => row.approve_date,
            format: (row) =>
                row.approve_date ? moment(row.approve_date).format('DD MMM YYYY') : '',
            sortable: true,
        },
        {
            name: 'Request Mode',
            selector: (row) => row.request_mode,
            sortable: true,
            cell: (row) =>
                row.request_mode == 'online' ? (
                    <Badge className="badgeActive" bg="success">
                        Online
                    </Badge>
                ) : row.request_mode == 'offline' ? (
                    <Badge className="badgeInactive" bg="error">
                        Offline
                    </Badge>
                ) : (
                    ''
                ),
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            cell: (row) =>
                row.status == 1 ? (
                    <Badge className="badgeActive" bg="success">
                        Approved
                    </Badge>
                ) : row.status == 2 ? (
                    <Badge className="badgeInactive" bg="success">
                        Rejected
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    <Button
                        className="btn btn-light actionBtn"
                        variant="primary"
                        onClick={() => showHander(row)}
                    >
                        {layout}
                    </Button>

                    {/* <Button
                        onClick={() => {
                            deleteHandler(row);
                        }}
                        type="button"
                        className="btn btn-light actionBtn"
                    >
                        <i className="fas fa-trash-alt" />
                    </Button> */}
                </>
            ),
        },
    ];

    return useDataTable({ columns, data: internalRequests });
}
