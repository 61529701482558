/* eslint-disable eqeqeq */
import { Button, Card } from 'react-bootstrap';
import { usePoStock } from './ContextAPI';
import DataTable from './DataTable';
import DetailsPdfPrintIndex from './detailsPdf/PrintIndex';
import FormInfo from './FormInfo';
import PdfListPrintIndex from './listPdf/PrintIndex';
import PendingShowDetails from './PendingShowDetails';
import ShowDetails from './ShowDetails';

export default function Index() {
    const {
        showForm,
        setShowForm,
        showDetails,
        setShowDetails,
        showList,
        setShowList,
        pendingDetailsShow,
        showPdfList,
        setShowPdfList,
        setPendingDetailsShow,
        showPdfDetails,
    } = usePoStock();

    const backToForm = () => {
        setShowForm(true);
        setShowPdfList(false);
        setShowList(false);
        setShowDetails(false);
        setPendingDetailsShow(false);
    };

    const handlePrintPreview = () => {
        setShowPdfList(true);
        setShowList(false);
        setShowForm(false);
        setShowDetails(false);
        setPendingDetailsShow(false);
    };

    return (
        <>
            {showForm && <FormInfo />}
            {showList && (
                <Card>
                    <Card.Header className="cardHeader">
                        <h3>PO Wise Stock Receive Status</h3>
                        <div>
                            <Button
                                onClick={() => backToForm()}
                                className="btnInfo"
                                variant="primary"
                            >
                                Go Back
                            </Button>
                        </div>
                        <div>
                            <Button className="btn btn-dark me-2" onClick={handlePrintPreview}>
                                Print Preview
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <DataTable />
                    </Card.Body>
                </Card>
            )}
            {showDetails && <ShowDetails />}
            {pendingDetailsShow && <PendingShowDetails />}
            {showPdfList && <PdfListPrintIndex />}
            {showPdfDetails && <DetailsPdfPrintIndex />}
        </>
    );
}
