/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: ContexAPI
 * Description: ContexAPI
 * Date: 15/03/2022
 */

import { createContext, useContext, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import UrlService from '../../../services/UrlService';

const ContexAPI = createContext({});

export function useUser() {
    return useContext(ContexAPI);
}

export function UserProvider({ children }) {
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState();
    const { auth } = useAuth();
    const partnerId = auth?.employee_info?.partner_id ? auth?.employee_info?.partner_id : null;

    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(
                partnerId ? UrlService.getUserByPartnerUrl(partnerId) : UrlService.getAllUserUrl(),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setData(response?.data?.user_info);
            // console.log(response?.data.data);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContexAPI.Provider value={{ data, setData, getAllData }}>{children}</ContexAPI.Provider>
    );
}

export default ContexAPI;
