/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Pending Stock Return Request Table
 * Description: Pending Stock Return Request Table
 * Date: 05/06/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { useApproval } from './ContextAPI';

export default function ApprovedTable() {
    const { setShow, setRowData, approvedData, getApprovedData } = useApproval();
    const axiosPrivate = useAxiosPrivate();

    const handleShow = async (row) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllReturnRequestItemListUrl(row.id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const row_info = {
                master_id: row.id,
                partner_name: row.partner_name,
                facility_name: row.facility_name,
                status: row.status,
                requested_date: row.request_unhcr_date,
                records: response?.data?.request_info,
            };
            setRowData(row_info);
            setShow(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const dataList = useCallback(() => {
        getApprovedData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const columns = [
        {
            name: 'Partner Name',
            selector: (row) => row.partner_name,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Requested Date',
            selector: (row) =>
                row.request_unhcr_date ? moment(row.request_unhcr_date).format('DD MMM YYYY') : '',
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            cell: (row) =>
                row.status == 'request_by_unhcr' ? (
                    <Badge className="badgeActive" bg="success">
                        UNHCR Requesting
                    </Badge>
                ) : row.status == 'request_approved_by_unhcr' ? (
                    <Badge className="badgeActive" bg="success">
                        UNHCR Requested
                    </Badge>
                ) : row.status == 'd_returned_by_partner' ? (
                    <Badge className="badgeActive" bg="success">
                        Partner Returned
                    </Badge>
                ) : row.status == 'd_return_approved_by_partner' ? (
                    <Badge className="badgeActive" bg="success">
                        Partner Returned Apv
                    </Badge>
                ) : row.status == 'return_approving_by_partner' ? (
                    <Badge className="badgeActive" bg="success">
                        Partner Approved
                    </Badge>
                ) : row.status == 'return_approved_by_partner' ? (
                    <Badge className="badgeActive" bg="success">
                        Partner Returned Apv
                    </Badge>
                ) : row.status == 'returned_by_facility' ? (
                    <Badge className="badgeActive" bg="success">
                        Facility Returned
                    </Badge>
                ) : row.status == 'return_approved_by_facility' ? (
                    <Badge className="badgeActive" bg="success">
                        Facility Returned Apv
                    </Badge>
                ) : row.status == 'received_by_central' ? (
                    <Badge className="badgeActive" bg="success">
                        Central Received
                    </Badge>
                ) : row.status == 'receive_approved_by_central' ? (
                    <Badge className="badgeActive" bg="success">
                        Central Received Apv
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Invalid Status
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            center: true,
            cell: (row) => (
                <Button
                    className="btn btn-light btnSuccess"
                    variant="primary"
                    onClick={() => handleShow(row)}
                >
                    View
                </Button>
            ),
        },
    ];

    const search_column = {
        // facility_name: 'Facility',
        // submited_date: 'Submited Date',
    };
    return useDataTable({ columns, data: approvedData, search_column, pagination: false });
}
