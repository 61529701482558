/*
 * Author M. Atoar Rahman
 * Title: Change Password
 * Description: Page for Change Password
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import FormInfo from '../layouts/changePassword/FormInfo';
import PermissionDenied from './PermissionDenied';

export default function ChangePassword() {
    // Route Permission
    const { auth } = useAuth();
    if (!auth) return <PermissionDenied />;

    return <FormInfo />;
}
