/* eslint-disable react-hooks/exhaustive-deps */
import useDataTable from '../../../hooks/useDataTable';

export default function ModelData({ data }) {
    const columns = [
        {
            name: 'Item Names',
            selector: (row) =>
                `${row.code_no} ${row.drug_name} ${row.presentation_types} ${row.presentation_strengths}`,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Invoice Qty',
            selector: (row) => row.invoice_qty,
            sortable: true,
        },
        {
            name: 'Received Qty',
            selector: (row) => row.receive_qty,
            sortable: true,
        },
        {
            name: 'Rejected Qty',
            selector: (row) => row.rejected_qty,
            sortable: true,
        },
    ];

    const search_column = {
        // code_no: 'UID',
        // drug_name: 'Item Names',
        // presentation_strengths: 'Presentation Strengths',
        // presentation_types: 'Presentation Types',
        // invoice_qty: 'Invoice Qty',
        // receive_qty: 'Received Qty',
        // rejected_qty: 'Rejected Qty',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
