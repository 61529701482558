/*
 * Author M. Atoar Rahman
 * Title: Dispensary Stock Return
 * Description: Page for Dispensary Stock Return
 * Date: 19/06/2022
 */

import { Button, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import ApprovedTable from './ApprovedTable';
import { useStockReturn } from './ContextAPI';
import FormInfo from './FormInfo';
import PendingTable from './PendingTable';
import ShowModal from './ShowModal';

export default function Index() {
    const { show, setShow, rowData, setRowData } = useStockReturn();
    const createHander = () => {
        setShow(true);
        setRowData('');
    };
    const backHander = () => {
        setShow(false);
        setRowData('');
    };
    return (
        <>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Facility Stock Return</h3>
                    {show ? (
                        <div>
                            <Button
                                onClick={() => backHander()}
                                className="btnInfo"
                                variant="primary"
                            >
                                Back to List
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Button
                                onClick={() => createHander()}
                                className="btnInfo"
                                variant="primary"
                            >
                                Create New
                            </Button>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    {show ? (
                        <FormInfo editData={rowData} />
                    ) : (
                        <Row className="requisition_table">
                            <Col>
                                <Tabs
                                    defaultActiveKey="pending"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="pending" title="Pending">
                                        <PendingTable />
                                    </Tab>
                                    <Tab eventKey="approved" title="Approved">
                                        <ApprovedTable />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    )}
                </Card.Body>
            </Card>
            <ShowModal />
        </>
    );
}
