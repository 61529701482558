/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Pending D1 Table
 * Description: Pending D1 Table
 * Date: 05/06/2022
 */
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { useDispensaryStockReceive } from './ContextAPI';

export default function ApprovedTable() {
    const { setShow, setRowData, approvedData, getApprovedData } = useDispensaryStockReceive();
    const axiosPrivate = useAxiosPrivate();

    const handleShow = async (row) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.dispensaryStockReceiveMedicineListUrl(row.id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const row_info = {
                stock_out_master_id: row.id,
                dispensary_name: row.dispensary_name,
                stockout_date: row.stockout_date,
                status: row.status,
                records: response?.data?.medicine_list,
            };
            setRowData(row_info);
            setShow(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const dataList = useCallback(() => {
        getApprovedData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const columns = [
        {
            name: 'Dispensary',
            selector: (row) => row.dispensary_name,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Request Type',
            selector: (row) => row.distribution_type,
            sortable: true,
            cell: (row) =>
                row.distribution_type === 'internal_request' ? (
                    <Badge className="badgeActive" bg="success">
                        Internal Request
                    </Badge>
                ) : (
                    <Badge className="badgeActive" bg="success">
                        Regular
                    </Badge>
                ),
        },
        {
            name: 'Supply Date',
            selector: (row) => row.supply_date,
            format: (row) => moment(row.supply_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Received Date',
            selector: (row) => row.received_date,
            format: (row) => moment(row.received_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: () => (
                <Badge className="badgeActive" bg="success">
                    Received
                </Badge>
            ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    onClick={() => handleShow(row)}
                    className="btnSuccess"
                    type="button"
                    variant="success"
                >
                    View
                </Button>
            ),
        },
    ];

    const search_column = {
        facility_name: 'Facility',
        submited_date: 'Submited Date',
    };
    return useDataTable({ columns, data: approvedData, search_column, pagination: false });
}
