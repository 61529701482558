/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Pending Stock Return Request Table
 * Description: Pending Stock Return Request Table
 * Date: 05/06/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { useApproval } from './ContextAPI';

export default function PendingTable() {
    const { setFormShow, setRowData, pendingData, getPendingData } = useApproval();
    const axiosPrivate = useAxiosPrivate();

    const approveHandler = async (row) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllReturnRequestItemListUrl(row.id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const row_info = {
                master_id: row.id,
                partner_name: row.partner_name,
                facility_name: row.facility_name,
                dispensary_name: row.dispensary_name,
                requested_date: row.request_unhcr_date,
                status: row.status,
                records: response?.data?.request_info,
            };
            setRowData(row_info);
            setFormShow(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const dataList = useCallback(() => {
        getPendingData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const columns = [
        {
            name: 'Partner Name',
            selector: (row) => row.partner_name,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Facility Name',
            selector: (row) => row.facility_name,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Warehouse Name',
            selector: (row) => row.warehouse_name,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Requested Date',
            center: true,
            selector: (row) =>
                row.request_unhcr_date ? moment(row.request_unhcr_date).format('DD MMM YYYY') : '',
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            center: true,
            cell: () => (
                <Badge className="badgeInactive" bg="success">
                    Pending
                </Badge>
            ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            center: true,
            cell: (row) => (
                <Button
                    className="btn btn-light btnSuccess"
                    variant="primary"
                    onClick={() => approveHandler(row)}
                >
                    View
                </Button>
            ),
        },
    ];

    const search_column = {
        // partner_name: 'Partner',
        // submited_date: 'Submited Date',
    };
    return useDataTable({ columns, data: pendingData, search_column, pagination: false });
}
