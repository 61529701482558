/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Requisition DataTable
 * Description: Requisition DataTable
 * Date: 05/06/2022
 */

import { toArray } from 'lodash';
import { useEffect } from 'react';
import { Badge, Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useApproval } from './ContextAPI';

export default function RequisitionTable({ data }) {
    const { setReceiveList, receiveData, setReceiveData } = useApproval();

    const receiveRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.received_qty = Number(value);
        itemdata.rejected_qty = item.central_distributed_qty - value;
        itemdata.rejected_reason = receiveData[itemdata.drug_id].rejected_reason;

        const list = { ...receiveData };
        list[itemdata.drug_id] = itemdata;

        setReceiveData(list);
    };

    const rejectRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.received_qty = item.central_distributed_qty - value;
        itemdata.rejected_qty = Number(value);
        itemdata.rejected_reason = receiveData[itemdata.drug_id].rejected_reason;

        const list = { ...receiveData };
        list[itemdata.drug_id] = itemdata;

        setReceiveData(list);
    };

    const rejectReasonRowHandler = (value, item) => {
        const itemdata = item;

        itemdata.received_qty = receiveData[itemdata.drug_id].received_qty;
        itemdata.rejected_qty = receiveData[itemdata.drug_id].rejected_qty;
        itemdata.rejected_reason = value;

        const list = { ...receiveData };
        list[itemdata.drug_id] = itemdata;

        setReceiveData(list);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(receiveData);
        setReceiveList(arrOfObj);
    }, [receiveData]);

    // console.log(receiveData);

    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            width: '250px',
            fixed: true,
        },
        {
            name: 'Category Name',
            selector: (row) => row.category_name,
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row) =>
                row.status === 'distributed' ? (
                    <Badge className="badgeActive" bg="success">
                        Distributed
                    </Badge>
                ) : row.status === 'forwarded' ? (
                    <Badge className="badgeActive" bg="success">
                        Forwarded
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ),
        },
        {
            name: 'Facility Requested Qty',
            selector: (row) => row.facility_approved_qty,
            sortable: true,
        },
        {
            name: 'Partner Requested Qty',
            selector: (row) => row.partner_forward_apv_qty,
            sortable: true,
        },
        {
            name: 'Central Distributed Qty',
            selector: (row) => row.central_distributed_qty,
            sortable: true,
        },
        {
            name: 'Received Qty',
            // selector: (row) => row.received_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        receiveRowHandler(e.target.value, row);
                    }}
                    value={receiveData[row.drug_id].received_qty}
                    disabled
                />
            ),
            reorder: true,
            width: '150px',
        },
        {
            name: 'Reject Qty',
            // selector: (row) => row.rejected_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        rejectRowHandler(e.target.value, row);
                    }}
                    defaultValue={receiveData[row.drug_id].rejected_qty}
                />
            ),
            reorder: true,
            width: '150px',
        },
        {
            name: 'Reject Reason',
            // selector: (row) => row.rejected_reason,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="text"
                    onBlur={(e) => {
                        rejectReasonRowHandler(e.target.value, row);
                    }}
                    defaultValue={receiveData[row.drug_id].rejected_reason}
                />
            ),
            reorder: true,
            width: '150px',
        },
    ];

    const search_column = {
        // drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
