/* eslint-disable react-hooks/exhaustive-deps */
import { toArray } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useD2Approval } from './ContextAPI';

export default function ShowTable({ data }) {
    const { setReceiveList, receiveData, setReceiveData } = useD2Approval();

    const requisitionRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.approved_request_qty = value;
        const list = { ...receiveData };
        list[itemdata.drug_id] = itemdata;
        if (value === '') {
            delete list[itemdata.drug_id];
        }
        setReceiveData(list);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(receiveData);
        setReceiveList(arrOfObj);
    }, [receiveData]);

    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            width: '250px',
            fixed: true,
        },
        {
            name: 'Opening Balance',
            selector: (row) => row.opening_balance,
            sortable: true,
        },
        {
            name: 'Total Quantity Received During The Month',
            selector: (row) => row.received_during_month,
            sortable: true,
        },
        {
            name: 'Total UNHCR Stock quantity Consumed By the Facility (OPD, IPD, Emergency, any other dept)',
            selector: (row) => row.consumed_by_facility,
            sortable: true,
            width: '150px',
        },
        {
            name: 'Total Non-UNHCR Stock Quantity Consumed By The Agency',
            cell: () => '0',
            sortable: true,
            width: '150px',
        },
        {
            name: 'Number Of Days Out Of Stock In The Pharmacy',
            selector: (row) => row.stock_out_days,
            sortable: true,
        },
        {
            name: 'Average Monthly Consumption',
            selector: (row) => row.avg_monthly_consumption,
            sortable: true,
        },
        {
            name: 'Total Quantities Returned To Main Agency Warehouse (Due To over stock/ For re-distribution)',
            selector: (row) => row.overstock_return,
            sortable: true,
            width: '150px',
        },
        {
            name: 'Total Quanties Expired or damaged',
            selector: (row) => row.expired_qty,
            sortable: true,
        },
        {
            name: 'Earliest date of Expiry of physical stocks',
            selector: (row) => row.earliest_expire_date,
            format: (row) => moment(row.earliest_expire_date).format('DD MMM YYYY'),
            sortable: true,
            width: '150px',
        },
        {
            name: 'Closing Balance (Physical Stock)',
            selector: (row) => row.closing_balance,
            sortable: true,
        },
        {
            name: 'Theoretical Stock',
            selector: (row) => row.theoritical_stock,
            sortable: true,
        },
        {
            name: 'Number of days of physical stock available',
            selector: (row) => row.days_physical_stock_available,
            sortable: true,
        },
        {
            name: 'Request Quantity',
            selector: (row) => row.requested_qty,
            sortable: true,
        },
        {
            name: 'Approved Request Quantity',
            selector: (row) => row.approved_request_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        requisitionRowHandler(e.target.value, row);
                    }}
                    defaultValue={receiveData?.[row.drug_id]?.approved_request_qty}
                />
            ),
            reorder: true,
            width: '150px',
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
