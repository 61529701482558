/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Requisition DataTable
 * Description: Requisition DataTable
 * Date: 28/06/2022
 */

import { toArray } from 'lodash';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useApproval } from './ContextAPI';

export default function ShowTable({ data }) {
    const { setReceiveData, setReceiveList, receiveData } = useApproval();

    // console.log(receiveData);

    const requisitionRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.approved_supply_qty = value;
        const list = { ...receiveData };
        list[itemdata.drug_id] = itemdata;
        if (value === '') {
            delete list[itemdata.drug_id];
        }
        setReceiveData(list);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(receiveData);
        setReceiveList(arrOfObj);
    }, [receiveData]);

    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            width: '350px',
            wrap: true,
        },
        {
            name: 'Opening Balance',
            selector: (row) => row.opening_balance,
            sortable: true,
        },
        {
            name: 'Closing Balance',
            selector: (row) => row.closing_balance,
            sortable: true,
        },
        {
            name: 'Request Quantity',
            selector: (row) => row.approved_request_qty,
            sortable: true,
        },
        {
            name: 'Available Quantity',
            selector: (row) => row.available_qty,
            sortable: true,
        },
        {
            name: 'Distribution Quantity',
            selector: (row) => (Number(row.supplied_qty) < 0 ? 0 : row.supplied_qty),
            sortable: true,
        },

        {
            name: 'Approve Distribution Quantity',
            selector: (row) => row.approved_supply_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        requisitionRowHandler(e.target.value, row);
                    }}
                    defaultValue={
                        row.approved_supply_qty > 0
                            ? row.approved_supply_qty
                            : receiveData?.[row.drug_id]?.approved_supply_qty
                    }
                />
            ),
            reorder: true,
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
