/* eslint-disable radix */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/*
 * Author M. Atoar Rahman
 * Title: Layer Chaining
 * Description: Master Setup of Layer Chaining Form: Create and Update
 * Date: 20/06/2022
 */

import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import UrlService from '../../../services/UrlService';
import { useLayerChaining } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                role_id: editData && {
                    label: editData?.role_name,
                    value: editData?.role_id,
                },
                layer_name: editData && {
                    label:
                        editData?.layer_name == 'unhcr'
                            ? 'UNHCR'
                            : editData?.layer_name == 'center'
                            ? 'Center'
                            : editData?.layer_name == 'partner'
                            ? 'Partner'
                            : editData?.layer_name == 'facility'
                            ? 'Facility'
                            : '',
                    value: editData?.layer_name,
                },
                status: parseInt(editData?.status) !== 0,
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            role_id: editData && {
                label: editData?.role_name,
                value: editData?.role_id,
            },
            layer_name: editData && {
                label:
                    editData?.layer_name == 'unhcr'
                        ? 'UNHCR'
                        : editData?.layer_name == 'center'
                        ? 'Center'
                        : editData?.layer_name == 'partner'
                        ? 'Partner'
                        : editData?.layer_name == 'facility'
                        ? 'Facility'
                        : '',
                value: editData?.layer_name,
            },
            status: parseInt(editData?.status) !== 0,
        });
    }, [editData]);

    // Dependency
    const axiosPrivate = useAxiosPrivate();
    const {
        getAllData,
        addLayerChaining,
        editLayerChaining,
        show,
        setShow,
        isSubmitted,
        setIsSubmitted,
    } = useLayerChaining();

    // Local State
    const [roleData, setRoleData] = useState([]);

    // Get All role From role Table
    const roleList = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllRoleUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const roleInfo = response?.data?.role_info?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setRoleData(roleInfo);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // Closing the Model
    const handleClose = () => {
        getAllData();
        setShow(false);
    };

    useEffect(() => {
        roleList();
    }, []);

    const layerData = [
        { label: 'UNHCR', value: 'unhcr' },
        { label: 'Center', value: 'center' },
        { label: 'Partner', value: 'partner' },
        { label: 'Facility', value: 'facility' },
    ];

    // Form Submit Handelar
    const formSubmitHandler = (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            layer_name: data.layer_name.value,
            role_id: data.role_id.value,
            status: data.status === (true || 1) ? 1 : 0,
        };
        if (editData) {
            editLayerChaining(formData, editData.id); // Update Data
        } else {
            addLayerChaining(formData); // Create Data
        }
        setShow(false);
        reset();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add/Edit Layer Chaining</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Row>
                            <Col lg={3}>
                                <Form.Label>
                                    Layer Name<span className="required">*</span>
                                </Form.Label>
                            </Col>
                            <Col lg={9}>
                                <Controller
                                    name="layer_name"
                                    id="layer_name"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors?.layer_name && 'invalidInput'}
                                            options={layerData}
                                        />
                                    )}
                                    rules={{ required: 'Please select your layer.' }}
                                />
                                {errors.layer_name && (
                                    <span className="invalid">{errors.layer_name?.message}</span>
                                )}
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col lg={3}>
                                <Form.Label>
                                    Role<span className="required">*</span>
                                </Form.Label>
                            </Col>
                            <Col lg={9}>
                                <Controller
                                    name="role_id"
                                    id="role_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors?.role_id && 'invalidInput'}
                                            options={roleData}
                                        />
                                    )}
                                    rules={{ required: 'Please select your role.' }}
                                />
                                {errors.role_id && (
                                    <span className="invalid">{errors.role_id?.message}</span>
                                )}
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col lg={3}>
                                <Form.Label>
                                    Lavel<span className="required">*</span>
                                </Form.Label>
                            </Col>
                            <Col lg={9}>
                                <Form.Control
                                    name="level"
                                    id="level"
                                    type="number"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register('level', {
                                        required: 'Please enter your level.',
                                    })}
                                    className={errors?.level && 'invalidInput'}
                                />
                                {errors.level && (
                                    <span className="invalid">{errors.level?.message}</span>
                                )}
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col lg={3}>
                                <Form.Label>Status</Form.Label>
                            </Col>
                            <Col lg={9}>
                                <Form.Check
                                    name="status"
                                    type="switch"
                                    id="status"
                                    {...register('status')}
                                />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            onClick={handleSubmit(formSubmitHandler)}
                            className="btnSuccess"
                            type="button"
                            variant="success"
                            disabled={isSubmitted}
                        >
                            {editData ? 'Update' : 'Create New'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
