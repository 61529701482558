/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty, toArray } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Row, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useFacilityStockReceive } from './ContextAPI';
import ReceiveTable from './ReceiveTable';

export default function FormInfo() {
    const { setFormShow, rowData, receiveList, setReceiveList, receiveData, setReceiveData } =
        useFacilityStockReceive();
    const { drugCategory, getDrugCategory } = CommonApiService();
    const [page, setPage] = useState(0);
    const [pageLength, setPageLength] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        rowData?.records?.map((item) =>
            item.stockout_details.map((el) => {
                const itemdata = {};
                itemdata.drug_id = el.drug_id;
                itemdata.batch_no = el.batch_no;
                itemdata.supplied_qty = el.supplied_qty;
                itemdata.received_qty = el.supplied_qty;
                itemdata.rejected_qty = 0;
                itemdata.rejected_reason = '';
                itemdata.partner_stockout_id = el.partner_stockout_id;

                const list = receiveData;
                list[itemdata.partner_stockout_id] = itemdata;
                if (itemdata.partner_stockout_id === '') {
                    delete list[itemdata.partner_stockout_id];
                }

                setReceiveData(list);

                const arrOfObj = toArray(list);
                setReceiveList(arrOfObj);
                return receiveData;
            })
        );
    }, []);

    console.log({ receiveList });

    const {
        register,
        // formState: { errors },
        reset,
        handleSubmit,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        if (!isEmpty(receiveList)) {
            setValue('medicine_details', receiveList);
        }
    }, [receiveList]);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <div className="subTable">
                    <ReceiveTable
                        data={rowData?.records?.filter((el) => el.category_id == item.id)}
                    />
                </div>
            </Tab>
        ));

    register('medicine_details', { required: 'Receive quantity is required!' });

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            d1_master_id: rowData.d1_master_id,
        };
        // console.log({ formData });
        try {
            await axiosPrivate.post(UrlService.saveD1StockReceiveUrl(), JSON.stringify(formData), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            ToastifyService.updateNotify('Approve Successfull');
            setFormShow(false);
            reset();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setFormShow(false);
    };

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Facility Stock Receive</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Tabs
                        activeKey={page}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 ps-3"
                        // eslint-disable-next-line radix
                        onSelect={(k) => setPage(parseInt(k))}
                    >
                        {tableDataDisplay()}
                    </Tabs>
                </Row>

                <div className="formFooter">
                    <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        className="btnInfo me-2 ms-2"
                        variant="primary"
                        style={{ minWidth: '6rem' }}
                        disabled={page === 0}
                        onClick={() => {
                            setPage((currPage) => currPage - 1);
                        }}
                    >
                        Prev
                    </Button>
                    <Button
                        type="button"
                        className={`btnSuccess ${page === pageLength - 1 && 'd-none'}`}
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        onClick={() => setPage((currPage) => currPage + 1)}
                    >
                        Next
                    </Button>

                    <Button
                        onClick={handleSubmit(formSubmitHandler)}
                        className={`btnSuccess ${page !== pageLength - 1 && 'd-none'}`}
                        type="button"
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        disabled={isSubmitted}
                    >
                        Receive
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}
