/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Item Dispatch Context
 * Description: Page for Item Dispatch Context
 * Date: 15/03/2022
 */

import { createContext, useContext, useReducer, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Initial State
const initialState = {
    dispatchs: [],
};

// Reducer Function
const reducer = (state, action) => {
    switch (action.type) {
        case 'ITEM_DISPATCH_LIST':
            return {
                ...state,
                dispatchs: action.payload,
            };
        case 'ADD_ITEM_DISPATCH':
            return {
                ...state,
                dispatchs: [...state.dispatchs, action.payload],
            };
        case 'EDIT_ITEM_DISPATCH':
            const updateItemDispatch = action.payload;

            const updateItemDispatchs = state.dispatchs.map((dispatch) => {
                if (dispatch.id === updateItemDispatch.id) {
                    return updateItemDispatch;
                }
                return dispatch;
            });
            return {
                ...state,
                dispatchs: updateItemDispatchs,
            };

        default:
            return state;
    }
};

// Create Context
const ContextAPI = createContext(initialState);

export function useItemDispatch() {
    return useContext(ContextAPI);
}

// Provider Component
export function ItemDispatchProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [errMsg, setErrMsg] = useState('');
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [rowData, setRowData] = useState('');

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Add/Edit Data
    const addOrUodateData = async (data, id) => {
        try {
            await axiosPrivate.post(
                id ? UrlService.updateDispatchUrl(id) : UrlService.saveDispatchUrl(),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (id) {
                ToastifyService.updateNotify('Data Update Successfull');
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
            }
            setShow(false);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else if (id) {
                setErrMsg('Data Update Failed!');
            } else {
                setErrMsg('Data Insert Failed!');
            }
            ToastifyService.errorNotify(errMsg);
        }
    };

    // API Function: Fatch All Data
    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllDispatchUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            dispatch({
                type: 'ITEM_DISPATCH_LIST',
                payload: response?.data?.dispatch_info,
            });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // Add ItemDispatch
    const addItemDispatch = (data) => {
        addOrUodateData(data);
        dispatch({
            type: 'ADD_ITEM_DISPATCH',
            payload: data,
        });
    };

    // Edit ItemDispatch
    const editItemDispatch = (data, id) => {
        addOrUodateData(data, id);
        dispatch({
            type: 'EDIT_ITEM_DISPATCH',
            payload: data,
        });
    };

    return (
        <ContextAPI.Provider
            value={{
                dispatchs: state.dispatchs,
                getAllData,
                addItemDispatch,
                editItemDispatch,
                show,
                setShow,
                showModal,
                setShowModal,
                rowData,
                setRowData,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
