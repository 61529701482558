/*
 * Author M. Atoar Rahman
 * Date: 15/03/2022
 * Time: 11:40 AM
 */

import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { DataProvider } from './ContextAPI';
import FormInfo from './FormInfo';
import List from './List';

export default function Index() {
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState('');
    const handleShow = () => {
        setShow(true);
    };
    const hideFunction = (show_status) => {
        setShow(show_status);
        setRowData('');
    };
    const editHander = (row) => {
        setShow(true);
        setRowData(row);
    };
    return (
        <DataProvider>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Menu Information</h3>
                    <div>
                        <Button variant="primary" onClick={handleShow}>
                            Create New
                        </Button>
                        {show && <FormInfo editData={rowData} hideFunction={hideFunction} />}
                    </div>
                </Card.Header>
                <Card.Body>
                    <List editHander={editHander} />
                </Card.Body>
            </Card>
        </DataProvider>
    );
}
