/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Pending D2 Table
 * Description: Pending D2 Table
 * Date: 05/06/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useD2Approval } from './ContextAPI';

export default function PendingD2Table() {
    const { setFormShow, setRowData, d2CheckedData, getD2CheckedData } = useD2Approval();

    const approveHandler = async (row) => {
        setRowData({
            d2_master_id: row.id,
            partner_name: row.partner_name,
            month: row.month,
            year: row.year,
        });
        setFormShow(true);
    };

    const dataList = useCallback(() => {
        getD2CheckedData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const columns = [
        {
            name: 'Partner',
            selector: (row) => row.partner_name,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Month',
            selector: (row) => row.month,
            sortable: true,
            cell: (row) =>
                row.month == 1
                    ? 'January'
                    : row.month == 2
                    ? 'February'
                    : row.month == 3
                    ? 'March'
                    : row.month == 4
                    ? 'April'
                    : row.month == 5
                    ? 'May'
                    : row.month == 6
                    ? 'June'
                    : row.month == 7
                    ? 'July'
                    : row.month == 8
                    ? 'August'
                    : row.month == 9
                    ? 'September'
                    : row.month == 10
                    ? 'October'
                    : row.month == 11
                    ? 'November'
                    : row.month == 12
                    ? 'December'
                    : '',
        },
        {
            name: 'Year',
            selector: (row) => row.year,
            sortable: true,
        },
        {
            name: 'Submitted Date',
            selector: (row) => row.submitted_date,
            format: (row) => moment(row.submitted_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: () => (
                <Badge className="badgeInactive" bg="success">
                    Pending
                </Badge>
            ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    onClick={() => approveHandler(row)}
                    className="btnSuccess"
                    type="button"
                    variant="success"
                >
                    Approve
                </Button>
            ),
        },
    ];

    const search_column = {
        partner_name: 'Partner',
        submited_date: 'Submited Date',
    };
    return useDataTable({ columns, data: d2CheckedData, search_column, pagination: false });
}
