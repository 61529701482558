/* eslint-disable radix */
/*
 * Author M. Atoar Rahman
 * Title: Bin Card
 * Description: Bin Card
 * Date: 15/07/2022
 */

import moment from 'moment';
import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useBinCard } from './ContextAPI';

function ComponentToPrint(props, ref) {
    const { pdfData } = useBinCard();

    return (
        <fieldset ref={ref} className="customFieldset p-5">
            <Row className="mb-5">
                <Col className="text-center">
                    <h3 style={{ textTransform: 'uppercase' }}>{pdfData?.stock_from}</h3>
                    <h5>STOCK RECORD CARD/BIN CARD</h5>
                </Col>
            </Row>
            <Row>
                <Table borderless>
                    <tbody>
                        <tr>
                            <td width="105px">
                                <b>Description:</b>
                            </td>
                            <td>{pdfData?.drug_name}</td>
                            <td width="105px">
                                <b>Batch No:</b>
                            </td>
                            <td>{pdfData?.stock_list[0]?.batch_no}</td>
                            <td width="105px">
                                {pdfData?.stock_list[0]?.expire_date ? <b>Expiry Date:</b> : ''}
                            </td>
                            <td>
                                {pdfData?.stock_list[0]?.expire_date
                                    ? moment(pdfData?.stock_list[0]?.expire_date).format(
                                          'DD MMM YYYY'
                                      )
                                    : ''}
                            </td>
                        </tr>
                        <tr>
                            <td width="105px">{pdfData?.date_from ? <b>Date From: </b> : ''}</td>
                            <td>
                                {pdfData.date_from
                                    ? moment(pdfData?.date_from).format('DD MMM YYYY')
                                    : ''}
                            </td>

                            <td width="105px">{pdfData?.date_to ? <b>Date To: </b> : ''}</td>
                            <td>
                                {pdfData.date_to
                                    ? moment(pdfData?.date_to).format('DD MMM YYYY')
                                    : ''}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th width="100px">Date</th>
                            <th>Received From</th>
                            <th>Issued To</th>
                            <th>Issued Qty</th>
                            <th>Quantity Received</th>
                            <th>Balance</th>
                            <th>P.O/Waybill</th>
                            <th>Remarks/Signature</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pdfData?.stock_list?.map((el) => (
                            <tr key={Math.random()}>
                                <td>{moment(el.date).format('DD MMM YYYY')}</td>
                                <td>{parseInt(el.issue_qty) === 0 ? el.particular : ''}</td>
                                <td>{parseInt(el.issue_qty) !== 0 ? el.particular : ''}</td>
                                <td>{el.issue_qty}</td>
                                <td>{el.receive_qty}</td>
                                <td>{el.closing_stock}</td>
                                <td>{el.issue_qty ? el.grn_waybill_no : el.po_no}</td>
                                <td />
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;
