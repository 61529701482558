/*
 * Author M. Atoar Rahman
 * Title: Code Send To Email
 * Description: Page for Code Send To Email
 * Date: 15/03/2022
 */

import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import logoWhite from '../../assets/unhcr-logo-white.png';
import styles from '../../styles/LoginForm.module.css';

export default function CodeSendToEmail() {
    return (
        <div className={styles.login}>
            <div className={styles.logo}>
                <img src={logoWhite} alt="" height={50} />
            </div>

            <Form>
                <h4 className="mt-4">Code Send To Your Email</h4>
                <span>Enter your to email for reset your password</span>
                <NavLink exact="true" to="/login">
                    <Button className={styles.btnGroup} variant="primary" type="submit">
                        Login
                    </Button>
                </NavLink>
            </Form>

            <p>Developed &amp; Maintenance By Nanosoft</p>
        </div>
    );
}
