/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import MenuTree from './MenuTree';

export default function FormInfo({ editData = null }) {
    const axiosPrivate = useAxiosPrivate();

    const [pageState, setPageState] = useState({
        roles: [],
        parent_menus: [],
        layers: [],
        permission_menus: [],
        search_data: {
            layer_id: '',
            role_id: '',
            parent_menu_id: '',
        },
        show_tree: false,
        error: { layer_id: '', role_id: '' },
    });

    const roleModuleLayerList = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllPermissionUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const roleInfo = response?.data?.roles?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            const layerInfo = response?.data?.layers?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            const parentMenuInfo = response?.data?.parent_menus?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setPageState({
                ...pageState,
                roles: roleInfo,
                layers: layerInfo,
                parent_menus: parentMenuInfo,
                show_tree: false,
            });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const getSearchPermissionList = async (search_data) => {
        try {
            const response = await axiosPrivate.post(
                UrlService.getAllPermissionMenuList(),
                JSON.stringify(search_data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const menu_list = response?.data?.menu_list;
            setPageState({
                ...pageState,
                permission_menus: menu_list,
                show_tree: !(menu_list.length === 0),
            });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    useEffect(() => {
        roleModuleLayerList();
    }, []);

    const { layer_id, role_id, parent_menu_id } = pageState.search_data;

    const searchSubmit = (e) => {
        e.preventDefault();
        if (!pageState.search_data.layer_id || !pageState.search_data.role_id) {
            setPageState({
                ...pageState,
                show_tree: false,
                error: {
                    ...pageState.error,
                    layer_id: pageState.search_data.layer_id ? '' : 'Please select a level',
                    role_id: pageState.search_data.role_id ? '' : 'Please select a role',
                },
            });
        } else {
            getSearchPermissionList(pageState.search_data);
        }
    };

    const permissionSubmit = async (checkedData) => {
        const data = {};
        data.checked_data = checkedData;
        data.parent_menu_id = pageState.search_data.parent_menu_id;
        data.layer_id = pageState.search_data.layer_id;
        data.role_id = pageState.search_data.role_id;

        try {
            const response = await axiosPrivate.post(
                UrlService.permissionMenuStore(),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            ToastifyService.saveNotify('Role permissions have been successfully updated');
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <>
            <Form onSubmit={searchSubmit}>
                <Form.Group className="mb-3">
                    <Row>
                        <Col lg={3}>
                            <Form.Label>User Category</Form.Label>
                            <Select
                                name="layer_id"
                                options={pageState.layers}
                                onChange={(e) => {
                                    setPageState({
                                        ...pageState,
                                        show_tree: false,
                                        search_data: {
                                            ...pageState.search_data,
                                            layer_id: e.value,
                                        },
                                        error: {
                                            ...pageState.error,
                                            layer_id: e.value ? '' : 'Not Allow Layer',
                                        },
                                    });
                                }}
                            />
                            <p className="invalid">{pageState.error.layer_id}</p>
                        </Col>
                        <Col lg={3}>
                            <Form.Label>Role</Form.Label>
                            <Select
                                name="role_id"
                                options={pageState.roles}
                                onChange={(e) => {
                                    setPageState({
                                        ...pageState,
                                        show_tree: false,
                                        search_data: {
                                            ...pageState.search_data,
                                            role_id: e.value,
                                        },
                                        error: {
                                            ...pageState.error,
                                            role_id: e.value ? '' : 'Not Allow Role',
                                        },
                                    });
                                }}
                            />
                            <p className="invalid">{pageState.error.role_id}</p>
                        </Col>

                        <Col lg={3}>
                            <Form.Label>Module</Form.Label>
                            <Select
                                name="parent_menu_id"
                                defaultValue={[]}
                                isMulti
                                options={pageState.parent_menus}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => {
                                    setPageState({
                                        ...pageState,
                                        show_tree: false,
                                        search_data: {
                                            ...pageState.search_data,
                                            parent_menu_id: e.map((el) => el.value),
                                        },
                                    });
                                }}
                            />
                        </Col>
                        <Col className="pt-1" lg={3}>
                            <Button className="mt-3" variant="primary" type="sumit">
                                Search <i className="fa fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
            {pageState.show_tree && (
                <MenuTree pageState={pageState} permissionSubmit={permissionSubmit} />
            )}
        </>
    );
}
