/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Requisition DataTable
 * Description: Requisition DataTable
 * Date: 05/06/2022
 */

import useDataTable from '../../../hooks/useDataTable';

export default function RequisitionTable({ data }) {
    const conditionalCellStyles = [
        {
            when: (row) => Number(row.central_available_qty) <= 0,
            style: {
                backgroundColor: '#ffb4c1',
            },
        },
    ];

    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            conditionalCellStyles,
            wrap: true,
            grow: 2,
            fixed: true,
        },
        {
            name: 'Category Name',
            selector: (row) => row.category_name,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'UNHCR Approved Qty',
            selector: (row) => row.unhcr_final_approved_qty,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Availability in Central Warehouse',
            selector: (row) => (row.central_available_qty ? row.central_available_qty : 0),
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Distribute Qty',
            selector: (row) => row.central_distributed_qty,
            sortable: true,
            conditionalCellStyles,
        },
    ];

    const search_column = {
        // drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
