import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { ApprovalProvider } from '../partner/facilityInternalRequestReceiveApproval/ContextAPI';
import Index from '../partner/facilityInternalRequestReceiveApproval/Index';
import PermissionDenied from './PermissionDenied';

export default function FacilityInternalRequestPartnerReceiveApproval() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['facility-internal-request-partner-receive-approval'])
        return <PermissionDenied />;

    return (
        <ApprovalProvider>
            <Index />
        </ApprovalProvider>
    );
}
