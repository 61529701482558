/*
 * Author M. Atoar Rahman
 * Title: Facility Stock Distribution
 * Description: Page for Facility Stock Distribution
 * Date: 28/04/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { FacilityStockDistributionProvider } from '../facility/stockDistribution/ContextAPI';
import Index from '../facility/stockDistribution/Index';
import PermissionDenied from './PermissionDenied';

export default function FacilityStockDistribution() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['facility-stock-distribution']) return <PermissionDenied />;

    return (
        <FacilityStockDistributionProvider>
            <Index />
        </FacilityStockDistributionProvider>
    );
}
