/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: ContextAPI
 * Description: ContextAPI
 * Date: 15/03/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import UrlService from '../../../services/UrlService';

const LIST_URL = UrlService.getAllPresentationStrengthUrl();
const ContextAPI = createContext({});

export function usePresentationStrength() {
    return useContext(ContextAPI);
}

export function PresentationStrengthProvider({ children }) {
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(LIST_URL, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setData(response?.data?.presentation_strength_info);
            // console.log(response?.data.data);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContextAPI.Provider value={{ data, setData, getAllData, isSubmitted, setIsSubmitted }}>
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
