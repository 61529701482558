/*
 * Author M. Atoar Rahman
 * Title: Facility Infomation
 * Description: Page for Facility Infomation
 * Date: 15/03/2022
 */

import React from 'react';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { FacilityProvider } from '../masterSetup/facilityInfo/ContextAPI';
import DataTable from '../masterSetup/facilityInfo/DataTable';
import FormInfo from '../masterSetup/facilityInfo/FormInfo';
import PermissionDenied from './PermissionDenied';

export default function FacilityInfomation() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['facility-info']) return <PermissionDenied />;

    return (
        <FacilityProvider>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Facility Information</h3>
                    <div>
                        <FormInfo />
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable />
                </Card.Body>
            </Card>
        </FacilityProvider>
    );
}
